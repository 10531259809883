import styled from "styled-components";

export const Container = styled.div`
  align-self: center;
  position: absolute;
  bottom: 5vh;
  height: 48px;
  width: 32px;
  z-index: 3;
  border: solid 3px ${({ color }) => color};
  border-radius: 15px;
  display: ${({ display }) => display};
  justify-content: center;

  .scrollButton {
    width: 8px;
    height: 8px;
    background: ${({ color }) => color} padding-box;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    animation: scrollDown 2s infinite ease-in-out;

    @keyframes scrollDown {
      20% {
        top: 6px;
        opacity: 100%;
      }
      100% {
        top: 32px;
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 1024px) and (max-aspect-ratio: 1/1) {
    display: none;
  }
`;
