import {combineReducers} from "redux";
import register from "./register";
import userProfile from "./userProfile";
import userSolicitations from "./userSolicitations";
import searchMembers from "./searchMembers";
import userPenalizations from "./userPenalizations";
import admin from "./admin";

export default combineReducers({
  register,
  userProfile,
  userSolicitations,
  searchMembers,
  userPenalizations,
  admin,
});
