import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 64px;

  .contact-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    justify-content: center;

    h1 {
      font-size: 4.8rem;
      color: ${({ theme }) => theme.color.black.main};
      font-weight: normal;
      margin-bottom: 16px;
    }

    p {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.color.gray.main};
      margin-bottom: 24px;
    }

    .input {
      width: 280px;
      margin-bottom: 16px;
      font-size: 10rem;
    }

    .input-msg {
      width: 400px;
      margin-bottom: 24px;
    }

    .button-submit {
      height: 56px;
      width: 280px;
      font-size: 1.4rem;
      font-weight: 400;
      border-radius: 2px;
    }

    #submit-progress {
      width: 280px;
    }

    .social {
      display: flex;
      margin-top: 40px;
      margin-bottom: 16px;
      .button-icon {
        cursor: pointer;
        margin-right: 24px;
      }
    }

    p {
      font-size: 2rem;
      color: ${({ theme }) => theme.color.gray.light};
    }
  }

  .map {
    height: 561px;
    width: 643px;
    box-shadow: 0px 0px 65px -6px rgba(0, 0, 0, 0.31);
    border-radius: 4px;
    margin: 0 8px;

    #iframe-map {
      height: 561px;
      width: 100%;
      border-radius: 4px;
    }

    p {
      color: ${({ theme }) => theme.color.gray.light};
      text-align: center;
      margin-top: 32px;
    }
  }

  @media screen and (max-width: 1366px) {
    .map {
      height: 461px;
      width: 543px;
      #iframe-map {
        height: 461px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .map {
      height: 373px;
      width: 441px;
      #iframe-map {
        height: 373px;
      }
    }
  }
  @media screen and (max-width: 850px) {
    flex-direction: column;

    .contact-container {
      .button-submit {
        width: 180px;
        height: 40px;
        font-size: 1.6rem;
      }
      #submit-progress {
        width: 180px;
      }
      .social {
        .button-icon {
          width: 30px;
          margin: -16px 0 0 12px;
        }
      }
      p#telephone {
        margin-left: 12px;
      }
    }

    .map {
      margin-top: 24px;
      margin-left: 0;
      margin-bottom: 150px;
    }
  }
  @media screen and (max-width: 470px) {
    .contact-container {
      padding: 0 8px;
      .button-submit {
        width: 152px;
        height: 40px;
      }
      #submit-progress {
        width: 153px;
      }
    }
    .map {
      width: 360px;
      height: 320px;
      #iframe-map {
        height: 320px;
      }
    }
  }
  @media screen and (max-width: 420px) {
    .contact-container {
      .input {
        width: 236px;
      }
      .input-msg {
        width: 300px;
      }
    }
  }
  @media screen and (max-width: 375px) {
    .map {
      width: 300px;
      height: 279px;
      #iframe-map {
        height: 279px;
      }
    }
  }
`;
