import {userProfileConstants as cc} from "@/store/types/userProfile";

const INITIAL_STATE = {
  user: {},
  fullUser: {},
  penalizations: [],
  loading: false,
  error: false,
  feedback: false,
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cc.LOAD_USER_PROFILE:
    case cc.LOAD_FULL_USER_PROFILE:
    case cc.UPDATE_USER_PROFILE:
    case cc.UPDATE_USER_PASSWORD:
    case cc.LOAD_USER_PENALIZATIONS:
      return {...state, loading: true, error: false, feedback: ""};
    case cc.LOAD_USER_PROFILE_SUCCESS:
      return {...state, loading: false, user: action.user};
    case cc.LOAD_USER_PROFILE_FAILURE:
      return {...state, loading: false, error: true};
    case cc.LOAD_FULL_USER_PROFILE_SUCCESS:
      return {...state, loading: false, fullUser: action.fullUser};
    case cc.LOAD_FULL_USER_PROFILE_FAILURE:
      return {...state, loading: false, error: true};
    case cc.UPDATE_USER_PROFILE_SUCCESS:
      return {...state, loading: false, user: action.user};
    case cc.UPDATE_USER_PROFILE_FAILURE:
      return {...state, loading: false, error: true};
    case cc.UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        feedback: "Senha alterada com sucesso",
      };
    case cc.UPDATE_USER_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        feedback: "Erro ao alterar a senha",
      };
    case cc.LOAD_USER_PENALIZATIONS_SUCCESS:
      return {...state, loading: false, penalizations: action.penalizations};
    case cc.LOAD_USER_PENALIZATIONS_FAILURE:
      return {...state, loading: false, error: true};
    case cc.CLEAR_USER_PROFILE:
      return {...state, user: {}, penalizations: []};
    default:
      return state;
  }
}
