import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Paper, FormControl, TextField, Button } from "@material-ui/core";

import { Container } from "./styles";
import logo from "../../../assets/loginLogo.svg";
import background from "../../../assets/backgroundInterno.png";

import { allowAccessRegister } from "../../../store/actions/register.js";

const allowedRegistrations = ["201710895", "999999999"];

const Cadastro = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [registration, setRegistration] = useState("");

  const [counterRegistration, setCounterRegistration] = useState(0);

  const handleSubmit = () => {
    allowAccessRegister(dispatch);

    const emailInstitucional = email.indexOf("@estudante.ufla.br") !== -1;

    const allowedRegistration =
      allowedRegistrations.indexOf(registration) !== -1;

    emailInstitucional & allowedRegistration
      ? history.push("/membros/cadastro/2")
      : history.push("/membros/cadastro/erro");
  };

  const handleCancel = () => {
    history.push("/membros");
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <FormControl component="form" onSubmit={handleSubmit}>
          <TextField
            required
            type="email"
            label="Email"
            className="input"
            variant="outlined"
            error={
              (email.indexOf("@estudante.ufla.br") !== -1) | (email === "")
                ? false
                : true
            }
            helperText={
              (email.indexOf("@estudante.ufla.br") !== -1) | (email === "")
                ? false
                : "Este email não é institucional"
            }
            inputProps={{ spellCheck: false }}
            onChange={({ target }) => setEmail(target.value)}
          />
          <div className="input-registration">
            <TextField
              required
              label="Matrícula"
              className="input"
              variant="outlined"
              error={
                (counterRegistration === 9) | (counterRegistration === 0)
                  ? false
                  : true
              }
              helperText={
                (allowedRegistrations.indexOf(registration) !== -1) |
                (registration === "")
                  ? false
                  : "Esta matrícula não é permitida"
              }
              inputProps={{ maxLength: 9, minLength: 9 }}
              onChange={({ target }) => {
                setRegistration(target.value);
                setCounterRegistration(target.value.length);
              }}
            />
            <p id="counter">{counterRegistration}/9</p>
          </div>
          <Button
            variant="contained"
            style={{ background: "#FB8C00" }}
            type="submit"
          >
            PRÓXIMO
          </Button>
          <Button
            variant="contained"
            style={{ background: "#FFBD45" }}
            onClick={handleCancel}
          >
            CANCELAR
          </Button>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default Cadastro;
