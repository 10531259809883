import React from "react";

import { Container } from "./styles";

const MouseAnimationContainer = ({ color, display = "flex" }) => {
  return (
    <Container color={color} display={display}>
      <div className="scrollButton" />
    </Container>
  );
};

export default MouseAnimationContainer;
