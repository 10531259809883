import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadAllMembers, loadListOfMembers} from "@/store/actions/searchMembers";
import {
  ThemeProvider,
  Box,
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Typography,
} from "@material-ui/core";

import {Search} from "@material-ui/icons";

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";

const ListMembers = (props) => {
  const dispatch = useDispatch();
  const {members} = useSelector(({searchMembers}) => searchMembers);
  const {user} = useSelector(({userProfile}) => userProfile);
  const classes = useStyles(); //Classes Personalizadas MUI (Arquivo customMUI)

  //Fields
  const [filteredMember, setFilteredMembers] = useState([]);

  useEffect(() => {
    if (!members.length) {
      dispatch(loadAllMembers());
    }
    props.setTitle("MEMBROS");
  }, []);

  const getListOfMembers = () => {
    dispatch(loadListOfMembers());
  };

  const handleHasAdmin = () => {
    const role = user.roles;
    if (role && role.length) {
      return true;
    }
    return false;
  };

  const filterMembers = (key) => {
    if (key === "") {
      return setFilteredMembers(members);
    }

    if (!isNaN(key)) {
      setFilteredMembers(
        members.filter((member) => parseInt(member.points) === parseInt(key))
      );
    } else {
      setFilteredMembers(
        members.filter(
          (member) =>
            member.username.toLowerCase().indexOf(key.toLowerCase()) !== -1
        )
      );
    }
  };

  return (
    <Container>
      <ThemeProvider theme={themeMaterial}>
        <Box className="membros-container-items">
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{marginBottom: "4%"}}
          >
            <Grid item md={8} xs={12}>
              <Grid
                container
                className={classes.wrapper}
                justify="flex-end"
                alignItems="center"
              >
                <TextField
                  className={classes.searchField}
                  id="searchField"
                  size="medium"
                  label="Buscar por nome ou pontuação..."
                  onChange={({target}) => filterMembers(target.value)}
                  color="primary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              {handleHasAdmin() && (
                <Grid
                  container
                  className={classes.wrapper}
                  justify="flex-end"
                  alignItems="center"
                >
                  <Button
                    onClick={getListOfMembers}
                    variant="contained"
                    color="primary"
                  >
                    Relação de Membros
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <TableContainer>
            {members.length ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableMembrosHead}>
                      Nome
                    </TableCell>
                    <TableCell
                      className={classes.tableMembrosHead}
                      align="center"
                    >
                      Área
                    </TableCell>
                    <TableCell
                      className={classes.tableMembrosHead}
                      align="center"
                    >
                      Pontos
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredMember.length === 0 ? members : filteredMember).map(
                    (member) => {
                      return (
                        <TableRow
                          key={member.username}
                          className={
                            member.username === user.username &&
                            classes.tablePenalizationFieldCurrent
                          }
                        >
                          <TableCell
                            className={classes.tableMembrosField}
                            component="th"
                            scope="row"
                          >
                            {member.username === user.username
                              ? "Você"
                              : member.username}
                          </TableCell>
                          <TableCell
                            className={classes.tableMembrosField}
                            align="center"
                          >
                            {member.field_action}
                          </TableCell>
                          <TableCell
                            className={classes.tableMembrosField}
                            align="center"
                          >
                            {member.points + " pontos"}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h6" align="center" color="primary">
                Nenhuma solicitação encontrada
              </Typography>
            )}
          </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default ListMembers;
