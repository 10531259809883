import styled from "styled-components";

export const Container = styled.div`
  height: 100%;

  animation: scrollOff 1.2s forwards;

  .apresentation-container {
    height: 100vh;
    min-height: 700px;
    position: relative;

    display: flex;

    background: linear-gradient(
      90deg,
      #f6f5fa 0%,
      #f6f5fa 43%,
      #16141a 43%,
      #16141a 100%
    );

    img {
      position: absolute;
    }
    img#to-web {
      width: 50%;
      left: 96px;
      align-self: center;
      object-fit: cover;
    }
    img#to-mobile {
      display: none;
    }

    .texts-container {
      width: 44.37vw;
      height: 70%;
      position: absolute;
      z-index: 2;
      right: 12vw;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        color: ${({ theme }) => theme.color.white.dark};
        font-size: 2.5rem;
        font-weight: normal;
        margin-bottom: 16px;
      }
      h1 {
        color: ${({ theme }) => theme.color.white.main};
        font-size: 5.6rem;
        font-weight: lighter;
        line-height: 1.3;
      }
    }
  }

  .mobile-container {
    overflow-x: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 70px;
    box-sizing: content-box;

    .box-bg {
      position: absolute;
      height: 33.9vw;
      min-height: 411px;
      width: 67.2vw;
      min-width: 814px;
      background: ${({ theme }) => theme.color.primary.main};
      border-radius: 4px;
    }
    p {
      position: relative;
      width: 30vw;
      color: ${({ theme }) => theme.color.white.main};
      font-size: 5rem;
      line-height: 1.5;
      font-weight: lighter;
      margin-left: 3%;
    }

    #mobile-prototype {
      position: relative;
      z-index: 3;
      height: 38vw;
      min-height: 642px;
      width: 18vw;
      min-width: 230px;
      margin-left: 5%;
    }
  }

  .web-container {
    background: linear-gradient(
      #f6f5fa 0%,
      #f6f5fa 60%,
      #5e2075 60%,
      #5e2075 100%
    );
    height: 100vh;
    min-height: 700px;
    position: relative;
    display: flex;
    align-items: center;

    svg#bg-wave {
      position: absolute;
      bottom: 40%;
    }

    h1 {
      position: absolute;
      z-index: 2;
      color: ${({ theme }) => theme.color.white.main};
      font-size: 8rem;
      font-weight: lighter;
      width: 29.5vw;
      left: 7vw;
      margin-top: 100px;
    }

    img {
      position: absolute;
      z-index: 2;
      right: 0;
      width: 50%;
      object-fit: contain;
    }
  }

  .embarcado-container {
    min-height: 600px;
    background: ${({ theme }) => theme.color.primary.main};
    display: flex;
    align-items: center;

    img {
      width: 50%;
      height: auto;
    }

    .texts-container {
      margin: 0 auto;
      width: 40%;

      p {
        color: ${({ theme }) => theme.color.white.dark};
        font-size: 3rem;
        margin-bottom: 24px;
      }
      h2 {
        color: ${({ theme }) => theme.color.white.main};
        font-size: 5rem;
        font-weight: lighter;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .apresentation-container {
      background: linear-gradient(
        90deg,
        #f6f5fa 0%,
        #f6f5fa 30%,
        #16141a 30%,
        #16141a 100%
      );
      padding: 8px;

      img#to-web {
        display: none;
      }
      img#to-mobile {
        display: block;
        align-self: center;
        width: 80%;
        max-height: 90%;
        object-fit: contain;
        min-width: 300px;
      }

      .texts-container {
        right: 0;
        width: 80%;
      }
    }

    .mobile-container {
      min-height: 900px;
      justify-content: center;
      flex-direction: column-reverse;
      .box-bg {
        top: 40px;
        height: 75vh;
        min-height: 600px;
        width: 100vw;
        min-width: 0;
        align-self: flex-start;
        border-radius: 0;
      }
      p {
        top: 24px;
        align-self: flex-start;
        margin-left: 24px;
        margin-bottom: 60px;
        width: 50%;
      }

      #mobile-prototype {
        margin-left: 0;
      }
    }

    .web-container {
      h1 {
        font-size: 6rem;
        margin-top: 78px;
        left: 24px;
        width: 40vw;
      }
    }

    .embarcado-container {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      padding-bottom: 100px;

      img {
        width: 100%;
        max-height: 100%;
        margin-bottom: 80px;
      }

      .texts-container {
        margin: 0 0 0 24px;
        width: 80%;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .web-container {
      margin-top: 60px;
      align-items: flex-start;
      background: linear-gradient(
        #f6f5fa 0%,
        #f6f5fa 30%,
        #5e2075 30%,
        #5e2075 100%
      );
      img {
        width: 90%;
      }
      h1 {
        width: 63vw;
        margin-top: 39vw;
        align-self: center;
      }
      svg#bg-wave {
        bottom: 69%;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .mobile-container {
      .box-bg {
        height: 100vh;
      }
      p {
        width: 70%;
      }
    }
  }

  @keyframes scrollOff {
    0% {
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      overflow: visible;
    }
  }
`;
