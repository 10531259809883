import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background: url("${(props) => props.background}") no-repeat;
  background-size: cover;

  .container-login {
    width: 40%;
    min-height: 100vh;
    background: ${({theme}) => theme.color.white.main};
    box-shadow: 10px 0px 10px #00000033;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      margin-bottom: 24px;
    }

    .input {
      margin-bottom: 16px;
    }

    .button-main {
      width: 280px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      margin-top: 8px;
      color: #fff;
      background: ${({theme}) => theme.color.secondary.main};
      box-shadow: 3px 3px 6px #0000003d;
    }

    p {
      text-align: center;
      font-size: 16px;
      color: ${({theme}) => theme.color.gray.light};
      margin-bottom: 24px;
      a {
        text-decoration: none;
        color: ${({theme}) => theme.color.primary.main};
        font-size: 14px;
        font-weight: bold;
      }
    }

    hr {
      margin-bottom: 24px;
    }
  }

  .login-feedback {
    background-color: #e74c3c;
    color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-weight: 700;
  }

  @media screen and (max-width: 1000px) {
    .container-login {
      width: 100%;
      border-radius: 0;
    }
  }
`;
