import React from "react";

import {Box, Tab} from "@material-ui/core";

export const TabPanel = ({children, value, index, type, ...other}) => {
  //Os paineis das Abas
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={`${type}-container`}>{children}</Box>}
    </div>
  );
};

export const a11TabProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

export const LinkTab = (props) => {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};
