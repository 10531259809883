import {adminConstants as cc} from "@/store/types/admin";

export const loadAllUsersSolicitations = () => ({
  type: cc.LOAD_ALL_USERS_SOLICITATIONS,
});

export const loadPeriod = () => ({
  type: cc.LOAD_CURRENT_PERIOD,
});

export const updatePeriod = (newPeriod) => ({
  type: cc.UPDATE_CURRENT_PERIOD,
  newPeriod,
});

export const loadAllPenalties = () => ({
  type: cc.LOAD_ALL_REGISTRED_PENALTIES,
});

export const loadAllMembersComplete = () => ({
  type: cc.LOAD_ALL_MEMBERS,
});

export const applyPenalty = (penalty) => ({
  type: cc.APPLY_PENALTY,
  penalty,
});
