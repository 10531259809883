import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background: ${({theme}) => theme.color.white.main};
  display: flex;
  flex-direction: column;

  animation: scrollOff 1.2s forwards;

  @keyframes scrollOff {
    0% {
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      overflow: visible;
    }
  }

  h1 {
    font-size: 5rem;
    font-weight: bold;
    line-height: 1.5;
    color: ${({theme}) => theme.color.primary.main};
    width: 43.8vw;
    margin-bottom: 4.5vh;
    margin-left: 9.5vw;
    margin-top: 100px;
  }

  .grid-text {
    display: flex;
    align-self: center;

    h3 {
      font-size: 3.4rem;
      line-height: 1.5;

      color: ${({theme}) => theme.color.primary.light};
      width: 36.2vw;
      margin-right: 4.2vw;
    }
  }

  .grid-imgs-1 {
    display: flex;
    margin-top: 2vw;

    .img-1 {
      width: 27vw;
      min-width: 84px;
      height: 21.9vw;
      min-height: 68px;
      align-self: flex-end;
      margin-right: 1.8vw;
      margin-bottom: 3.24vh;
      margin-left: auto;
      border-radius: 4px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
    }
    .img-2 {
      width: 36.2vw;
      min-width: 153px;
      height: 31.6vw;
      min-height: 135px;
      background: blue;
      margin-bottom: 3.24vh;
      margin-right: 13vw;
      border-radius: 4px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
    }
  }
  .grid-imgs-2 {
    display: flex;
    .img-3 {
      width: 36.2vw;
      min-width: 152px;
      height: 23vw;
      min-height: 95px;
      background: green;
      margin-right: 1.8vw;
      margin-left: auto;
      margin-top: 20px;
      border-radius: 4px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
    }
    .img-4 {
      width: 51.4vw;
      min-width: 198px;
      height: 37.9vw;
      min-height: 144px;
      background: yellow;
      margin-right: auto;
      border-radius: 4px;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.5);
    }
  }

  div.body {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      to bottom right,
      transparent 0%,
      transparent 50%,
      #5e2075 50%,
      #300049 100%
    );
    margin-top: -30px;

    .mission {
      margin-left: 12.9vw;

      #title {
        font-size: 2.4rem;
        color: ${({theme}) => theme.color.secondary.main};
      }
      #description {
        width: 28.9vw;
        font-size: 4.8rem;
        color: ${({theme}) => theme.color.primary.main};
      }
    }

    .values {
      margin-top: 15vh;
      align-self: center;
      width: 74.2vw;
      max-width: 1430px;

      #title {
        margin-left: 7.6vw;
        font-size: 2.4rem;
        color: ${({theme}) => theme.color.secondary.main};
      }

      #title2 {
        margin-left: 1vw;
        font-size: 2.4rem;
        color: ${({theme}) => theme.color.secondary.main};
      }

      .grid-values {
        margin-top: 24px;

        .value-card {
          background: #fff;
          height: 28.3vw;
          min-height: 250px;
          max-height: 544px;
          width: 36.2vw;
          min-width: 300px;
          max-width: 695px;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          margin: 0 auto;
          box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.5);
          will-change: transform;

          h3 {
            font-size: 3.4rem;
            color: ${({theme}) => theme.color.gray.main};
            font-weight: lighter;
          }

          #value-1-img {
            width: 46.6%;
            height: 64.3%;
          }
          #value-2-img {
            width: 46.6%;
            height: 64.3%;
          }
          #value-3-img {
            width: 82.7%;
            height: 64.3%;
          }
          #value-4-img {
            width: 74.4%;
            height: 64.3%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      width: 80vw;
    }
    div.body {
      margin-bottom: 56px;
      .mission {
        margin-top: 84px;
        #description {
          width: 80vw;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    div.body {
      .values {
        width: calc(100% - 16px);

        #title2 {
          margin-left: 11vw;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    h1 {
      font-size: 4.5rem;
    }
    .grid-text {
      flex-direction: column;
      h3 {
        margin-bottom: 16px;
        width: 80vw;
      }
    }
    .grid-imgs-2 {
      .img-3 {
        min-width: 84px;
      }
      .img-4 {
        min-width: 153px;
      }
    }
    div.body {
      .mission {
        #description {
          font-size: 4rem;
        }
      }
    }
  }
`;

export const Subscribe = styled.div`
  height: 100vh;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: url("${(props) => props.subscribeBg}") no-repeat;
  background-size: 50vw auto;
  background-position: 0 100%;

  .subscribe-area {
    height: 100%;
    align-self: flex-end;
    margin-right: 84px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    h3 {
      font-size: 4.8rem;
      font-weight: 300;
      width: 44vw;
      color: ${({theme}) => theme.color.white.main};
      line-height: 1.5;
      text-align: right;
      margin-bottom: 32px;
    }

    .button {
      width: 280px;
      height: 56px;
      font-size: 1.4rem;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 1024px) and (max-aspect-ratio: 1/1) {
    background-size: 80vw auto;
    .subscribe-area {
      margin-top: 370px;
    }
  }

  @media screen and (max-width: 888px) {
    .subscribe-area {
      margin-right: 40px;
      margin-top: 200px;
      h3 {
        width: 50vw;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .subscribe-area {
      margin-top: 120px;
      .button {
        height: 40px;
        width: 152px;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .subscribe-area {
      margin-right: 24px;
      margin-top: 80px;
      h3 {
        max-width: 68vw;
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    background-size: 100vw auto;
  }
  @media screen and (max-width: 360px) {
    .subscribe-area {
      h3 {
        font-size: 4rem;
        margin-bottom: 8px;
      }
    }
  }
  @media screen and (max-width: 340px) {
    .subscribe-area {
      margin-top: 60px;
    }
  }
`;
