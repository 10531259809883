import {userProfileConstants as cc} from "@/store/types/userProfile";

export const loadUserProfile = () => ({
  type: cc.LOAD_USER_PROFILE,
});

export const loadFullUserProfile = () => ({
  type: cc.LOAD_FULL_USER_PROFILE,
});

export const updateUserProfile = (user) => ({
  type: cc.UPDATE_USER_PROFILE,
  user,
});

export const updateUserPassword = (passwords) => ({
  type: cc.UPDATE_USER_PASSWORD,
  passwords,
});

export const loadUserPenalizations = () => ({
  type: cc.LOAD_USER_PENALIZATIONS,
});

export const clearUserProfile = () => ({
  type: cc.CLEAR_USER_PROFILE,
});
