import {userSolicitationsconstants as cc} from "@/store/types/userSolicitations";

export const loadUserSolicitations = () => ({
  type: cc.LOAD_USER_SOLICITATIONS,
});

export const sendUserSolicitations = (solicitation) => ({
  type: cc.SEND_USER_SOLICITATIONS,
  solicitation,
});
