import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";

import { Container } from "./styles";
import erro from "../../../../../assets/erro.svg";
import logo from "../../../../../assets/loginLogo.svg";
import background from "../../../../../assets/backgroundInterno.png";

const Erro = () => {
  let history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <img src={erro} alt="ERRO" />
        <p>
          <strong>Desculpe</strong> você não está válido para Cadastrar-se.
        </p>
        <Button
          variant="contained"
          style={{ background: "#FB8C00" }}
          onClick={handleBack}
        >
          VOLTAR
        </Button>
      </Paper>
    </Container>
  );
};

export default Erro;
