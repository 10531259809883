export const toRight = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeOut",
    properties: [
      {
        startValue: -120,
        endValue: 0,
        property: "translateX",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

export const toLeft = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeOut",
    properties: [
      {
        startValue: 120,
        endValue: 0,
        property: "translateX",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

export const toLeftOnly = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: -10,
        unit: "vw",
        property: "translateX",
      },
    ],
  },
];

export const toRightOnly = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: 90,
        property: "translateX",
      },
    ],
  },
];

export const toTop = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 200,
        endValue: 0,
        property: "translateY",
      },
    ],
  },
];

export const toTopHide = [
  {
    start: "self",
    startOffset: -20,
    duration: "70vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: -100,
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

export const zoomIn = [
  {
    start: "self",
    startOffset: -20,
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0.93,
        endValue: 1,
        property: "scale",
      },
    ],
  },
];
