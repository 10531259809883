import {takeLatest, call, put} from "redux-saga/effects";
import api from "@/services/api";
import {adminConstants as cc} from "@/store/types/admin";

function* loadAllSolicitations() {
  try {
    const {data} = yield call(api.get, "/events");
    yield put({
      type: cc.LOAD_ALL_USERS_SOLICITATIONS_SUCCESS,
      solicitations: data,
    });
  } catch (e) {
    yield put({type: cc.LOAD_ALL_USERS_SOLICITATIONS_FAILURE});
  }
}

function* loadPeriod() {
  try {
    const {data} = yield call(api.get, "/periods");
    const currentPeriod = data.find((period) => period.active);
    if (currentPeriod)
      return yield put({
        type: cc.LOAD_CURRENT_PERIOD_SUCCESS,
        current: currentPeriod.period,
      });
    return yield put({type: cc.LOAD_CURRENT_PERIOD_SUCCESS, current: ""});
  } catch (e) {
    yield put({type: cc.LOAD_CURRENT_PERIOD_FAILURE});
  }
}

function* updatePeriod({newPeriod}) {
  try {
    const {data} = yield call(api.post, "/periods", newPeriod);
    yield put({
      type: cc.UPDATE_CURRENT_PERIOD_SUCCESS,
      updatePeriod: data.period,
    });
  } catch (e) {
    yield put({type: cc.UPDATE_CURRENT_PERIOD_FAILURE});
  }
}

function* loadAllPenalties() {
  try {
    const {data} = yield call(api.get, "/penalties");
    yield put({type: cc.LOAD_ALL_REGISTRED_PENALTIES_SUCCESS, penalties: data});
  } catch (e) {
    yield put({type: cc.LOAD_ALL_REGISTRED_PENALTIES_FAILURE});
  }
}

function* loadAllMembers() {
  try {
    const {data} = yield call(api.get, "/users");
    yield put({type: cc.LOAD_ALL_MEMBERS_SUCCESS, members: data.data});
  } catch (e) {
    yield put({type: cc.LOAD_ALL_MEMBERS_FAILURE});
  }
}

function* applyPenalty({penalty}) {
  try {
    const {data} = yield call(api.post, "/penalizations", penalty);
    yield put({
      type: cc.APPLY_PENALTY_SUCCESS,
      error: "Penalidade aplicada com sucesso",
    });
    yield put({
      type: cc.LOAD_ALL_MEMBERS,
    });
  } catch (e) {
    yield put({
      type: cc.APPLY_PENALTY_FAILURE,
      error: "Falha ao aplicar penalidade",
    });
  }
}

export default function* watcherSaga() {
  yield takeLatest(cc.LOAD_ALL_USERS_SOLICITATIONS, loadAllSolicitations);
  yield takeLatest(cc.LOAD_CURRENT_PERIOD, loadPeriod);
  yield takeLatest(cc.UPDATE_CURRENT_PERIOD, updatePeriod);
  yield takeLatest(cc.LOAD_ALL_REGISTRED_PENALTIES, loadAllPenalties);
  yield takeLatest(cc.LOAD_ALL_MEMBERS, loadAllMembers);
  yield takeLatest(cc.APPLY_PENALTY, applyPenalty);
}
