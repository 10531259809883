import React, {useState, useEffect} from "react";

//Material Components

import {
  ThemeProvider,
  AppBar,
  Tabs,
  Box,
  Paper,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";

//Material Icons

import {AssignmentTurnedIn, TrackChanges, Warning} from "@material-ui/icons";

//Custom components

import {TabPanel, LinkTab, a11TabProps} from "../Widgets/Tabs";
import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";

//Pages

import Solicitations from "./allSolicitations";
import Period from "./period";
import Penalize from "./penalize";

const Admin = (props) => {
  const classes = useStyles(); //Classes Personalizadas MUI (Arquivo customMUI)

  //Functionality
  const [value, setValue] = useState(0);
  const [admOptionSelected, setAdmOptionSelected] = useState(false);

  useEffect(() => {
    props.setTitle("ADMINISTRAÇÃO");
  }, []);

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickRefAdmSolicitations = (index) => {
    setAdmOptionSelected(!admOptionSelected);
    setValue(index);
  };

  return (
    <Container>
      <ThemeProvider theme={themeMaterial}>
        <div className="solicitation-content">
          <Hidden mdUp>
            <Hidden mdDown={admOptionSelected}>
              <Grid
                container
                justify="center"
                spacing={2}
                style={{marginTop: 30}}
              >
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    className={classes.cardAdmMobile}
                    onClick={() => handleClickRefAdmSolicitations(1)}
                  >
                    <AssignmentTurnedIn
                      color="primary"
                      style={{fontSize: 80}}
                    />
                    <Typography variant="subtitle2" align="center">
                      Alterar Período
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    className={classes.cardAdmMobile}
                    onClick={() => handleClickRefAdmSolicitations(0)}
                  >
                    <TrackChanges color="primary" style={{fontSize: 80}} />
                    <Typography variant="subtitle2" align="center">
                      Avaliar Solicitações
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-around"
                spacing={2}
                style={{marginTop: 30}}
              >
                <Grid item md={6}>
                  <Paper
                    elevation={1}
                    className={classes.cardAdmMobile}
                    onClick={() => handleClickRefAdmSolicitations(2)}
                  >
                    <Warning color="primary" style={{fontSize: 80}} />
                    <Typography variant="subtitle2" align="center">
                      Aplicar Penalidades
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Hidden>
          </Hidden>

          <Box className="solicitation-container-items">
            <div className="nav-bar-web">
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleTab}
                  style={{textColor: "#333"}}
                  indicatorColor="secondary"
                  aria-label="Tab-index"
                >
                  <LinkTab
                    href="/solicitacoes"
                    label="Avaliar Solicitações"
                    value={0}
                    {...a11TabProps(0)}
                  />
                  <LinkTab
                    href="/alterar-periodo"
                    label="Alterar Período"
                    value={1}
                    {...a11TabProps(1)}
                  />
                  <LinkTab
                    href="/aplicar-penalizacao"
                    label="Aplicar Penalidades"
                    value={2}
                    {...a11TabProps(2)}
                  />
                </Tabs>
              </AppBar>
            </div>
            <Hidden mdDown={!admOptionSelected}>
              <>
                <TabPanel value={value} index={0} type={"solicitation"}>
                  <Solicitations
                    classes={classes}
                    setValue={setValue}
                    admOptionSelected={admOptionSelected}
                    setAdmOptionSelected={setAdmOptionSelected}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} type={"solicitation"}>
                  <Period
                    classes={classes}
                    setValue={setValue}
                    admOptionSelected={admOptionSelected}
                    setAdmOptionSelected={setAdmOptionSelected}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} type={"solicitation"}>
                  <Penalize
                    classes={classes}
                    setValue={setValue}
                    admOptionSelected={admOptionSelected}
                    setAdmOptionSelected={setAdmOptionSelected}
                  />
                </TabPanel>
              </>
            </Hidden>
          </Box>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default Admin;
