import {adminConstants as cc} from "@/store/types/admin";

const INITIAL_STATE = {
  allSolicitations: [],
  allPenalties: [],
  members: [],
  currentPeriod: "",
  isLoading: false,
  error: false,
};

export default function admin(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cc.LOAD_ALL_USERS_SOLICITATIONS:
    case cc.LOAD_CURRENT_PERIOD:
    case cc.UPDATE_CURRENT_PERIOD:
    case cc.LOAD_ALL_REGISTRED_PENALTIES:
    case cc.LOAD_ALL_MEMBERS:
    case cc.APPLY_PENALTY:
      return {...state, isLoading: true, error: ""};
    case cc.LOAD_ALL_USERS_SOLICITATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allSolicitations: action.solicitations,
      };
    case cc.LOAD_ALL_USERS_SOLICITATIONS_FAILURE:
      return {...state, isLoading: false, error: true};
    case cc.LOAD_CURRENT_PERIOD_SUCCESS:
      return {...state, isLoading: false, currentPeriod: action.current};
    case cc.LOAD_CURRENT_PERIOD_FAILURE:
      return {...state, isLoading: false, error: true};
    case cc.UPDATE_CURRENT_PERIOD_SUCCESS:
      return {...state, isLoading: false, currentPeriod: action.updatePeriod};
    case cc.UPDATE_CURRENT_PERIOD_FAILURE:
      return {...state, isLoading: false, error: true};
    case cc.LOAD_ALL_REGISTRED_PENALTIES_SUCCESS:
      return {...state, isLoading: false, allPenalties: action.penalties};
    case cc.LOAD_ALL_REGISTRED_PENALTIES_FAILURE:
      return {...state, isLoading: false, error: true};
    case cc.LOAD_ALL_MEMBERS_SUCCESS:
      return {...state, isLoading: false, members: action.members};
    case cc.LOAD_ALL_MEMBERS_FAILURE:
      return {...state, isLoading: false, error: true};
    case cc.APPLY_PENALTY_SUCCESS:
      return {...state, isLoading: false};
    case cc.APPLY_PENALTY_FAILURE:
      return {...state, isLoading: false, error: action.error};
    default:
      return state;
  }
}
