import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

//Custom functions

import {formatDate} from "@/services/formatDate";

//Redux actions

import {loadUserSolicitations} from "@/store/actions/userSolicitations";

//Material Components

import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Modal,
  Backdrop,
  Fade,
  Checkbox,
  Typography,
} from "@material-ui/core";

//Material Icons

import {ExpandMore, Close, GetApp} from "@material-ui/icons";

//Custom components

import types from "../utils/Constants";
import api from "../../../services/api";

const HistoryUser = (props) => {
  const dispatch = useDispatch();
  const {classes} = props;

  const {solicitations} = useSelector(
    ({userSolicitations}) => userSolicitations
  );

  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [
    openModalCancelSolicitation,
    setOpenModalCancelSolicitation,
  ] = useState(false);
  const [checkRefund, setCheckRefund] = useState(false); //Checkboxes mobile de filtros das solicitações
  const [checkShutdown, setCheckShutdown] = useState(false);
  const [checkWithdrawal, setCheckWithdrawal] = useState(false);
  const [currentSolicitationId, setCurrentSolicitationId] = useState(""); //Guardar o id da solicitação clicada

  //Data
  const [allUserSolicitation, setAllUserSolicitation] = useState([]);

  //Methods

  useEffect(() => {
    if (!solicitations.length) {
      dispatch(loadUserSolicitations());
    }
  }, []);

  useEffect(() => {
    if (solicitations[0]) {
      setAllUserSolicitation(solicitations);
    }
  }, [solicitations]);

  const handleOpenModalDetails = (currentId) => {
    setCurrentSolicitationId(currentId);
    setOpenModalDetails(true);
  };

  const handleCloseModalDetails = () => {
    setOpenModalDetails(false);
    setCurrentSolicitationId("");
  };

  const handleOpenModalCancelSolicitation = (currentId) => {
    setCurrentSolicitationId(currentId);
    setOpenModalCancelSolicitation(true);
  };

  const handleCloseModalCancelSolicitation = () => {
    setOpenModalCancelSolicitation(false);
    setCurrentSolicitationId("");
  };

  const handleCheckRefund = (e) => {
    setCheckRefund(e.target.checked);
  };

  const handleCheckShutdown = (e) => {
    setCheckShutdown(e.target.checked);
  };

  const handleCheckWithdrawal = (e) => {
    setCheckWithdrawal(e.target.checked);
  };

  const selectSolicitationType = (solicitationId) => {
    switch (solicitationId) {
      case 1:
        return "Desligamento";
      case 2:
        return "Afastamento";
      case 3:
        return "Reembolso";
    }
  };

  const selectSolicitationStatus = (solicitationStatus) => {
    switch (solicitationStatus) {
      case "Aguardando": {
        return {class: "buttonPending", text: "Pendente"};
      }
      case "Deferido": {
        return {class: "buttonAccept", text: "Aprovado"};
      }
      case "Indeferido": {
        return {class: "buttonDenied", text: "Negado"};
      }
      case "Cancelado": {
        return {class: "buttonCancel", text: "Cancelado"};
      }
    }
  };

  const getSolicitationById = () => {
    return allUserSolicitation.filter(
      (solicitation) => solicitation.id === currentSolicitationId
    );
  };

  const handleCancelSolicitation = () => {
    api
      .delete(`/events/${currentSolicitationId}`)
      .then((response) => {
        dispatch(loadUserSolicitations());
      })
      .catch((err) => {});
    handleCloseModalCancelSolicitation();
  };

  const handleDownloadSolicitationPdf = () => {
    const current = getSolicitationById()[0];
    api({
      url: current.event_picture_url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Solicitacao.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="history-content">
      <div className="history-table-web">
        {allUserSolicitation.length !== 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tablePenalizationHead}>
                    Data
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Tipo
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Recurso
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Informações
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Opções
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUserSolicitation.map((solicitation) => {
                  return (
                    <TableRow key={solicitation.id}>
                      <TableCell
                        className={classes.tablePenalizationField}
                        component="th"
                        scope="row"
                      >
                        {solicitation && formatDate(solicitation.created_at)}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        {selectSolicitationType(solicitation.type_id)}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={
                            classes[
                              `${
                                selectSolicitationStatus(solicitation.status)[
                                  "class"
                                ]
                              }`
                            ]
                          }
                          size="small"
                          variant="contained"
                        >
                          {
                            selectSolicitationStatus(solicitation.status)[
                              "text"
                            ]
                          }
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={classes.buttonDetails}
                          size="small"
                          variant="contained"
                          onClick={(e) =>
                            handleOpenModalDetails(solicitation.id)
                          }
                        >
                          Detalhes
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={classes.buttonCancel}
                          size="small"
                          color="default"
                          variant="contained"
                          onClick={(e) =>
                            handleOpenModalCancelSolicitation(solicitation.id)
                          }
                          disabled={
                            solicitation.status === types.Indeferido ||
                            solicitation.status === types.Cancelado
                          }
                        >
                          Cancelar Pedido
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center" color="primary">
            Você ainda não realizou nenhuma solicitação
          </Typography>
        )}
      </div>
      <div className="history-table-mobile">
        {allUserSolicitation.length !== 0 ? (
          <>
            <div className="history-table-mobile-row-nopadding">
              <p className={classes.tablePenalizationCheckText}>
                <Checkbox
                  inputProps={{"aria-label": "primary checkbox"}}
                  color="default"
                  size="small"
                  checked={checkRefund}
                  onChange={handleCheckRefund}
                />
                Reembolsos
              </p>
              <p className={classes.tablePenalizationCheckText}>
                <Checkbox
                  inputProps={{"aria-label": "primary checkbox"}}
                  color="default"
                  size="small"
                  checked={checkShutdown}
                  onChange={handleCheckShutdown}
                />
                Afastamentos
              </p>
              <p className={classes.tablePenalizationCheckText}>
                <Checkbox
                  inputProps={{"aria-label": "primary checkbox"}}
                  color="default"
                  size="small"
                  checked={checkWithdrawal}
                  onChange={handleCheckWithdrawal}
                />
                Desligamentos
              </p>
            </div>
            <div className="history-table-mobile-row">
              <p className={classes.tablePenalizationHeadMobile}>Data</p>
              <p className={classes.tablePenalizationHeadMobile}>Tipo</p>
              <p className={classes.tablePenalizationHeadMobile}>Status</p>
            </div>
            {allUserSolicitation.map((solicitation) => {
              return (
                <ExpansionPanel
                  elevation={0}
                  className={classes.expandedGeneral}
                  key={solicitation.id}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="history-table-mobile-row">
                      <p className={classes.tablePenalizationFieldMobile}>
                        {solicitation && formatDate(solicitation.created_at)}
                      </p>
                      <p className={classes.tablePenalizationFieldMobile}>
                        {selectSolicitationType(solicitation.type_id)}
                      </p>
                      <Button
                        className={
                          classes[
                            `${
                              selectSolicitationStatus(solicitation.status)[
                                "class"
                              ]
                            }`
                          ]
                        }
                        size="small"
                        variant="contained"
                      >
                        {selectSolicitationStatus(solicitation.status)["text"]}
                      </Button>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="history-table-mobile-expanded">
                      <p className={classes.tablePenalizationFieldMobile}>
                        {"Opções de Solicitações"}
                      </p>
                      <div className="history-table-mobile-expanded-btn">
                        <Button
                          className={classes.buttonDetails}
                          size="small"
                          variant="contained"
                          onClick={(e) =>
                            handleOpenModalDetails(solicitation.id)
                          }
                        >
                          Detalhes
                        </Button>
                        <Button
                          className={classes.buttonCancel}
                          size="small"
                          variant="contained"
                          onClick={(e) =>
                            handleOpenModalCancelSolicitation(solicitation.id)
                          }
                          disabled={
                            solicitation.status === types.Indeferido ||
                            solicitation.status === types.Cancelado
                          }
                        >
                          Cancelar
                        </Button>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </>
        ) : (
          <Typography
            style={{marginTop: "10%"}}
            variant="body1"
            align="center"
            color="primary"
          >
            Você não possui solicitações cadastradas
          </Typography>
        )}
      </div>

      <Modal
        aria-labelledby="modal-details-title"
        aria-describedby="modal-details-description"
        className={classes.modal}
        open={openModalDetails}
        onClose={handleCloseModalDetails}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalDetails} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-details-title" className={classes.modalTitle}>
              Detalhes da Solicitação
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalDetails}
              />
            </h2>
            <FormControl name={3} component="form">
              <div className={classes.modalContent}>
                <p className={classes.modalContentSubtitle}>Documento</p>
                <p
                  className={classes.modalContentSubtitleMin}
                  onClick={handleDownloadSolicitationPdf}
                >
                  Documento.pdf <GetApp size="small" />
                </p>
                <p className={classes.modalContentSubtitle}>
                  Resposta da diretoria
                </p>
                <p className={classes.modalContentSubtitleMin}>
                  Olá Membro Tal, seu pedido foi negado pelo motivo de não ter
                  seguido as regras para envio de solicitações e não ter apre-
                  sentado informações suficientes para validar o mesmo.
                </p>
              </div>
              <Button
                type="button"
                className={classes.buttonSubmitModal}
                onClick={handleCloseModalDetails}
                color="secondary"
              >
                CONFIRMAR
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-cancelSolicitation-title"
        aria-describedby="modal-cancelSolicitation-description"
        className={classes.modal}
        open={openModalCancelSolicitation}
        onClose={handleCloseModalCancelSolicitation}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalCancelSolicitation} elevation={1}>
          <div className={classes.modalContainer}>
            <h2
              id="modal-cancelSolicitation-title"
              className={classes.modalTitle}
            >
              Cancelamento de Pedido
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalCancelSolicitation}
              />
            </h2>
            <FormControl
              name={3}
              component="form"
              onSubmit={handleCancelSolicitation}
            >
              <div className={classes.modalContent}>
                <p>
                  Ao clicar em 'CONFIRMAR' você <br /> estará cancelando o seu
                  pedido de desligamento. <br />
                  <br /> Sua ação não poderá ser desfeita.
                </p>
              </div>
              <Button
                type="button"
                className={classes.buttonSubmitModalGreen}
                onClick={handleCancelSolicitation}
                color="secondary"
              >
                CONFIRMAR
              </Button>
              <Button
                type="button"
                className={classes.buttonCancelModalRed}
                onClick={handleCloseModalCancelSolicitation}
                color="secondary"
              >
                VOLTAR
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default HistoryUser;
