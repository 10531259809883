import {userSolicitationsconstants as cc} from "@/store/types/userSolicitations";

const INITIAL_STATE = {
  solicitations: {},
  loading: false,
  error: false,
};

export default function userSolicitations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cc.LOAD_USER_SOLICITATIONS:
      return {...state, loading: true, error: false};
    case cc.LOAD_USER_SOLICITATIONS_SUCCESS:
      return {...state, loading: false, solicitations: action.solicitations};
    case cc.LOAD_USER_SOLICITATIONS_FAILURE:
      return {...state, loading: false, error: true};
    case cc.SEND_USER_SOLICITATIONS:
      return {...state, loading: true, error: false};
    case cc.SEND_USER_SOLICITATIONS_SUCCESS:
      return {...state, loading: false, error: false};
    case cc.SEND_USER_SOLICITATIONS_FAILURE:
      return {...state, loading: false, error: true};
    default:
      return state;
  }
}
