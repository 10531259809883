import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";

//Material Components

import {
  ThemeProvider,
  AppBar,
  Avatar,
  Tabs,
  Box,
  Badge,
} from "@material-ui/core";

//Material Icons

import {Person, ReportProblem} from "@material-ui/icons";

//Styles

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";

//Custom Components

import {TabPanel, LinkTab, a11TabProps} from "../Widgets/Tabs";
import Lapis from "@/assets/mode-edit.svg";
import Profile from "./profile";
import Penalization from "./penalization";

const Perfil = (props) => {
  const classes = useStyles(); //Custom Material UI styles
  const {user} = useSelector(({userProfile}) => userProfile); //Redux state

  //Functionality
  const [value, setValue] = useState(0); //Control Tabs
  const refInputProfile = useRef(null);
  const [btnUpdateFields, setBtnUpdateFields] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");

  useEffect(() => {
    props.setTitle("PERFIL");
  }, []);

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleShowBtnUpdateFields = () => {
    setBtnUpdateFields(!btnUpdateFields);
  };

  const chooseProfilePicture = () => {
    refInputProfile.current.click();
  };

  return (
    <Container>
      <ThemeProvider theme={themeMaterial}>
        <div className="perfil-content">
          <div className="perfil-avatar-mobile">
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              className="perfil-avatar-img"
              badgeContent={
                btnUpdateFields && (
                  <Avatar
                    alt="Avatar"
                    src={Lapis}
                    className="perfil-avatar-img-small"
                    onClick={chooseProfilePicture}
                  />
                )
              }
            >
              <Avatar
                alt="Avatar"
                src={user?.profile_picture_url}
                className="perfil-avatar-mobile-img"
              />
              <input
                onChange={({target}) => setProfilePicture(target.files[0])}
                style={{display: "none"}}
                type="file"
                ref={refInputProfile}
              />
            </Badge>
            <h2>{user.username}</h2>
          </div>

          <div className="nav-bar-mobile">
            <AppBar position="static" color="default" elevation={0}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleTab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Tab-index"
              >
                <LinkTab
                  className={classes.tabGeneral}
                  href="/alterarPerfil"
                  label="Perfil"
                  value={0}
                  icon={<Person />}
                  {...a11TabProps(0)}
                />
                <LinkTab
                  className={classes.tabGeneral}
                  href="/penalizações"
                  label="Penalizações"
                  value={1}
                  icon={<ReportProblem />}
                  {...a11TabProps(1)}
                />
              </Tabs>
            </AppBar>
          </div>

          <Box className="perfil-container-items">
            <div className="nav-bar-web">
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleTab}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="Tab-index"
                >
                  <LinkTab
                    href="/alterarPerfil"
                    label="Perfil"
                    value={0}
                    icon={<Person />}
                    {...a11TabProps(0)}
                  />
                  <LinkTab
                    href="/penalizações"
                    label="Penalizações"
                    value={1}
                    icon={<ReportProblem />}
                    {...a11TabProps(1)}
                  />
                </Tabs>
              </AppBar>
            </div>

            <TabPanel value={value} index={0} type={"perfil"}>
              <Profile
                classes={classes}
                chooseProfilePicture={chooseProfilePicture}
                btnUpdateFields={btnUpdateFields}
                handleShowBtnUpdateFields={handleShowBtnUpdateFields}
                profilePicture={profilePicture}
                setProfilePicture={setProfilePicture}
              />
            </TabPanel>

            <TabPanel value={value} index={1} type={"perfil"}>
              <Penalization classes={classes} />
            </TabPanel>
          </Box>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default Perfil;
