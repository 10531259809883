import styled from "styled-components";

export const Apresentation = styled.div`
  height: 100vh;
  min-height: 700px;
  background: url("${(props) => props.astronaut}") no-repeat,
    url("${(props) => props.background}") no-repeat;
  background-size: 50% auto, 140% auto;
  background-position-y: -60%, 42%;
  background-position-x: right, center;

  animation: background-parallax forwards 2s ease-out;

  @keyframes background-parallax {
    to {
      background-size: 80% auto, 100% auto;
      background-position-y: 36%, 24%;
    }
  }

  .filter {
    height: 100%;
    background: rgba(141, 78, 164, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5vw;

    #text-title {
      max-width: 50%;
      font-size: 6rem;
      font-weight: lighter;
      color: #fff;
      letter-spacing: -0.03px;
      margin-bottom: 32px;
    }

    #text-description {
      font-size: 3.4rem;
      font-weight: 400;
      color: ${({theme}) => theme.color.white.main};
      letter-spacing: 0.01px;
      max-width: 36.5vw;
    }
  }

  @media screen and (max-width: 1024px) and (max-aspect-ratio: 1/1) {
    background-size: 85% auto, 140% auto;
    background-position-y: 920%, 68%;
    @keyframes background-parallax {
      to {
        background-size: 85% auto, 110% auto;
        background-position-y: 170%, -46%;
      }
    }
  }

  @media screen and (max-width: 500px) and (max-aspect-ratio: 1/1) {
    background-size: 70% auto, 110% auto;
    background-position-y: 136%, -150%;
    @keyframes background-parallax {
      to {
        background-size: 70% auto, 110% auto;
        background-position-y: 85%, 140%;
      }
    }

    .filter {
      #text-title {
        max-width: 90vw;
      }
      #text-description {
        max-width: 60vw;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .filter {
      justify-content: flex-start;
      padding-top: 20vh;
    }
  }
`;

export const Container = styled.div`
  height: 100%;

  .body {
    overflow-x: hidden;
    min-height: 100%;
    background: linear-gradient(
      rgba(152, 98, 172, 1) 0%,
      rgba(73, 17, 97, 1) 15%,
      rgba(48, 0, 73, 1) 100%
    );
    box-shadow: 0px -40px 40px 12px rgba(152, 98, 172, 1);
    position: relative;
    z-index: 2;

    .transition-texts {
      display: flex;
      flex-direction: column;
      margin-bottom: 17vh;
      margin-top: 5vw;

      #text-1 {
        align-self: center;
        max-width: 80vw;
        font-size: 3.4rem;
        color: ${({theme}) => theme.color.white.main};
        letter-spacing: 0.01px;

        p {
          font-size: 2.4rem;
          margin-top: 16px;
          text-align: end;
        }
      }
    }

    .container-infos {
      height: 68vh;
      min-height: 500px;
      width: 75vw;
      margin-bottom: 7.4vh;
      background: ${({theme}) => theme.color.primary.light};
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content {
        width: 30.2%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        #infos-icons {
          height: 7.9vh;
          width: 4.4vw;
          margin-bottom: 11px;
        }

        #text-title {
          font-size: 4.8rem;
          color: #fff;
          margin-bottom: 16px;
        }
        #text-description {
          font-size: 3.4rem;
          color: ${({theme}) => theme.color.white.dark};
        }
      }

      .img {
        width: 41%;
        height: 83%;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .info-1 {
      margin-left: 11vw;
      .content {
        margin-left: 10vw;
      }
    }
    .info-2 {
      margin-left: 16vw;
      .content {
        margin-right: 10vw;
      }
    }
    .info-3 {
      margin-left: 12vw;
      .content {
        margin-left: 10vw;
      }
    }

    .info-boat {
      height: 100vh;
      min-height: 600px;
      position: relative;

      img.wave {
        position: absolute;
        bottom: -2px;
        width: 100%;
        height: auto;
      }
      img.boat {
        position: absolute;
        height: auto;
        width: 27.2vw;
        bottom: 6%;
        left: 25%;
      }
      .cloud {
        position: absolute;
        height: auto;
        width: 30vw;
        right: 0;
        top: 20%;
        text-align: right;
        h2 {
          font-size: 5rem;
          color: ${({theme}) => theme.color.white.dark};
          margin-bottom: 16px;
          font-weight: 300;
        }
        p {
          font-size: 3rem;
          color: ${({theme}) => theme.color.white.dark};
        }
      }
    }

    .peoples {
      min-height: 196vh;
      background: ${({theme}) => theme.color.white.main};
      display: flex;
      flex-direction: column;
      padding-top: 90px;

      .cards {
        display: flex;
        position: relative;
        z-index: 2;
        align-self: center;
        width: 700px;
        height: 700px;
        border-radius: 30px;

        .card-img {
          width: 50%;
          height: 100%;
          background: no-repeat;
          background-size: cover;
          background-position: 20%;
          display: flex;

          .social {
            display: flex;
            justify-content: space-between;
            width: 35%;
            align-self: flex-end;
            margin: 2.4rem;
          }
        }

        .card-content {
          background: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;

          .comment {
            width: 70%;
            font-size: 1.8rem;
            color: ${({theme}) => theme.color.gray.main};
          }
        }
      }
      .card-1 {
        margin-bottom: -25vh;

        .card-img {
          border-radius: 30px 0 0 30px;
        }

        .card-content {
          border-radius: 0 30px 30px 0;
        }
      }

      .card-2 {
        margin-top: -18vh;

        .card-img {
          border-radius: 0 30px 30px 0;
          .social {
            margin-left: auto;
          }
        }

        .card-content {
          border-radius: 30px 0 0 30px;
        }
      }

      .img-team-divider {
        min-height: 74vh;
        background: url("${(props) => props.teamBg}") no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
      }

      .bottom {
        background: url("${(props) => props.bottomBg}") no-repeat;
        background-size: 100% auto;
        background-position-y: 100%;
        margin-top: 8vw;
        height: 100vh;
        min-height: 700px;
        position: relative;

        .content {
          position: absolute;
          top: 12%;
          right: 8%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          #text-title {
            font-size: 6rem;
            font-weight: lighter;
            color: ${({theme}) => theme.color.black.main};
            letter-spacing: -0.03px;
            text-align: right;
            margin-bottom: 16px;
          }
          #text-description {
            font-size: 3rem;
            font-weight: 400;
            color: #726977;
            letter-spacing: 0.01px;
            max-width: 36.5vw;
            text-align: right;
            margin-bottom: 24px;
          }
          .button {
            width: 280px;
            height: 56px;
            font-size: 1.6rem;
            margin-top: 8px;
            margin-bottom: 8px;
          }
        }
      }
    }
    @media screen and (max-width: 1366px) {
      .info-boat {
        height: 120vh;
      }
      .peoples {
        .cards {
          width: 600px;
          height: 530px;
        }
      }
    }

    @media screen and (max-width: 1050px) {
      .info-1,
      .info-2,
      .info-3 {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media screen and (max-width: 1024px) {
      .peoples {
        .bottom {
          .content {
            #text-description {
              max-width: 60vw;
            }
          }
        }
      }

      .info-boat {
        height: 80vh;
      }
    }
    @media screen and (max-width: 870px) {
      .container-infos {
        flex-direction: column;
        padding: 48px 0;
        height: auto;
      }
      .info-1,
      .info-2,
      .info-3 {
        .content {
          margin: 0;
          margin-bottom: 28px;
          width: 80%;

          #infos-icons {
            width: 28px;
            height: auto;
          }

          #text-description {
            font-size: 3.2rem;
          }
        }
        .img {
          width: 80%;
          height: auto;
        }
      }
      .peoples {
        .cards {
          width: 80%;
          height: 70vh;
        }
      }
      .info-boat {
        height: 65vh;
        .cloud {
          font-size: 4rem;
          width: 50vw;
          h2 {
            margin-bottom: 12px;
            font-size: 4rem;
          }
          p {
            font-size: 2rem;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      box-shadow: 0px -80px 40px 72px rgba(152, 98, 172, 1);
      .transition-texts {
        margin-bottom: 48px;
      }
      .peoples {
        .img-team-divider {
          background-attachment: scroll;
        }
        .bottom {
          margin-bottom: 56px;
          .content {
            top: 25%;
            .button {
              width: 180px;
              height: 46px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 670px) {
      .peoples {
        .img-team-divider {
          margin-top: 20vh;
          min-height: 35vh;
        }
        .cards {
          width: 100%;
          height: 50vh;
          border-radius: 0;
          background: transparent;
          box-shadow: 0;

          .card-content {
            border-radius: 0;
            background: transparent;
            .comment {
              width: 80%;
            }
          }
          .card-img {
            border-radius: 0;
            height: 100%;
          }
        }
        .card-1 {
          .card-content {
            .comment {
              margin-top: -90px;
            }
          }
        }
        .card-2 {
          margin-top: -5vh;
          .card-content {
            .comment {
              margin-top: 120px;
            }
          }
        }
      }
      .info-boat {
        height: 55vh;
      }
    }
    @media screen and (max-width: 440px) {
      .info-boat {
        height: 45vh;
        .cloud {
          top: 5%;
        }
      }
      .container-infos {
        width: 90vw;
      }
      .peoples {
        .bottom {
          .content {
            #text-description {
              max-width: 80vw;
            }
            .button {
              width: 152px;
              height: 40px;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
`;
