import React, {useState} from "react";
import {
  ThemeProvider,
  Paper,
  FormControl,
  TextField,
  Button,
} from "@material-ui/core";

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";
import logo from "../../../assets/loginLogo.svg";
import logoMobile from "../../../assets/loginLogoMobile.svg";
import background from "../../../assets/backgroundInterno.png";
import groupImg from "../../../assets/grupo.svg";
import groupImgError from "../../../assets/recoveryPassError.svg";
import successImg from "../../../assets/success-png.png";
import erroImg from "../../../assets/error-png.png";
import api from "../../../services/api";

const RecuperarSenha = () => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [registration, setRegistration] = useState("");

  const [counterRegistration, setCounterRegistration] = useState(0);
  const [successSend, setSuccessSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await api
      .post("/forgot-password", {email, registration})
      .then((response) => setSuccessSend(true))
      .catch((error) => {
        setSuccessSend(true);
        setErrorSend(true);
      });
  };

  return (
    <Container background={background}>
      <ThemeProvider theme={themeMaterial}>
        <Paper className="container-recuperasenha">
          <img src={logo} alt="Emakers Jr." />
          {successSend && (
            <div className="progress-bar">
              <div className={`bar ${errorSend ? `red` : ""}`} />
            </div>
          )}
          {!successSend ? (
            <FormControl component="form" onSubmit={handleSubmit}>
              <TextField
                type="email"
                required
                className="input"
                variant="outlined"
                label="Email"
                onChange={({target}) => setEmail(target.value)}
              />
              <div className="input-registration">
                <TextField
                  required
                  label="Matrícula"
                  className="input"
                  variant="outlined"
                  error={
                    !((counterRegistration === 9) | (counterRegistration === 0))
                  }
                  inputProps={{maxLength: 9, minLength: 9}}
                  onChange={({target}) => {
                    setCounterRegistration(target.value.length);
                    setRegistration(target.value);
                  }}
                />
                <p id="counter">{counterRegistration}/9</p>
              </div>
              <Button className="button-main" variant="contained" type="submit">
                RECUPERAR SENHA
              </Button>
              <a href="/login">
                <Button
                  className={classes.buttonBack}
                  variant="contained"
                  type="button"
                >
                  CANCELAR
                </Button>
              </a>
            </FormControl>
          ) : (
            <div className="success-send">
              <img
                src={errorSend ? groupImgError : groupImg}
                alt="Grupo de Pessoas"
              />
              <p>
                {errorSend
                  ? "Não foi possível recuperar a sua senha."
                  : "Senha enviada para o Email fornecido!"}
              </p>
              <a href="/login">
                <Button
                  className="button-main"
                  variant="contained"
                  type="button"
                >
                  {errorSend ? "VOLTAR" : "FINALIZAR"}
                </Button>
              </a>
            </div>
          )}
        </Paper>

        <Paper className="container-recuperasenha-mobile">
          <img src={logoMobile} alt="Emakers Jr." />
          {!successSend ? (
            <FormControl component="form" onSubmit={handleSubmit}>
              <TextField
                type="email"
                required
                className="input"
                label="Email"
                color="secondary"
                onChange={({target}) => setEmail(target.value)}
              />
              <div className="input-registration">
                <TextField
                  required
                  label="Matrícula"
                  className={classes.inputMobile}
                  color="secondary"
                  error={
                    !((counterRegistration === 9) | (counterRegistration === 0))
                  }
                  inputProps={{maxLength: 9, minLength: 9}}
                  onChange={({target}) => {
                    setCounterRegistration(target.value.length);
                    setRegistration(target.value);
                  }}
                />
              </div>
              <Button className="button-main" variant="contained" type="submit">
                RECUPERAR SENHA
              </Button>
              <a href="/login">
                <Button
                  className={classes.buttonBack}
                  variant="contained"
                  type="button"
                >
                  CANCELAR
                </Button>
              </a>
            </FormControl>
          ) : (
            <div className="success-send">
              <img src={errorSend ? erroImg : successImg} alt="status" />
              <p>
                {errorSend
                  ? "Não foi possível recuperar a sua senha."
                  : "Senha enviada para o Email fornecido!"}
              </p>
              <a href="/login">
                <Button
                  className="button-main"
                  variant="contained"
                  type="button"
                >
                  {errorSend ? "VOLTAR" : "LOGAR"}
                </Button>
              </a>
            </div>
          )}
        </Paper>
      </ThemeProvider>
    </Container>
  );
};

export default RecuperarSenha;
