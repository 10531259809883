import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  useHistory,
  Link,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import {logout} from "@/services/auth";

import {
  loadUserProfile,
  loadUserPenalizations,
  clearUserProfile,
} from "@/store/actions/userProfile";
import {loadUserSolicitations} from "@/store/actions/userSolicitations";
import {loadAllMembers} from "@/store/actions/searchMembers";
import {loadAllPenalizations} from "@/store/actions/userPenalizations";
import {
  loadAllUsersSolicitations,
  loadAllPenalties,
  loadPeriod,
  loadAllMembersComplete,
} from "@/store/actions/admin";

import {
  ThemeProvider,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Tabs,
  Box,
  Typography,
  Hidden,
  Drawer,
  Popover,
  Button,
  Divider,
} from "@material-ui/core";

import {
  Menu,
  Close,
  Person,
  Assignment,
  People,
  ReportProblemRounded,
  ExitToApp,
  Settings,
} from "@material-ui/icons";

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";
import MountAnimation from "@/components/MountAnimation";
import Logo from "@/assets/logo-lateral-semfundo.svg";
import {TabPanel, LinkTab, a11TabProps} from "@/components/Widgets/Tabs";
import Profile from "@/components/DashProfile";
import Solicitation from "@/components/DashSolicitations";
import Members from "@/components/DashMembers";
import Penalization from "@/components/DashPenalizations";
import Admin from "@/components/DashAdmin";

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const {path, url} = useRouteMatch();

  const {user} = useSelector(({userProfile}) => userProfile);

  const [title, setTitle] = useState("NÃO DEFINIDO");
  const [mobileOpenSidebar, setMobileOpenSidebar] = useState(false);
  const [profileOptions, setProfileOptions] = useState(null);

  //Popup
  const openOptions = Boolean(profileOptions);
  const idOptions = openOptions ? "options-profile" : undefined;

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadUserPenalizations());
    dispatch(loadUserSolicitations());
    dispatch(loadAllMembers());
    dispatch(loadAllPenalizations());
    dispatch(loadAllUsersSolicitations());
    dispatch(loadAllPenalties());
    dispatch(loadPeriod());
    dispatch(loadAllMembersComplete());
  }, []);

  const handleSidebarMobile = () => {
    setMobileOpenSidebar(!mobileOpenSidebar);
  };

  const handleOpenProfileOptions = (event) => {
    setProfileOptions(event.currentTarget);
  };

  const handleCloseProfileOptions = () => {
    setProfileOptions(null);
  };

  const handleHasAdmin = () => {
    const role = user.roles;
    if (role && role.length) {
      return true;
    }
    return false;
  };

  const handleLogout = async () => {
    await logout();
    dispatch(clearUserProfile());
    history.push("/login");
  };

  const getLinks = () => {
    return (
      <>
        <Link
          className={title === "PERFIL" ? classes.linkSelected : classes.link}
          to={`${url}/perfil`}
        >
          <Person className={classes.linkIcon} />
          {mobileOpenSidebar && (
            <Typography
              style={{color: "#fff"}}
              variant="caption"
              align="center"
            >
              Perfil
            </Typography>
          )}
        </Link>
        <Link
          className={title === "PEDIDOS" ? classes.linkSelected : classes.link}
          to={`${url}/pedidos`}
        >
          <Assignment className={classes.linkIcon} />
          {mobileOpenSidebar && (
            <Typography
              style={{color: "#fff"}}
              variant="caption"
              align="center"
            >
              Pedidos
            </Typography>
          )}
        </Link>
        <Link
          className={title === "MEMBROS" ? classes.linkSelected : classes.link}
          to={`${url}/membros`}
        >
          <People className={classes.linkIcon} />
          {mobileOpenSidebar && (
            <Typography
              style={{color: "#fff"}}
              variant="caption"
              align="center"
            >
              Membros
            </Typography>
          )}
        </Link>
        <Link
          className={
            title === "PENALIZAÇÕES" ? classes.linkSelected : classes.link
          }
          to={`${url}/penalizações`}
        >
          <ReportProblemRounded className={classes.linkIcon} />
          {mobileOpenSidebar && (
            <Typography
              style={{color: "#fff"}}
              variant="caption"
              align="center"
            >
              Penalizações
            </Typography>
          )}
        </Link>
        {handleHasAdmin() && (
          <Link
            className={
              title === "ADMINISTRAÇÃO" ? classes.linkSelected : classes.link
            }
            to={`${url}/administração`}
          >
            <Settings className={classes.linkIcon} />
            {mobileOpenSidebar && (
              <Typography
                style={{color: "#fff"}}
                variant="caption"
                align="center"
              >
                Administração
              </Typography>
            )}
          </Link>
        )}
      </>
    );
  };

  const sidebarWeb = (props) => {
    return (
      <Drawer
        className={classes.tabWeb}
        variant={!props ? "permanent" : "persistent"}
        style={{width: "100px", flexShrink: 0}}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={mobileOpenSidebar ? "right" : "left"}
        {...props}
      >
        {getLinks()}
      </Drawer>
    );
  };

  const sidebarMobile = () => {
    return (
      <Drawer
        variant="temporary"
        anchor={"right"}
        open={mobileOpenSidebar}
        onClose={handleSidebarMobile}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Close onClick={handleSidebarMobile} />
        {getLinks()}
        <Button
          className={classes.btnLogout}
          variant="contained"
          color="primary"
          endIcon={<ExitToApp />}
          onClick={handleLogout}
        >
          Sair
        </Button>
      </Drawer>
    );
  };

  return (
    <Container>
      <MountAnimation
        title={user?.username && `Bem vindo ${user?.username}`}
        backgroundColor="#5e2075"
      />
      <ThemeProvider theme={themeMaterial}>
        <AppBar position="fixed" elevation={0}>
          <Toolbar className="toolbar">
            <img src={Logo} alt="Emakers" className="logo" />
            <Typography
              align="center"
              variant="h5"
              className={classes.navTitle}
            >
              {title}
            </Typography>
            <IconButton
              edge="end"
              className="toolbar-menu"
              color="inherit"
              aria-label="menu"
              onClick={handleSidebarMobile}
            >
              <Menu />
            </IconButton>
            <div className="toolbar-avatar-container">
              <Avatar
                alt="dashboard"
                src={user?.profile_picture_url}
                className="toolbar-avatar"
                onClick={handleOpenProfileOptions}
                variant="circle"
              />
              <Popover
                id={idOptions}
                open={openOptions}
                anchorEl={profileOptions}
                onClose={handleCloseProfileOptions}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Button
                  className={classes.btnLogout}
                  variant="contained"
                  color="primary"
                  endIcon={<ExitToApp />}
                  onClick={handleLogout}
                >
                  Sair
                </Button>
              </Popover>
            </div>
          </Toolbar>
        </AppBar>

        <div className="dashboard-content">
          <Hidden smDown>{sidebarWeb()}</Hidden>
          <Hidden smUp>{sidebarMobile()}</Hidden>

          <Box className="dashboard-container-items">
            <Switch>
              <Route exact path={`${path}/perfil`}>
                <Profile setTitle={setTitle} />
              </Route>
              <Route path={`${path}/pedidos`}>
                <Solicitation setTitle={setTitle} />
              </Route>
              <Route path={`${path}/membros`}>
                <Members setTitle={setTitle} />
              </Route>
              <Route path={`${path}/penalizações`}>
                <Penalization setTitle={setTitle} />
              </Route>
              <Route path={`${path}/administração`}>
                <Admin setTitle={setTitle} />
              </Route>
              <Redirect from="*" to={`${path}/perfil`} />
            </Switch>
          </Box>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default Dashboard;
