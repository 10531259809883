import React, {useState, useEffect} from "react";

//Material Components

import {ThemeProvider, AppBar, Tabs, Box} from "@material-ui/core";

//Material Icons

import {History, Send} from "@material-ui/icons";

//Custom Styles

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";

//Custom components

import {TabPanel, LinkTab, a11TabProps} from "../Widgets/Tabs";
import Solicitation from "./solicitation";
import HistoryUser from "./history";

const Solicitacao = (props) => {
  const classes = useStyles(); //Classes Personalizadas MUI (Arquivo customMUI)

  //Functionality
  const [value, setValue] = useState(0); //Controlar Abas

  useEffect(() => {
    props.setTitle("PEDIDOS");
  }, []);

  const handleTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <ThemeProvider theme={themeMaterial}>
        <div className="solicitation-content">
          <div className="nav-bar-mobile">
            <AppBar position="static" color="default" elevation={0}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleTab}
                textColor="primary"
                indicatorColor="primary"
                aria-label="Tab-index"
              >
                <LinkTab
                  className={classes.tabGeneral}
                  href="/solicitation"
                  label="Solicitar"
                  value={0}
                  icon={<Send />}
                  {...a11TabProps(0)}
                />
                <LinkTab
                  className={classes.tabGeneral}
                  href="/historico"
                  label="Histórico"
                  value={1}
                  icon={<History />}
                  {...a11TabProps(1)}
                />
              </Tabs>
            </AppBar>
          </div>

          <Box className="solicitation-container-items">
            <div className="nav-bar-web">
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleTab}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="Tab-index"
                >
                  <LinkTab
                    href="/solicitation"
                    label="Solicitar"
                    value={0}
                    icon={<Send />}
                    {...a11TabProps(0)}
                  />
                  <LinkTab
                    href="/historico"
                    label="Histórico"
                    value={1}
                    icon={<History />}
                    {...a11TabProps(1)}
                  />
                </Tabs>
              </AppBar>
            </div>

            <TabPanel value={value} index={0} type={"solicitation"}>
              <Solicitation classes={classes} />
            </TabPanel>

            <TabPanel value={value} index={1} type={"solicitation"}>
              <HistoryUser classes={classes} />
            </TabPanel>
          </Box>
        </div>
      </ThemeProvider>
    </Container>
  );
};

export default Solicitacao;
