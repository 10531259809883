import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation: scrollOff 1.2s forwards;

  .apresentation {
    z-index: 3;
    height: 100vh;
    min-height: 700px;
    background: url("${({apresentationImg}) => apresentationImg}") no-repeat,
      url("${({apresentationBg}) => apresentationBg}") no-repeat;
    background-size: 80% auto, 100% 100%;
    background-position-y: 80%, center;
    background-position-x: center;
    position: relative;
    display: flex;
    flex-direction: column;

    .texts-container {
      z-index: 2;
      margin-top: 18vh;
      margin-right: 8vw;
      align-self: flex-end;
      max-width: 33%;

      h2 {
        margin-bottom: 24px;
        font-size: 6rem;
        color: #fff;
        font-weight: lighter;
        letter-spacing: -0.03px;
      }

      h3 {
        font-size: 3.4rem;
        color: ${({theme}) => theme.color.white.main};
        letter-spacing: 0.01px;
        font-weight: normal;
      }
    }
  }

  h2#depoimento-redacao {
    width: 100%;
    margin-bottom: 84px;
    margin-top: 64px;
    text-align: justify;
    font-weight: normal;
    padding: 0 20%;
    color: ${({theme}) => theme.color.gray.main};

    p {
      font-size: 2rem;
      margin-top: 16px;
    }
  }

  h1#divider-text {
    max-width: 59.6vw;
    align-self: center;
    text-align: center;
    font-size: 6rem;
    font-weight: lighter;
    color: ${({theme}) => theme.color.gray.main};
    letter-spacing: -0.03px;
    line-height: 1.5;
    margin-top: 10vw;
  }

  @media screen and (max-width: 970px) {
    .apresentation {
      .texts-container {
        margin-right: 5vw;
        max-width: 40%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .apresentation {
      height: 90vh;
      flex-direction: column-reverse;
      justify-content: flex-end;
      .texts-container {
        margin: 100px auto 0 auto;
        max-width: 90%;
        align-self: flex-end;
      }
    }

    h2#depoimento-redacao {
      padding: 0 10%;
    }

    h1#divider-text {
      max-width: 90vw;
    }
  }
  @media screen and (max-width: 500px) {
    h1#divider-text {
      font-size: 4rem;
    }
  }
  @media screen and (max-width: 410px) {
    .apresentation {
      .texts-container {
        h2 {
          font-size: 5rem;
        }
        h3 {
          font-size: 2.8rem;
        }
      }
    }
  }

  @keyframes scrollOff {
    0% {
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      overflow: visible;
    }
  }
`;

export const ContainerProduct1 = styled.div`
  min-height: 100%;
  min-height: 700px;
  background: ${({theme}) => theme.color.white.main};
  display: flex;
  align-items: center;
  z-index: 2;

  img#logo {
    width: 20.8vw;
    margin-left: -25%;
  }

  .content {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .panels {
      width: 30vw;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .expansion-panels {
        background: none;
        border: none;
        box-shadow: none;
        margin: 0;
        ::before {
          display: none;
        }

        #title {
          font-size: 3.4rem;
          color: ${({theme}) => theme.color.gray.main};
          letter-spacing: 0.01px;
          margin-bottom: -24px;
        }

        #description {
          font-size: 2rem;
          color: ${({theme}) => theme.color.gray.main};
          letter-spacing: 0.01px;
          margin-bottom: -10px;
        }
      }
    }

    a {
      align-self: flex-start;
      margin-left: 10vw;
      margin-top: 5vh;

      button {
        width: 280px;
        height: 56px;
        box-shadow: 3px 3px 6px #0000003d;
        font-size: 1.6rem;
        margin-top: 24px;
      }
    }
  }

  .product-1-imgs {
    width: 50%;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: relative;

    img#logo,
    #progress-bar,
    .texts-container,
    a {
      display: none;
    }

    .swipeable-container {
      width: 100%;
      box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.5);
      border-radius: 15px 0px 0px 15px;

      img {
        position: absolute;
        border-right: none;
        height: 35vw;
        width: 100%;
      }
      .img3 {
        height: 35vw;
        width: 100%;
        box-shadow: 0px 0px 31px -2px rgba(0, 0, 0, 0.5);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .content {
      .panels {
        width: 90%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
    .content {
      display: none;
    }
    .product-1-imgs {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;

      img#logo {
        display: block;
        width: 30vw;
        margin: 0 0 48px 0;
      }
      .swipeable-container {
        border-radius: 4px;
        width: 60%;
        min-height: 50vw;
        img,
        .img3 {
          height: 50vw;
        }
      }
      .texts-container {
        display: block;
        width: 80%;
        margin: 24px auto 24px auto;

        .swipeable-container-texts {
          p.title {
            color: ${({theme}) => theme.color.gray.main};
            font-size: 3.6rem;
            margin-bottom: 16px;
            text-align: center;
          }
          p.description {
            color: ${({theme}) => theme.color.gray.main};
            font-size: 2.6rem;
            text-align: center;
          }
        }
      }
      #progress-bar {
        display: block;
      }
      a {
        display: block;
        button {
          width: 280px;
          height: 56px;
          box-shadow: 3px 3px 6px #0000003d;
          font-size: 1.4rem;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .product-1-imgs {
      img#logo {
        width: 35vw;
      }
      .swipeable-container {
        width: 90%;
        height: 70vw;
        img,
        .img3 {
          height: 70vw;
        }
      }
      a {
        button {
          width: 152px;
          height: 40px;
        }
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 3px;
  height: 100%;
  background: #989898;
  border-radius: 3px;
  position: absolute;
  left: 0;
  top: 12%;

  #bar {
    background: ${({theme}) => theme.color.primary.light};
    width: 100%;
    border-radius: 3px;

    height: ${(props) => props.progress}%;
    transition: height 0.4s linear;
  }

  @media screen and (max-width: 768px) {
    width: 50%;
    height: 3px;
    position: relative;
    top: 0;
    margin: 0 0 48px 0;

    #bar {
      height: 100%;
      width: ${(props) => props.progress}%;
      transition: width 0.4s linear;
    }
  }
`;

export const ContainerProduct2 = styled.div`
  .epoint-area {
    min-height: 50vh;
    position: relative;

    .epoint-trigger {
      bottom: 0;
      position: absolute;
    }
  }

  .epoint {
    opacity: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      position: absolute;
      width: 29vw;
      min-width: 200px;
      height: auto;
      border-radius: 4px;
    }

    #epoint-outside {
      z-index: 2;
      box-shadow: 1px 2px 37px -4px rgba(0, 0, 0, 0.2);
    }
    #epoint-inside {
      box-shadow: 1px 2px 37px -4px rgba(0, 0, 0, 0.65);
    }
  }

  .StickyText {
    display: none;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    opacity: 0;
    text-align: center;

    h2 {
      font-size: 4rem;
      font-weight: lighter;
      color: #676586;
      max-width: 80vw;
      margin: 0 auto;
    }
    p {
      font-size: 2.8rem;
      color: #acaac2;
      max-width: 80vw;
      margin: 0 auto;
    }
  }

  .texts {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 48px;

    .container-text {
      position: relative;
      margin-bottom: 100vh;
      max-width: 24vw;
      h1 {
        font-size: 4rem;
        font-weight: lighter;
        color: #676586;
      }
      p {
        font-size: 3.4rem;
        color: #acaac2;
      }
    }

    .container-text-1 {
      margin-left: 7vw;
      margin-top: 40vh;
    }
    .container-text-2 {
      align-self: flex-end;
      margin-right: 7vw;
      text-align: right;
    }
    .container-text-3 {
      margin-left: 7vw;
    }
    .container-text-4 {
      align-self: flex-end;
      margin-right: 7vw;
      text-align: right;
    }
    .container-text-5 {
      margin-left: 7vw;
    }
  }

  .contact {
    height: 100vh;
    min-height: 500px;
    background: url("${(props) => props.background}") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    display: flex;
    align-items: center;

    .contact-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 22.8vw;
      margin-left: 6vw;
      h1#title {
        font-size: 6rem;
        font-weight: lighter;
        color: ${({theme}) => theme.color.white.main};
        margin-bottom: 80px;
        text-shadow: 0px 3px 12px rgba(0, 0, 0, 1);
        opacity: 0;
      }
      .button {
        width: 14.6vw;
        min-width: 110px;
        height: 2.9vw;
        min-height: 35px;
        box-shadow: 3px 3px 6px #0000003d;
        font-size: 1.4rem;
        opacity: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .StickyText {
      display: block;
    }
    .texts {
      display: none;
    }
    .contact {
      background: url("${(props) => props.bakgroundMobile}") no-repeat;
      background-size: cover;
      background-attachment: fixed;
      align-items: flex-start;
      justify-content: center;
      margin-top: 500vh;

      .contact-container {
        width: 80vw;
        z-index: 3;
        margin-left: 0;
        margin-top: 10vw;
        h1#title {
          margin-bottom: 24px;
        }
      }
    }
  }
`;
