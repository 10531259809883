import React from "react";
import Plx from "react-plx";
import { Fade } from "react-reveal";

import { Container } from "./styles";

import Header from "../../../components/Header";
import MyBottomNavigation from "../../../components/MyBottomNavigation";
import MountAnimation from "../../../components/MountAnimation";

import apresentationImg from "../../../assets/lp-servicos-apresentation-img.png";
import apresentationImg2Mobile from "../../../assets/lp-servicos-apresentation-img-mobile.png";

import webImg from "../../../assets/lp-servicos-web-img.png";
import embarcadoImg from "../../../assets/lp-servicos-embarcado-img.png";

import { toTopHide } from "../../../utils/parallaxDatas";

const Servicos = () => {
  return (
    <>
      <Header />
      <Container>
        <MountAnimation title="Serviços" backgroundColor="#16141a" />
        <div className="apresentation-container">
          <img id="to-web" src={apresentationImg} alt="imagem-tecnologia" />
          <img
            id="to-mobile"
            src={apresentationImg2Mobile}
            alt="imagem-tecnologia"
          />
          <Plx className="texts-container" parallaxData={toTopHide}>
            <h1>
              Expanda seu negócio, alcance novos públicos, gere visibilidade,
              valor, credibilidade e confiança.
            </h1>
          </Plx>
        </div>

        <div className="mobile-container">
          <div className="box-bg" />
          <iframe
            id="mobile-prototype"
            title="mobile"
            src="https://xd.adobe.com/embed/c0bb5a4d-4dcd-4bf9-475b-92a638870aec-b68a/?hints=off"
            frameborder="0"
            allowfullscreen
          ></iframe>
          <Fade right>
            <p>Desenvolvemos seu produto para que caiba na palma das mãos</p>
          </Fade>
        </div>

        <div className="web-container">
          <svg
            id="bg-wave"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#5e2075"
              fillOpacity="1"
              d="M0,96L60,80C120,64,240,32,360,21.3C480,11,600,21,720,58.7C840,96,960,160,1080,165.3C1200,171,1320,117,1380,90.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
          <Fade left>
            <h1>Disponibilidade para todas as plataformas</h1>
          </Fade>

          <img src={webImg} alt="imagem-web" />
        </div>

        <div className="embarcado-container">
          <img src={embarcadoImg} alt="Embarcados" />
          <Fade right>
            <div className="texts-container">
              <p>Inteligência e conectividade em suas soluções</p>
              <h2>
                Tornamos seu produto ou seu processo mais inteligente inserindo-o no mundo da
                Internet da Coisas e da Indústria 4.0, automatizando tomadas de decisão.
              </h2>
            </div>
          </Fade>
        </div>
        <MyBottomNavigation pageIndex="servicos" />
      </Container>
    </>
  );
};

export default Servicos;
