import {userPenalizationsConstants as cc} from "@/store/types/userPenalizations";

const INITIAL_STATE = {
  allPenalizations: [],
  isLoading: false,
  error: false,
};

export default function userPenalizations(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cc.LOAD_ALL_USERS_PENALIZATIONS:
      return {...state, isLoading: true, error: false};
    case cc.LOAD_ALL_USERS_PENALIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allPenalizations: action.penalizations,
      };
    case cc.LOAD_ALL_USERS_PENALIZATIONS_FAILURE:
      return {...state, isLoading: false, error: true};
    default:
      return state;
  }
}
