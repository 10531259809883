import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { ThemeProvider as ThemeProviderMaterial } from "@material-ui/core";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";

import Routes from "./routes";
import store from "./store";

import "./styles/GlobalStyles.css";

import { themeMaterial, themeStyled } from "./styles/themes";

ReactDOM.render(
  <ThemeProviderStyled theme={themeStyled}>
    <ThemeProviderMaterial theme={themeMaterial}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </ThemeProviderMaterial>
  </ThemeProviderStyled>,
  document.getElementById("root"),
);
