import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .perfil-content {
    flex: 1;
    display: flex;
    flex-direction: row;

    .nav-bar-mobile {
      display: none;
    }

    .perfil-avatar-mobile {
      display: none;
    }
  }

  .perfil-container-items {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .nav-bar-web {
      box-shadow: 0px 3px 3px #0000004d;
    }

    .perfil-container {
      flex: 1;
      display: flex;
      justify-content: center;

      .perfil-form {
        flex: 1;
        max-width: 1500px;
        display: flex;
        padding: 0 1%;
        flex-wrap: wrap;
        flex-direction: row;

        .perfil-card {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 30%;
          max-width: 537px;
          margin: 3% auto;
          padding: 10px 20px 15px 20px;

          .perfil-avatar {
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            .perfil-avatar-img {
              width: 130px;
              height: 130px;
              border-radius: 100%;
            }

            .perfil-avatar-img-small {
              padding: 2px;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              background-color: ${({theme}) => theme.color.primary.main};
            }
          }

          .perfil-card-btn-update {
            margin-top: 3%;
            display: flex;
            justify-self: flex-start;
            align-self: flex-end;
            position: absolute;
          }

          .perfil-title {
            width: 100%;
            font-weight: 500;
            font-size: 1.4rem;
            padding: 10px 20%;
            text-align: center;
            margin: 12px 0;
          }

          .perfil-card-title {
            font-size: 1rem;
            align-self: flex-start;
            margin-left: 8%;
            margin-bottom: 10px;
            color: #fb8c00;
          }

          .perfil-input {
            width: 85%;
            font-weight: 16px;
            margin-bottom: 4px;
          }

          .input-telephone {
            margin-right: 20px;
          }

          .input-numero {
            margin-right: 20%;
            max-width: 100px;
          }

          .input-bairro {
            margin-right: 40px;
          }

          .perfil-card-row {
            width: 85%;
            display: flex;
          }
        }
      }

      .penalization-content {
        flex: 1;
        padding: 50px 100px;

        .penalization-table-mobile {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .toolbar {
      justify-content: space-between;

      .logo {
        display: block;
      }

      .toolbar-menu {
        display: block;
      }

      .toolbar-avatar {
        display: none;
      }
    }

    .perfil-content {
      flex-direction: column;

      .nav-bar-mobile {
        display: block;
        background-color: #fff;
        margin-bottom: 7px;
        box-shadow: 0 1px 3px #0000004d;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .perfil-avatar-mobile {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: ${({theme}) => theme.color.primary.main};

        .perfil-avatar-mobile-img {
          width: 100px;
          height: 100px;
        }
      }

      .perfil-avatar-mobile h2 {
        margin: 15px 0;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .perfil-container-items {
      .nav-bar-web {
        display: none;
      }

      .perfil-container {
        .perfil-form {
          flex-direction: column;
          padding: 0;
          width: 100%;
          align-items: center;

          .perfil-card {
            flex: 1;
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: none;
            border-radius: none;

            .perfil-card-btn-update {
              padding-right: 20px;
            }

            .perfil-title {
              display: none;
            }

            .perfil-card-title {
              margin-bottom: 20px;
              margin-top: 20px;
            }

            .perfil-input-mobile-end {
              margin-bottom: 30px;
            }

            .perfil-input {
              padding-left: 10px;
            }

            .perfil-avatar {
              display: none;
            }
          }

          .perfil-card-mobile {
            border-bottom: 1px solid #707070;
          }
        }

        .penalization-content {
          padding: 0;
          width: 100%;
          padding: 0 15px;

          .penalization-table-web {
            display: none;
          }

          .penalization-table-mobile {
            display: block;

            .penalization-table-mobile-row {
              width: 100%;
              height: 48px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .penalization-table-mobile-expanded {
              min-height: 70px;
              display: flex;
              flex-direction: column;

              .penalization-table-mobile-expanded-btn {
                margin-top: 10px;
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
`;
