import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  InputAdornment,
  IconButton
} from "@material-ui/core";

import {
  ArrowBackRounded,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";

import { Container } from "./styles";
import logo from "../../../../../assets/loginLogo.svg";
import background from "../../../../../assets/backgroundInterno.png";

import { allowAccessRegister } from "../../../../../store/actions/register.js";

const RotaSenha = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);

  const handleSubmit = () => {
    allowAccessRegister(dispatch);
    history.push("/membros/cadastro/sucesso");
  };

  const handleCancel = () => {
    history.push("/membros");
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <div className="back-container">
          <div className="back" onClick={handleBack}>
            <ArrowBackRounded style={{ color: "#676586" }} />
            <p>Voltar</p>
          </div>
        </div>
        <FormControl component="form" onSubmit={handleSubmit}>
          <TextField
            className="input"
            type={showPassword ? "text" : "password"}
            label="Senha"
            variant="outlined"
            required
            onChange={({ target }) => setPassword(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={event => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            className="input"
            type={showConfirmedPassword ? "text" : "password"}
            label="Confirmar senha"
            variant="outlined"
            required
            error={password === confirmedPassword ? false : true}
            helperText={
              password === confirmedPassword ? false : "Senhas não correspondem"
            }
            onChange={({ target }) => setConfirmedPassword(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowConfirmedPassword(!showConfirmedPassword)
                    }
                    onMouseDown={event => event.preventDefault()}
                    edge="end"
                  >
                    {showConfirmedPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button
            className="main"
            type={password === confirmedPassword ? "submit" : "reset"}
            variant="contained"
            style={{ background: "#FB8C00" }}
          >
            PRÓXIMO
          </Button>
          <Button
            className="main"
            variant="contained"
            style={{ background: "#FFBD45" }}
            onClick={handleCancel}
          >
            CANCELAR
          </Button>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default RotaSenha;
