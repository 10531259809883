import React from "react";
import {Snackbar, Typography, makeStyles} from "@material-ui/core";

const styles = makeStyles(() => ({
  success: {
    backgroundColor: "#1D8000D9",
    color: "#fff",
    padding: "15px 15px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
  },
  error: {
    backgroundColor: "#e74c3c",
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
  },
}));

const Feedback = (props) => {
  const classes = styles();
  const {open, close, type, children, message} = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={close}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Typography
        variant="body2"
        align="center"
        className={classes[type] || classes.success}
      >
        {children}
        {message}
      </Typography>
    </Snackbar>
  );
};

export default Feedback;
