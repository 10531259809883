import {takeLatest, call, put} from "redux-saga/effects";
import api from "@/services/api";
import {userPenalizationsConstants as cc} from "@/store/types/userPenalizations";

function* loadAllPenalizations() {
  try {
    const {data} = yield call(api.get, "/penalizations");
    yield put({
      type: cc.LOAD_ALL_USERS_PENALIZATIONS_SUCCESS,
      penalizations: data.data,
    });
  } catch (e) {
    yield put({type: cc.LOAD_ALL_USERS_PENALIZATIONS_FAILURE});
  }
}

export default function* watcherSaga() {
  yield takeLatest(cc.LOAD_ALL_USERS_PENALIZATIONS, loadAllPenalizations);
}
