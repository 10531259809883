export const adminConstants = {
  LOAD_ALL_USERS_SOLICITATIONS: "LOAD_ALL_USERS_SOLICITATIONS",
  LOAD_ALL_USERS_SOLICITATIONS_SUCCESS: "LOAD_ALL_USERS_SOLICITATIONS_SUCCESS",
  LOAD_ALL_USERS_SOLICITATIONS_FAILURE: "LOAD_ALL_USERS_SOLICITATIONS_FAILURE",
  LOAD_CURRENT_PERIOD: "LOAD_CURRENT_PERIOD",
  LOAD_CURRENT_PERIOD_SUCCESS: "LOAD_CURRENT_PERIOD_SUCCESS",
  LOAD_CURRENT_PERIOD_FAILURE: "LOAD_CURRENT_PERIOD_FAILURE",
  UPDATE_CURRENT_PERIOD: "UPDATE_CURRENT_PERIOD",
  UPDATE_CURRENT_PERIOD_SUCCESS: "UPDATE_CURRENT_PERIOD_SUCCESS",
  UPDATE_CURRENT_PERIOD_FAILURE: "UPDATE_CURRENT_PERIOD_FAILURE",
  LOAD_ALL_REGISTRED_PENALTIES: "LOAD_ALL_REGISTRED_PENALTIES",
  LOAD_ALL_REGISTRED_PENALTIES_SUCCESS: "LOAD_ALL_REGISTRED_PENALTIES_SUCCESS",
  LOAD_ALL_REGISTRED_PENALTIES_FAILURE: "LOAD_ALL_REGISTRED_PENALTIES_FAILURE",
  LOAD_ALL_MEMBERS: "LOAD_ALL_MEMBERS",
  LOAD_ALL_MEMBERS_SUCCESS: "LOAD_ALL_MEMBERS_SUCCESS",
  LOAD_ALL_MEMBERS_FAILURE: "LOAD_ALL_MEMBERS_FAILURE",
  APPLY_PENALTY: "APPLY_PENALTY",
  APPLY_PENALTY_SUCCESS: "APPLY_PENALTY_SUCCESS",
  APPLY_PENALTY_FAILURE: "APPLY_PENALTY_FAILURE",
};
