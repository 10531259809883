import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background: url('${(props) => props.background}') no-repeat ;
  background-size: cover;
  display: flex;
  justify-content: flex-end;

  .container-register {
    min-height: 100vh;
    width: 40%;
    background: ${({ theme }) => theme.color.white.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0px 0px 10px;

    .progress-bar{
      width: 400px;
      height: 8px;
      border-radius: 4px;
      background: ${({ theme }) => theme.color.gray.light};
      margin-bottom: 7.6%;
      .bar {
        height: 100%;
        width: 2%;
        border-radius: 4px;
        background: ${({ theme }) => theme.color.primary.light};
      }
    }

    .input {
      width: 280px;
      margin-bottom: 16px;
    }

    .input-registration {
      display: flex;
      flex-direction: column;
      width: 280px;
      margin-bottom: 16px;
    }

    p#counter {
      align-self: flex-end;
      font-size: 10px;
      color: ${({ theme }) => theme.color.gray.main};
      margin-top: -32px;
      margin-right: 8px;
    }

    button {
      width: 280px;
      height: 56px;
      background: ${({ theme }) => theme.color.secondary.main};
      box-shadow: 3px 3px 6px #0000003D;
      font-size: 14px;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 8px; 
    }

  }

  @media screen and (max-width: 1000px){
    .container-register {
      width: 100%;
      border-radius: 0;
    }
  }
`;

export const Button = styled.button`
  width: 280px;
  height: 56px;
  border: none;
  border-radius: 4px;
  box-shadow: 3px 3px 6px #0000003d;
  background-color: ${(props) => props.bgColor};
  font-size: 14px;
  color: #fff;
  margin-bottom: 16px;
`;
