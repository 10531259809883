import {createMuiTheme, makeStyles} from "@material-ui/core";

export const themeMaterial = createMuiTheme({
  palette: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#Fb8c00",
      dark: "#C25E00",
      light: "#FFBD45",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTabs: {
      indicator: {
        width: ".25rem",
        borderRadius: 4,
        height: "80%",
        "@media (min-width: 1000px)": {
          left: 0,
        },
      },
    },
    MuiTab: {
      root: {
        width: "100px",
        height: "10vh",
        "& svg, .material-icons": {
          fontSize: 40,
          color: "#fff",
        },
        "@media (min-width: 600px)": {
          minWidth: "100px",
        },
        "@media (max-width: 1000px)": {
          width: "150px",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#5e2075",
        border: "1px solid #5e2075",
        width: "140px",
        height: "30px",
      },
      label: {
        fontSize: ".7rem",
        "&:hover": {
          color: "#fff",
        },
        "@media (max-width: 1000px)": {
          color: "#fff",
          textTransform: "capitalize",
        },
      },
      colorInherit: {
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#5e2075",
        },
        "@media (max-width: 1000px)": {
          backgroundColor: "#5e2075",
          border: "none",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        width: "19px",
        height: "19px",
        opacity: "80%",
      },
    },
    MuiDrawer: {
      root: {
        "@media (max-width: 1000px)": {
          width: "200px",
        },
      },
      paper: {
        backgroundColor: "#5e2075",
        display: "flex",
        padding: "5% 0",
        color: "#fff",
      },
    },
  },
});

export const useStyles = makeStyles({
  link: {
    backgroundColor: "#5e2075",
    display: "flex",
    padding: "10px 0",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      padding: "0 20px",
      marginBottom: 15,
    },
  },
  linkSelected: {
    backgroundColor: "#300049",
    display: "flex",
    padding: "10px 0",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    "@media (max-width: 1000px)": {
      flexDirection: "column",
      padding: "0 20px",
      marginBottom: 15,
    },
  },
  linkIcon: {
    color: "#fff",
    fontSize: 40,
  },
  navTitle: {
    width: "100%",
    fontWeight: "bold",
    "@media (max-width: 1000px)": {
      display: "none",
    },
  },
  tabGeneral: {
    minHeight: "45px",
    fontSize: "15px",
    textAlign: "center",
    textTransform: "capitalize",
    "& svg, .material-icons": {
      fontSize: 20,
    },
  },
  tabWeb: {
    backgroundColor: "#5e2075",
    height: "100vh",
    flex: 1,
    position: "fixed",
    zIndex: 1,
    "@media (max-width: 1000px)": {
      position: "initial",
    },
  },
  drawerPaper: {
    width: "100px",
    marginTop: "3vh",
  },
  btnLogout: {
    background: "#300049",
    border: "1px solid #5e2075",
    color: "#fff",
    cursor: "pointer",
    "@media (max-width: 1000px)": {
      marginTop: "100px",
      background: "#300049",
      color: "#333",
      width: "100%",
    },
  },
});
