import React from "react";
import { Link } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import {
  GroupOutlined,
  WorkOutlineOutlined,
  SettingsApplicationsOutlined,
} from "@material-ui/icons";

import useStyles from "./styles";

const MyBottomNavigation = ({ pageIndex }) => {
  const classes = useStyles();

  return (
    <BottomNavigation
      className={classes.container}
      showLabels
      value={pageIndex}
    >
      <BottomNavigationAction
        value="sobre"
        component={Link}
        label="Sobre nós"
        icon={<GroupOutlined />}
        to="/sobre"
      />
      <BottomNavigationAction
        value="servicos"
        component={Link}
        label="Serviços"
        icon={<SettingsApplicationsOutlined />}
        to="/servicos"
      />
      <BottomNavigationAction
        value="portfolio"
        component={Link}
        label="Portfólio"
        icon={<WorkOutlineOutlined />}
        to="/portfolio"
      />
    </BottomNavigation>
  );
};

export default MyBottomNavigation;
