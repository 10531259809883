import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

//Custom functions

import {formatDate} from "@/services/formatDate";

//Redux actions

import {loadAllUsersSolicitations} from "@/store/actions/admin";

//Material Components

import {
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Modal,
  Backdrop,
  Fade,
  Checkbox,
  Typography,
  Hidden,
} from "@material-ui/core";

//Material Icons

import {ExpandMore, Close, GetApp, ArrowBack} from "@material-ui/icons";

//Custom components

import api from "@/services/api";

const Solicitations = (props) => {
  const dispatch = useDispatch();
  const {classes, setValue, admOptionSelected, setAdmOptionSelected} = props;

  const {allSolicitations} = useSelector(({admin}) => admin);

  //Functionality
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [
    openModalReviewSolicitation,
    setOpenModalReviewSolicitation,
  ] = useState(false);
  const [checkRefund, setCheckRefund] = useState(false); //Checkboxes mobile de filtros das solicitações
  const [checkShutdown, setCheckShutdown] = useState(false);
  const [checkWithdrawal, setCheckWithdrawal] = useState(false);
  const [currentSolicitationId, setCurrentSolicitationId] = useState(""); //Guardar o id da solicitação clicada
  const [currentDescription, setCurrentDescription] = useState(null);
  const [justification, setJustification] = useState("");

  //Methods

  useEffect(() => {
    if (!allSolicitations.length) {
      dispatch(loadAllUsersSolicitations());
    }
  }, []);

  const handleOpenModalDetails = (currentSol) => {
    setCurrentSolicitationId(currentSol.id);
    setCurrentDescription(currentSol.description);
    setOpenModalDetails(true);
  };

  const handleCloseModalDetails = () => {
    setOpenModalDetails(false);
    setCurrentSolicitationId("");
  };

  const handleOpenModalReviewSolicitation = (currentId) => {
    setCurrentSolicitationId(currentId);
    setOpenModalReviewSolicitation(true);
  };

  const handleCloseModalReviewSolicitation = () => {
    setOpenModalReviewSolicitation(false);
    setCurrentSolicitationId("");
  };

  const handleCheckRefund = (e) => {
    setCheckRefund(e.target.checked);
  };

  const handleCheckShutdown = (e) => {
    setCheckShutdown(e.target.checked);
  };

  const handleCheckWithdrawal = (e) => {
    setCheckWithdrawal(e.target.checked);
  };

  const handleInputChange = (e) => {
    const {value} = e.target;

    setJustification(value);
  };

  const handleClickRefAdmSolicitations = (index) => {
    setAdmOptionSelected(!admOptionSelected);
    setValue(index);
  };

  const selectSolicitationType = (solicitationId) => {
    switch (solicitationId) {
      case 1:
        return "Desligamento";
      case 2:
        return "Afastamento";
      case 3:
        return "Reembolso";
    }
  };

  const selectSolicitationStatus = (solicitationStatus) => {
    switch (solicitationStatus) {
      case "Aguardando": {
        return {class: "buttonPending", text: "Pendente"};
      }
      case "Deferido": {
        return {class: "buttonAccept", text: "Aprovado"};
      }
      case "Indeferido": {
        return {class: "buttonDenied", text: "Negado"};
      }
      case "Cancelado": {
        return {class: "buttonCancel", text: "Cancelado"};
      }
    }
  };

  const getSolicitationById = () => {
    return allSolicitations.filter(
      (solicitation) => solicitation.id === currentSolicitationId
    );
  };

  const handleReviewSolicitation = () => {
    const data = {
      is_approved: false,
      event_id: currentSolicitationId,
      description: justification,
    };

    api.post("/event-reviews", data);

    handleCloseModalReviewSolicitation();
  };

  const handleDownloadSolicitationPdf = () => {
    const current = getSolicitationById()[0];
    api({
      url: current.event_picture_url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Solicitacao.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="history-content">
      <Hidden mdDown>
        <TableContainer>
          {allSolicitations.length ? (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tablePenalizationHead}>
                    Data
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Membro
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Recurso
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Informações
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Opções
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allSolicitations.map((solicitation) => {
                  return (
                    <TableRow key={solicitation.id}>
                      <TableCell
                        className={classes.tablePenalizationField}
                        component="th"
                        scope="row"
                      >
                        {solicitation && formatDate(solicitation.created_at)}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        {selectSolicitationType(solicitation.type_id)}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={
                            classes[
                              `${
                                selectSolicitationStatus(solicitation.status)[
                                  "class"
                                ]
                              }`
                            ]
                          }
                          size="small"
                          variant="contained"
                        >
                          {
                            selectSolicitationStatus(solicitation.status)[
                              "text"
                            ]
                          }
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={classes.buttonDetails}
                          size="small"
                          variant="contained"
                          onClick={(e) => handleOpenModalDetails(solicitation)}
                        >
                          Detalhes
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          className={classes.buttonCancel}
                          size="small"
                          color="default"
                          variant="contained"
                          onClick={(e) =>
                            handleOpenModalReviewSolicitation(solicitation.id)
                          }
                        >
                          Avaliar
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="h6" align="center" color="primary">
              Nenhuma solicitação encontrada
            </Typography>
          )}
        </TableContainer>
      </Hidden>
      <div className="history-table-mobile">
        <Hidden mdUp>
          <Typography align="left" variant="body1" style={{margin: "20px 0"}}>
            <ArrowBack
              style={{fontSize: 30}}
              onClick={() => handleClickRefAdmSolicitations(0)}
            />
          </Typography>
        </Hidden>
        <div className="history-table-mobile-row-nopadding">
          <p className={classes.tablePenalizationCheckText}>
            <Checkbox
              inputProps={{"aria-label": "primary checkbox"}}
              color="default"
              size="small"
              checked={checkRefund}
              onChange={handleCheckRefund}
            />
            Reembolsos
          </p>
          <p className={classes.tablePenalizationCheckText}>
            <Checkbox
              inputProps={{"aria-label": "primary checkbox"}}
              color="default"
              size="small"
              checked={checkShutdown}
              onChange={handleCheckShutdown}
            />
            Afastamentos
          </p>
          <p className={classes.tablePenalizationCheckText}>
            <Checkbox
              inputProps={{"aria-label": "primary checkbox"}}
              color="default"
              size="small"
              checked={checkWithdrawal}
              onChange={handleCheckWithdrawal}
            />
            Desligamentos
          </p>
        </div>
        <div className="history-table-mobile-row">
          <p className={classes.tablePenalizationHeadMobile}>Data</p>
          <p className={classes.tablePenalizationHeadMobile}>Membro</p>
          <p className={classes.tablePenalizationHeadMobile}>Status</p>
        </div>
        {allSolicitations.map((solicitation) => {
          return (
            <ExpansionPanel
              elevation={0}
              className={classes.expandedGeneral}
              key={solicitation.id}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="history-table-mobile-row">
                  <p className={classes.tablePenalizationFieldMobile}>
                    {solicitation.created_at}
                  </p>
                  <p className={classes.tablePenalizationFieldMobile}>
                    {selectSolicitationType(solicitation.type_id)}
                  </p>
                  <Button
                    className={
                      classes[
                        `${
                          selectSolicitationStatus(solicitation.status)["class"]
                        }`
                      ]
                    }
                    size="small"
                    variant="contained"
                  >
                    {selectSolicitationStatus(solicitation.status)["text"]}
                  </Button>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="history-table-mobile-expanded">
                  <p className={classes.tablePenalizationFieldMobile}>
                    {"Opções de Solicitações"}
                  </p>
                  <div className="history-table-mobile-expanded-btn">
                    <Button
                      className={classes.buttonDetails}
                      size="small"
                      variant="contained"
                      onClick={(e) => handleOpenModalDetails(solicitation)}
                    >
                      Detalhes
                    </Button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>

      <Modal
        aria-labelledby="modal-details-title"
        aria-describedby="modal-details-description"
        className={classes.modal}
        open={openModalDetails}
        onClose={handleCloseModalDetails}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalDetails} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-details-title" className={classes.modalTitle}>
              Detalhes da Solicitação
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalDetails}
              />
            </h2>
            <FormControl name={3} component="form">
              <div className={classes.modalContent}>
                <p className={classes.modalContentSubtitle}>Descrição</p>
                <p className={classes.modalContentSubtitleMin}>
                  {currentDescription}
                </p>
                <p className={classes.modalContentSubtitle}>Documento</p>
                <p
                  className={classes.modalContentSubtitleMin}
                  onClick={handleDownloadSolicitationPdf}
                >
                  Documento.pdf <GetApp size="small" />
                </p>
              </div>
              <Button
                type="button"
                className={classes.buttonSubmitModal}
                onClick={handleCloseModalDetails}
                color="secondary"
              >
                CONFIRMAR
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-cancelSolicitation-title"
        aria-describedby="modal-cancelSolicitation-description"
        className={classes.modal}
        open={openModalReviewSolicitation}
        onClose={handleCloseModalReviewSolicitation}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalReviewSolicitation} elevation={1}>
          <div className={classes.modalContainer}>
            <h2
              id="modal-cancelSolicitation-title"
              className={classes.modalTitle}
            >
              Cancelamento de Pedido
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalReviewSolicitation}
              />
            </h2>
            <FormControl
              name={3}
              component="form"
              onSubmit={handleReviewSolicitation}
            >
              <div className={classes.modalContent}>
                <p>
                  Ao clicar em 'CONFIRMAR' você <br /> estará aprovando a
                  solicitação deste membro. <br />
                  <br /> Sua ação não poderá ser desfeita.
                </p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleInputChange}
                />
              </div>
              <Button
                type="button"
                className={classes.buttonSubmitModalGreen}
                onClick={handleReviewSolicitation}
                color="secondary"
              >
                CONFIRMAR
              </Button>
              <Button
                type="button"
                className={classes.buttonCancelModalRed}
                onClick={handleReviewSolicitation}
                color="secondary"
              >
                Reprovar
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Solicitations;
