import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .membros-content {
    flex: 1;
    display: flex;
    flex-direction: row;
  }
  .membros-container-items {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
  }
  @media screen and (max-width: 1000px) {
    .membros-content {
      flex-direction: column;
    }
    .membros-container-items {
      padding: 0 10px;

      .membros-content {
        width: 100%;
        padding: 0;
        .membros-table-web {
          width: 100%;
        }
        .membros-table-mobile {
          display: block;
          .membros-table-mobile-row {
            width: 100%;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .membros-table-mobile-expanded {
            min-height: 70px;
            display: flex;
            flex-direction: column;
            .membros-table-mobile-expanded-btn {
              margin-top: 10px;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }
`;
