import React from "react";
import {isAuthenticated} from "../services/auth";
import {Route, Redirect} from "react-router-dom";

export function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{pathname: "/login", state: {from: props.location}}} />
        )
      }
    />
  );
}

export function PrivateRouteRegister({children, allowAccessRegister, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) =>
        allowAccessRegister ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/membros/cadastro",
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
