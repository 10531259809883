import React from "react";
import {useSelector} from "react-redux";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider as ThemeProviderMaterial} from "@material-ui/core";

import GlobalStylesLandingPage from "../styles/GlobalStylesLandingPage";
import {MuiOverridesLandingpage} from "../styles/themes";

import {PrivateRouteRegister} from "./privateRoutes.js";
import {PrivateRoute} from "./privateRoutes.js";

import Home from "../pages/publicas/Home";
import Contato from "../pages/publicas/Contato";
import Sobre from "../pages/publicas/Sobre";
import Portfolio from "../pages/publicas/Portfolio";
import Servicos from "../pages/publicas/Servicos";

import Login from "../pages/internas/Login";
import RecuperarSenha from "../pages/internas/RecuperaSenha";
import Cadastro from "../pages/internas/Cadastro";
import RotaInfos from "../pages/internas/Cadastro/subRotas/RotaInfos";
import RotaEndereco from "../pages/internas/Cadastro/subRotas/RotaEndereco";
import RotaSenha from "../pages/internas/Cadastro/subRotas/RotaSenha";
import Sucesso from "../pages/internas/Cadastro/subRotas/Sucesso";
import Erro from "../pages/internas/Cadastro/subRotas/Erro";
import Dashboard from "../pages/internas/Dashboard";

const Routes = () => {
  const {allowAccessRegister} = useSelector(({register}) => register);

  return (
    <Router>
      <Switch>
        {/* Publicas */}

        <Route exact path="/">
          <ThemeProviderMaterial theme={MuiOverridesLandingpage}>
            <Home />
            <GlobalStylesLandingPage />
          </ThemeProviderMaterial>
        </Route>
        <Route path="/contato">
          <ThemeProviderMaterial theme={MuiOverridesLandingpage}>
            <Contato />
            <GlobalStylesLandingPage />
          </ThemeProviderMaterial>
        </Route>
        <Route path="/sobre">
          <ThemeProviderMaterial theme={MuiOverridesLandingpage}>
            <Sobre />
            <GlobalStylesLandingPage />
          </ThemeProviderMaterial>
        </Route>
        <Route path="/portfolio">
          <ThemeProviderMaterial theme={MuiOverridesLandingpage}>
            <Portfolio />
            <GlobalStylesLandingPage />
          </ThemeProviderMaterial>
        </Route>
        <Route path="/servicos">
          <ThemeProviderMaterial theme={MuiOverridesLandingpage}>
            <Servicos />
            <GlobalStylesLandingPage />
          </ThemeProviderMaterial>
        </Route>

        {/* Internas */}

        <Route exact path="/login">
          <Login />
        </Route>

        <Route exact path="/membros/recuperar-senha">
          <RecuperarSenha />
        </Route>

        <Route exact path="/membros/cadastro">
          <Cadastro />
          {/* TODO: Proteger rota de cadastro (apenas diretores podem acessar) */}
        </Route>

        <PrivateRouteRegister
          path="/membros/cadastro/2"
          allowAccessRegister={allowAccessRegister}
        >
          <RotaInfos />
        </PrivateRouteRegister>

        <PrivateRouteRegister
          path="/membros/cadastro/3"
          allowAccessRegister={allowAccessRegister}
        >
          <RotaEndereco />
        </PrivateRouteRegister>

        <PrivateRouteRegister
          path="/membros/cadastro/4"
          allowAccessRegister={allowAccessRegister}
        >
          <RotaSenha />
        </PrivateRouteRegister>

        <PrivateRouteRegister
          path="/membros/cadastro/sucesso"
          allowAccessRegister={allowAccessRegister}
        >
          <Sucesso />
        </PrivateRouteRegister>

        <PrivateRouteRegister
          path="/membros/cadastro/erro"
          allowAccessRegister={allowAccessRegister}
        >
          <Erro />
        </PrivateRouteRegister>

        <PrivateRoute path="/dashboard" component={Dashboard} />
      </Switch>
    </Router>
  );
};

export default Routes;
