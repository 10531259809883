import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatDate, formatDateWithHour} from "@/services/formatDate";

import {loadUserPenalizations} from "@/store/actions/userProfile";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core";

import {ExpandMore} from "@material-ui/icons";

const Penalization = (props) => {
  const {classes} = props;
  const dispatch = useDispatch();
  const {penalizations} = useSelector(({userProfile}) => userProfile);

  useEffect(() => {
    dispatch(loadUserPenalizations());
  }, []);

  return (
    <div className="penalization-content">
      <div className="penalization-table-web">
        {penalizations?.length !== 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tablePenalizationHead}>
                    Data/Hora
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Advertência
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Recurso
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    PCD
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {penalizations?.map((penalization) => {
                  return (
                    <TableRow key={penalization.id}>
                      <TableCell
                        className={classes.tablePenalizationField}
                        component="th"
                        scope="row"
                      >
                        {penalization &&
                          formatDateWithHour(penalization.created_at)}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        {penalization["penalty"]?.title}
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        <Button
                          color="inherit"
                          size="small"
                          variant="contained"
                          className={classes.buttonDefault}
                        >
                          Solicitar Recurso
                        </Button>
                      </TableCell>
                      <TableCell
                        className={classes.tablePenalizationField}
                        align="center"
                      >
                        {penalization["penalty"]?.score + " pontos"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" align="center" color="primary">
            Você não possui nenhuma penalização
          </Typography>
        )}
      </div>

      <div className="penalization-table-mobile">
        {penalizations?.length !== 0 ? (
          <>
            <div className="penalization-table-mobile-row">
              <p className={classes.tablePenalizationHeadMobile}>Data</p>
              <p className={classes.tablePenalizationHeadMobile}>Advertência</p>
              <p className={classes.tablePenalizationHeadMobile}>Pontuação</p>
            </div>
            {penalizations?.map((penalization) => {
              return (
                <ExpansionPanel
                  elevation={0}
                  className={classes.expandedGeneral}
                  key={penalization?.id}
                >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="penalization-table-mobile-row">
                      <p className={classes.tablePenalizationFieldMobile}>
                        {penalization && formatDate(penalization.created_at)}
                      </p>
                      <p className={classes.tablePenalizationFieldMobile}>
                        {penalization["penalty"]?.title}
                      </p>
                      <p className={classes.tablePenalizationFieldMobile}>
                        {penalization["penalty"]?.score + " pontos"}
                      </p>
                    </div>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className="penalization-table-mobile-expanded">
                      <p className={classes.tablePenalizationFieldMobile}>
                        {penalization["penalty"] &&
                          formatDateWithHour(
                            penalization["penalty"].created_at
                          )}
                      </p>
                      <p className={classes.tablePenalizationFieldMobile}>
                        {"Descrição: " + penalization.justification}
                      </p>
                      <div className="penalization-table-mobile-expanded-btn">
                        <Button
                          color="inherit"
                          size="small"
                          variant="contained"
                        >
                          Solicitar Recurso
                        </Button>
                      </div>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              );
            })}
          </>
        ) : (
          <Typography
            style={{marginTop: "10%"}}
            variant="body1"
            align="center"
            color="primary"
          >
            Você não possui nenhuma penalização
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Penalization;
