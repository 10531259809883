import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowBackRounded } from "@material-ui/icons";
import { Paper, FormControl, TextField, Button } from "@material-ui/core";

import { Container } from "./styles";
import logo from "../../../../../assets/loginLogo.svg";
import background from "../../../../../assets/backgroundInterno.png";

import { allowAccessRegister } from "../../../../../store/actions/register.js";

const RotaEndereco = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [cep, setCep] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [complement, setComplement] = useState("");

  const [counterCep, setCounterCep] = useState(0);

  const handleSubmit = () => {
    allowAccessRegister(dispatch);
    history.push("/membros/cadastro/4");
  };

  const handleCancel = () => {
    history.push("/membros");
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <div className="back-container">
          <div className="back" onClick={handleBack}>
            <ArrowBackRounded style={{ color: "#676586" }} />
            <p>Voltar</p>
          </div>
        </div>
        <FormControl component="form" onSubmit={handleSubmit}>
          <TextField
            label="Rua"
            className="input"
            variant="outlined"
            required
            onChange={({ target }) => setStreet(target.value)}
          />

          <div className="inputs-num-cep">
            <TextField
              label="Número"
              className="input-num"
              variant="outlined"
              required
              onChange={({ target }) => setNumber(target.value)}
            />
            <div className="input-cep">
              <TextField
                label="CEP"
                variant="outlined"
                required
                inputProps={{ maxLength: 8, minLength: 8 }}
                error={(counterCep === 8) | (counterCep === 0) ? false : true}
                onChange={({ target }) => {
                  setCep(target.value);
                  setCounterCep(target.value.length);
                }}
              />
              <p id="counter">{counterCep}/8</p>
            </div>
          </div>

          <TextField
            label="Bairro"
            className="input"
            variant="outlined"
            required
            onChange={({ target }) => setNeighborhood(target.value)}
          />
          <TextField
            label="Cidade"
            className="input"
            variant="outlined"
            required
            onChange={({ target }) => setCity(target.value)}
          />
          <div className="input-complement">
            <TextField
              label="Complemento"
              className="input"
              variant="outlined"
              onChange={({ target }) => setComplement(target.value)}
            />
            <p>Opicional</p>
          </div>
          <Button
            type="submit"
            variant="contained"
            style={{ background: "#FB8C00" }}
          >
            PRÓXIMO
          </Button>
          <Button
            variant="contained"
            style={{ background: "#FFBD45" }}
            onClick={handleCancel}
          >
            CANCELAR
          </Button>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default RotaEndereco;
