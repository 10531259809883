import {createMuiTheme, makeStyles} from "@material-ui/core";

export const themeMaterial = createMuiTheme({
  palette: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#Fb8c00",
      dark: "#C25E00",
      light: "#FFBD45",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTab: {
      labelIcon: {
        fontSize: "25px",
        minHeight: "56px",
        textAlign: "center",
        textTransform: "capitalize",
      },
      wrapper: {
        flexDirection: "row",
        "& svg, .material-icons": {
          fontSize: 40,
          marginRight: 5,
        },
      },
      textColorPrimary: {
        color: "#5e2075",
      },
    },
    MuiTableCell: {
      root: {
        fontWeight: 500,
        fontSize: "1.3rem",
        borderBottom: "1px solid #707070",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#5e2075",
        border: "1px solid #5e2075",
        width: "140px",
        height: "30px",
      },
      label: {
        fontSize: ".7rem",
        "&:hover": {
          color: "#fff",
        },
        "@media (max-width: 1000px)": {
          color: "#fff",
          textTransform: "capitalize",
        },
      },
      colorInherit: {
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#5e2075",
        },
        "@media (max-width: 1000px)": {
          backgroundColor: "#5e2075",
          border: "none",
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        height: "48px",
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: "#E3E1ED",
      },
    },
    MuiInputAdornment: {
      root: {
        width: "19px",
        height: "19px",
        opacity: "80%",
      },
    },
    MuiAvatar: {
      img: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: "100%",
      },
    },
  },
});

export const useStyles = makeStyles({
  loader: {
    flex: 1,
  },
  tabGeneral: {
    minHeight: "45px",
    fontSize: "15px",
    textAlign: "center",
    textTransform: "capitalize",
    "& svg, .material-icons": {
      fontSize: 20,
    },
  },
  modalPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPasswordContainer: {
    width: "30%",
    maxWidth: "500px",
    padding: "1.5%",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    "@media (max-width: 1000px)": {
      width: "100%",
    },
  },
  modalPasswordTitle: {
    color: "#676586",
    fontSize: "1.3rem",
    fontWeight: 500,
    marginBottom: "2%",
    display: "flex",
    justifyContent: "space-between",
  },
  modalPasswordInput: {
    width: "80%",
    marginBottom: "5px",
    alignSelf: "center",
  },
  modalPasswordStatus: {
    margin: "20px 0",
    fontSize: "1rem",
    textAlign: "center",
  },
  tablePenalizationHead: {
    color: "#5e2075",
    fontSize: "1.3vw",
    "@media (max-width: 1000px)": {
      color: "#C25E00",
      fontSize: "16px",
    },
  },
  tablePenalizationField: {
    color: "#000000",
    fontSize: "18px",
  },
  tablePenalizationHeadMobile: {
    fontSize: "14px",
    color: "#C25E00",
    fontWeight: 400,
  },
  tablePenalizationFieldMobile: {
    fontSize: "12px",
    color: "#000000",
    fontWeight: 300,
  },
  expandedGeneral: {
    borderBottom: "1px solid #707070",
  },
  buttonDefault: {
    backgroundColor: "#5e2075",
    color: "#fff",
    border: "none",
  },
  buttonPending: {
    backgroundColor: "#F3E02E",
    color: "#040404",
    border: "none",
    "&:hover": {
      backgroundColor: "#F3E02E",
      color: "#040404",
    },
  },
  buttonDenied: {
    backgroundColor: "#EF2525AD",
    color: "#040404",
    border: "none",
    "&:hover": {
      backgroundColor: "#EF2525AD",
      color: "#fff",
    },
  },
  buttonAccept: {
    backgroundColor: "#3ADB1BB8",
    color: "#040404",
    border: "none",
    "&:hover": {
      backgroundColor: "#1D8000D9",
      color: "#fff",
    },
  },
  buttonSubmitModal: {
    backgroundColor: "#1D8000D9",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#1D8000D9",
    },
  },
  buttonCancelModal: {
    backgroundColor: "#F92635D9",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#F92635D9",
    },
  },
  buttonBackModal: {
    backgroundColor: "#FB8C00",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#FB8C00",
    },
  },
  buttonUpdateField: {
    fontSize: "1.8rem",
    cursor: "pointer",
  },
});
