import {all} from "redux-saga/effects";
import userProfile from "./userProfile";
import userSolicitations from "./userSolicitations";
import searchMembers from "./searchMembers";
import userPenalizations from "./userPenalizations";
import admin from "./admin";

export default function* rootSaga() {
  yield all([
    userProfile(),
    userSolicitations(),
    searchMembers(),
    userPenalizations(),
    admin(),
  ]);
}
