import React from "react";
import {
  AppBar,
  Toolbar,
  useScrollTrigger,
  Button,
  Slide,
} from "@material-ui/core/";
import { Link, NavLink } from "react-router-dom";

import { Container } from "./styles";
import logoBlack from "../../assets/headerLogoBlack.svg";

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  const trigger2 = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      elevation={trigger2 ? 4 : 0}
    >
      {children}
    </Slide>
  );
}

const Header = (props) => {
  return (
    <Container>
      <HideOnScroll {...props}>
        <AppBar position="fixed">
          <Toolbar className="toolbar">
            <Link className="logo" exact to="/">
              <img src={logoBlack} alt="EMAKERS JÚNIOR" />
            </Link>
            <div className="pages">
              <NavLink className="navlink" to="/sobre">
                Sobre nós
                <div className="hover-bar" />
              </NavLink>
              <NavLink className="navlink" to="/servicos">
                Serviços
                <div className="hover-bar" />
              </NavLink>
              <NavLink className="navlink" to="/portfolio">
                Portfólio
                <div className="hover-bar" />
              </NavLink>
              <Button
                component={Link}
                to="/contato"
                variant="contained"
                className="button-contact"
              >
                <div className="hover-background" />
                <span>Contato</span>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Container>
  );
};

export default Header;
