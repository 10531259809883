import React, {useState, useRef, useEffect} from "react";
import SwipeableViews from "react-swipeable-views";
import Plx from "react-plx";
import {autoPlay} from "react-swipeable-views-utils";
import {Link} from "react-router-dom";
import {Zoom, Fade} from "react-reveal";

import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";

import {
  Container,
  ContainerProduct1,
  ContainerProduct2,
  ProgressBar,
} from "./styles";

import Header from "../../../components/Header";
import MyBottomNavigation from "../../../components/MyBottomNavigation";
import MountAnimation from "../../../components/MountAnimation";

import apresentationBg from "../../../assets/lp-portfolio-apresentation-background.png";
import apresentationImg from "../../../assets/lp-portfolio-apresentation-img.svg";

import logoRedacao from "../../../assets/redacao-inteligente.svg";
import redacaoImg1 from "../../../assets/lp-portfolio-redacao-img1.gif";
import redacaoImg2 from "../../../assets/lp-portfolio-redacao-img2.gif";
import redacaoImg3 from "../../../assets/redacao-foto.png";

import epointBackground from "../../../assets/imagem-fim.jpg";
import epointBackgroundMobile from "../../../assets/lp-portfolio-epoint-mobile-bg.png";
import epointInside from "../../../assets/lp-portfolio-epoint-inside.jpg";
import epointOutside from "../../../assets/lp-portfolio-epoint-outside.jpg";
import {toTopHide} from "../../../utils/parallaxDatas";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const textosEpoint = {
  texto1: {
    titulo: "E-Point",
    descricao: "Sistema eletrônico para registros de pontos via RFID",
  },
  texto2: {
    titulo: "Administração",
    descricao:
      "Listagem dos pontos, contabilização de horas, notificações e muito mais",
  },
  texto3: {
    titulo: "Operação Online",
    descricao: "Pontos registrados e administrados em tempo real",
  },
  texto4: {
    titulo: "Operação Offline",
    descricao: "Pontos registrados mesmo com ausência de conexão",
  },
  texto5: {
    titulo: "Fácil Alimentação",
    descricao: "Basta conectá-lo via usb a um pc ou carregador adequado",
  },
};

const epointData = [
  {
    start: ".epoint-trigger",
    easing: "easeInOut",
    duration: "50vh",
    properties: [
      {
        startValue: 0,
        endValue: -50,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const textData = [
  {
    start: "self",
    duration: "30vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];
const contactData = [
  {
    start: ".contact",
    startOffset: "80vh",
    duration: "20vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];
const openEpointData = [
  {
    start: ".epoint-trigger",
    startOffset: "200vh",
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: 0,
        endValue: -50,
        unit: "%",
        property: "translateY",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "330vh",
    duration: "50vh",
    easing: "easeInOut",
    properties: [
      {
        startValue: -50,
        endValue: 0,
        unit: "%",
        property: "translateY",
      },
    ],
  },
];

const stickyTextData = [
  {
    start: ".epoint-trigger",
    startOffset: "20vh",
    duration: "30vh",
    properties: [
      {
        startValue: 0,
        endValue: -15,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "60vh",
    duration: "30vh",
    properties: [
      {
        startValue: -15,
        endValue: -30,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const stickyTextData2 = [
  {
    start: ".epoint-trigger",
    duration: "30vh",
    startOffset: "100vh",
    properties: [
      {
        startValue: 0,
        endValue: -15,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "160vh",
    duration: "30vh",
    properties: [
      {
        startValue: -15,
        endValue: -30,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const stickyTextData3 = [
  {
    start: ".epoint-trigger",
    duration: "30vh",
    startOffset: "200vh",
    properties: [
      {
        startValue: 0,
        endValue: -15,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "260vh",
    duration: "30vh",
    properties: [
      {
        startValue: -15,
        endValue: -30,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const stickyTextData4 = [
  {
    start: ".epoint-trigger",
    duration: "30vh",
    startOffset: "300vh",
    properties: [
      {
        startValue: 0,
        endValue: -15,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "360vh",
    duration: "30vh",
    properties: [
      {
        startValue: -15,
        endValue: -30,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const stickyTextData5 = [
  {
    start: ".epoint-trigger",
    duration: "30vh",
    startOffset: "400vh",
    properties: [
      {
        startValue: 0,
        endValue: -15,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".epoint-trigger",
    startOffset: "460vh",
    duration: "30vh",
    properties: [
      {
        startValue: -15,
        endValue: -30,
        unit: "vh",
        property: "translateY",
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

const Portfolio = () => {
  const [expanded, setExpanded] = useState(0);
  const [progress, setProgress] = useState(2);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    switch (expanded) {
      case 1:
        setProgress(50);
        break;
      case 2:
        setProgress(100);
        break;
      default:
        setProgress(2);
        break;
    }
  }, [expanded]);

  const triggerEl = useRef(null);

  return (
    <>
      <Header />
      <Container
        apresentationBg={apresentationBg}
        apresentationImg={apresentationImg}
      >
        <MountAnimation title="Portfólio" backgroundColor="#FB8C00" />
        <div className="apresentation">
          <Plx className="texts-container" parallaxData={toTopHide}>
            <h2>Conheça alguns de nossos cases</h2>
            <h3>Acompanhe os trabalhos realizados pela Emakers Jr.</h3>
          </Plx>
        </div>

        <ContainerProduct1>
          <div className="content">
            <img
              id="logo"
              src={logoRedacao}
              alt="REDAÇÃO INTELIGENTE"
            />
            <div className="panels">
              <ProgressBar progress={progress}>
                <div id="bar" />
              </ProgressBar>

              <ExpansionPanel
                className="expansion-panels"
                expanded={expanded === 0}
                onChange={() => {
                  setExpanded(0);
                }}
              >
                <ExpansionPanelSummary>
                  <p id="title">Correção inteligente</p>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <p id="description">
                    Correções ágeis e dinâmicas, visando a melhor experiência
                    para os alunos.
                  </p>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                className="expansion-panels"
                expanded={expanded === 1}
                onChange={() => {
                  setExpanded(1);
                }}
              >
                <ExpansionPanelSummary>
                  <p id="title">Aluno conectado</p>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <p id="description">
                    O aluno tira todas suas dúvidas e avalia o corretor,
                    possibilitando sua evolução constante.
                  </p>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                className="expansion-panels"
                expanded={expanded === 2}
                onChange={() => {
                  setExpanded(2);
                }}
              >
                <ExpansionPanelSummary>
                  <p id="title">Manutenção da plataforma</p>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <p id="description">
                    O administrador controla diversos recursos da plataforma,
                    como os planos, anúncios, vídeo informativo, relatórios
                    mensais etc.
                  </p>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://redacaointeligente.com.br/"
            >
              <Button color="primary" variant="contained">
                VISITAR
              </Button>
            </a>
          </div>

          <div className="product-1-imgs">
            <img id="logo" src={logoRedacao} alt="REDAÇÃO INTELIGENTE" />

            <AutoPlaySwipeableViews
              className="swipeable-container"
              interval={5000}
              onChangeIndex={(index) => setExpanded(index)}
              index={expanded}
              resistance
              enableMouseEvents
            >
              <img
                className="img1"
                src={redacaoImg1}
                alt="gif corrigindo prova"
              />
              <img
                className="img2"
                src={redacaoImg2}
                alt="gif comentando a correção de prova"
              />
               <img
                className="img3"
                src={redacaoImg3}
                alt="foto tela do redação"
              />
              <div
                className="img3"
              />
            </AutoPlaySwipeableViews>

            <div className="texts-container">
              <AutoPlaySwipeableViews
                className="swipeable-container-texts"
                interval={5000}
                onChangeIndex={(index) => setExpanded(index)}
                index={expanded}
                disabled
              >
                <>
                  <p className="title">Correção inteligente</p>
                  <p className="description">
                    Correções ágeis e dinâmicas, visando a melhor experiência
                    para os alunos.
                  </p>
                </>
                <>
                  <p className="title">Aluno conectado</p>
                  <p className="description">
                    O aluno tira todas suas dúvidas e avalia o corretor,
                    possibilitando sua evolução constante.
                  </p>
                </>
                <>
                  <p className="title">Manutenção da plataforma</p>
                  <p className="description">
                    O administrador controla diversos recursos da plataforma,
                    como os planos, anúncios, vídeo informativo, relatórios
                    mensais etc.
                  </p>
                </>
              </AutoPlaySwipeableViews>
            </div>
            <ProgressBar id="progress-bar" progress={progress}>
              <div id="bar" />
            </ProgressBar>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://redacaointeligente.com.br/"
            >
              <Button color="primary" variant="contained">
                VISITAR
              </Button>
            </a>
          </div>
        </ContainerProduct1>

        <Fade>
          <h2 id="depoimento-redacao">
            "Ter minha plataforma produzida pela Emakers foi um sonho realizado.
            Eu trabalho com correção de redações e fazia todo esse trabalho
            manual e, a partir do momento que resolvemos colocar todo esse fluxo
            dentro de um programa, a otimização do serviço e o atendimento
            melhoraram em 100%. Os meninos foram super atenciosos e superaram as
            minhas expectativas. Sou muito grata à Emakers por fazerem parte da
            minha vida e cooperarem para o meu sucesso."
            <p>- Cássia, Redação Inteligente</p>
          </h2>
        </Fade>

        <Fade bottom>
          <h1 id="divider-text">Sua solução conectada à internet!</h1>
        </Fade>

        <ContainerProduct2
          background={epointBackground}
          bakgroundMobile={epointBackgroundMobile}
        >
          <Plx className="epoint" parallaxData={epointData}>
            <img id="epoint-inside" src={epointInside} alt="epoint" />
            <Plx
              tagName="img"
              id="epoint-outside"
              src={epointOutside}
              alt="epoint"
              parallaxData={openEpointData}
            />
          </Plx>

          <div className="epoint-area">
            <div className="epoint-trigger" />
          </div>

          {/* Textos no MOBILE */}
          <Plx className="StickyText" parallaxData={stickyTextData}>
            <h2>{textosEpoint.texto1["titulo"]}</h2>
            <p>{textosEpoint.texto1["descricao"]}</p>
          </Plx>
          <Plx className="StickyText" parallaxData={stickyTextData2}>
            <h2>{textosEpoint.texto2["titulo"]}</h2>
            <p>{textosEpoint.texto2["descricao"]}</p>
          </Plx>
          <Plx className="StickyText" parallaxData={stickyTextData3}>
            <h2>{textosEpoint.texto3["titulo"]}</h2>
            <p>{textosEpoint.texto3["descricao"]}</p>
          </Plx>
          <Plx className="StickyText" parallaxData={stickyTextData4}>
            <h2>{textosEpoint.texto4["titulo"]}</h2>
            <p>{textosEpoint.texto4["descricao"]}</p>
          </Plx>
          <Plx className="StickyText" parallaxData={stickyTextData5}>
            <h2>{textosEpoint.texto5["titulo"]}</h2>
            <p>{textosEpoint.texto5["descricao"]}</p>
          </Plx>

          {/* Textos na WEB */}
          <div className="texts" ref={triggerEl}>
            <Plx
              className="container-text-1 container-text"
              parallaxData={textData}
            >
              <h1>{textosEpoint.texto1["titulo"]}</h1>
              <p>{textosEpoint.texto1["descricao"]}</p>
            </Plx>
            <Plx
              className="container-text-2 container-text"
              parallaxData={textData}
            >
              <h1>{textosEpoint.texto2["titulo"]}</h1>
              <p>{textosEpoint.texto2["descricao"]}</p>
            </Plx>
            <Plx
              className="container-text-3 container-text open-trigger"
              parallaxData={textData}
            >
              <h1>{textosEpoint.texto3["titulo"]}</h1>
              <p>{textosEpoint.texto3["descricao"]}</p>
            </Plx>
            <Plx
              className="container-text-4 container-text open-trigger"
              parallaxData={textData}
            >
              <h1>{textosEpoint.texto4["titulo"]}</h1>
              <p>{textosEpoint.texto4["descricao"]}</p>
            </Plx>
            <Plx
              className="container-text-5 container-text"
              parallaxData={textData}
            >
              <h1>{textosEpoint.texto5["titulo"]}</h1>
              <p>{textosEpoint.texto5["descricao"]}</p>
            </Plx>
          </div>

          <div className="contact">
            <div className="contact-container">
              <Plx tagName="h1" id="title" parallaxData={contactData}>
                Mude agora sua Empresa!
              </Plx>
              <Plx
                tagName={Button}
                component={Link}
                className="button"
                to="/contato"
                color="secondary"
                variant="contained"
                parallaxData={contactData}
              >
                FALE CONOSCO
              </Plx>
            </div>
          </div>
        </ContainerProduct2>

        <MyBottomNavigation pageIndex="portfolio" />
      </Container>
    </>
  );
};

export default Portfolio;
