export const userProfileConstants = {
  LOAD_USER_PROFILE: "LOAD_USER_PROFILE",
  UPDATE_USER_PROFILE: "LOAD_USER_PROFILE",
  LOAD_USER_PROFILE_SUCCESS: "LOAD_USER_PROFILE_SUCCESS",
  LOAD_USER_PROFILE_FAILURE: "LOAD_USER_PROFILE_FAILURE",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",
  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_USER_PASSWORD_SUCCESS: "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_FAILURE: "UPDATE_USER_PASSWORD_FAILURE",
  LOAD_USER_PENALIZATIONS: "LOAD_USER_PENALIZATIONS",
  LOAD_USER_PENALIZATIONS_SUCCESS: "LOAD_USER_PENALIZATIONS_SUCCESS",
  LOAD_USER_PENALIZATIONS_FAILURE: "LOAD_USER_PENALIZATIONS_FAILURE",
  LOAD_FULL_USER_PROFILE: "LOAD_FULL_USER_PROFILE",
  LOAD_FULL_USER_PROFILE_SUCCESS: "LOAD_FULL_USER_PROFILE_SUCCESS",
  LOAD_FULL_USER_PROFILE_FAILURE: "LOAD_FULL_USER_PROFILE_FAILURE",
  CLEAR_USER_PROFILE: "CLEAR_USER_PROFILE",
};
