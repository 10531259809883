import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background: url('${(props) => props.background}') no-repeat ;
  background-size: cover;
  display: flex;
  justify-content: flex-end;

  .container-recuperasenha {
    width: 40%;
    min-height: 100vh;
    background: ${({ theme }) => theme.color.white.main};
    box-shadow: -10px 0px 10px #00000033;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .progress-bar{
      width: 400px;
      height: 8px;
      border-radius: 4px;
      background: ${({ theme }) => theme.color.gray.light};
      margin-bottom: 7.6%;
      .bar {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: ${({ theme }) => theme.color.primary.light};
      }
      .red {
        background: #A72D57;
      }
    }

    img {
      margin-bottom: 24px;
      width: 50%;
    }

    .input {
      margin-bottom: 16px;
    }

    .input-registration {
      display: flex;
      flex-direction: column;
      width: 280px;
    }

    p#counter {
      align-self: flex-end;
      font-size: 10px;
      color: ${({ theme }) => theme.color.gray.main};
      margin-top: -32px;
      margin-right: 8px;
    }

    .button-main {
      width: 280px;
      height: 56px;
      margin-bottom: 10px;
      margin-top: 8px;
      color: #fff;
      background:${({ theme }) => theme.color.secondary.main};
      box-shadow: 3px 3px 6px #0000003D;
    }

    p{
      text-align: center;
      font-size: 16px;
      color: ${({ theme }) => theme.color.gray.light};
      margin-bottom: 24px;
      a{
        text-decoration: none;
        color: ${({ theme }) => theme.color.primary.main};
        font-size: 14px;
        font-weight: bold;
      }
    }

    hr {
      margin-bottom: 24px;
    }

    .success-send {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 70%;
        margin-top: -10px;
        padding-top: 30px;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }

  .container-recuperasenha-mobile {
    display: none;
  }

  @media screen and (max-width: 1000px){

    .container-recuperasenha {
      display: none
    }

    .container-recuperasenha-mobile {
      width: 100%;
      min-height: 100vh;
      background: ${({ theme }) => theme.color.primary.main};
      box-shadow: 10px 0px 10px #00000033;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        margin-bottom: 24px;
        width: 160px;
        height: 160px
      }

      .input {
        margin-bottom: 16px;
        color: #fff;
      }

      .input-registration {
        display: flex;
        flex-direction: column;
        width: 280px;
        margin-bottom: 16px;
      }

      .button-main {
        width: 280px;
        height: 56px;
        margin-bottom: 30px;
        margin-top: 30px;
        color: #fff;
        background:${({ theme }) => theme.color.secondary.main};
        box-shadow: 3px 3px 6px #0000003D;
      }

      .success-send {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          width: 100px;
          height: 130px;
          margin-top: -10px;
          padding-top: 30px;
        }
        p {
          font-size: 1rem;
          color: #fff;
        }
      }
    }
  }


`;
