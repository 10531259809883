import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

//Material Components

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Backdrop,
  Fade,
  Grid,
  TextField,
  Typography,
  Hidden,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";

//Material Icons

import {Search, Close, ArrowBack, Check} from "@material-ui/icons";

//Redux actions

import {applyPenalty} from "@/store/actions/admin";

//Custom components

import Feedback from "@/components/Widgets/Feedbacks";

const Solicitations = (props) => {
  const dispatch = useDispatch();
  const {classes, setValue, admOptionSelected, setAdmOptionSelected} = props;

  const {user} = useSelector(({userProfile}) => userProfile);
  const {members, allPenalties, feedback} = useSelector(({admin}) => admin);

  //Functionality
  const [openModalPenalties, setOpenModalPenalties] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [currentMember, setCurrentMember] = useState(""); //Guardar o id da solicitação clicada
  const [filteredMember, setFilteredMembers] = useState([]);
  const [checked, setChecked] = useState(null);
  const [currentPenaltie, setCurrentPenaltie] = useState(null);

  const handleOpenModalPenalties = (member) => {
    setCurrentMember(member);
    setOpenModalPenalties(true);
  };

  const handleCloseModalPenalties = () => {
    setOpenModalPenalties(false);
    setCurrentPenaltie(null);
    setChecked(null);
    setCurrentMember("");
  };

  const handleClickRefAdmSolicitations = (index) => {
    setAdmOptionSelected(!admOptionSelected);
    setValue(index);
  };

  const handleShowFeedback = () => setShowFeedback(!showFeedback);

  const filterMembers = (key) => {
    if (key === "") {
      return setFilteredMembers(members);
    }
    setFilteredMembers(
      members.filter(
        (member) =>
          member.username.toLowerCase().indexOf(key.toLowerCase()) !== -1
      )
    );
  };

  const handleChange = (penaltie) => {
    if (checked === penaltie.id) {
      setCurrentPenaltie(null);
      return setChecked(null);
    }
    setCurrentPenaltie(penaltie);
    return setChecked(penaltie.id);
  };

  const handleApplyPenalty = () => {
    const penalty = {
      member_id: currentMember.id,
      admin_id: user.id,
      penalty_id: currentPenaltie.id,
      justification: "Sem justificação",
    };

    dispatch(applyPenalty(penalty));
    handleCloseModalPenalties();
  };

  return (
    <div className="history-content">
      <Hidden mdUp>
        <Typography align="left" variant="body1" style={{marginTop: "20px"}}>
          <ArrowBack
            style={{fontSize: 30}}
            onClick={() => handleClickRefAdmSolicitations(0)}
          />
        </Typography>
      </Hidden>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{marginBottom: "4%"}}
      >
        <Grid item md={8} xs={12}>
          <Grid
            container
            className={classes.wrapper}
            justify="center"
            alignItems="center"
          >
            <TextField
              className={classes.searchField}
              size="medium"
              label="Buscar por nome..."
              onChange={({target}) => filterMembers(target.value)}
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Hidden mdDown>
        <TableContainer>
          {members.length ? (
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tablePenalizationHead}>
                    Nome
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Área
                  </TableCell>
                  <TableCell
                    className={classes.tablePenalizationHead}
                    align="center"
                  >
                    Pontos
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredMember.length === 0 ? members : filteredMember).map(
                  (member) => {
                    return (
                      <TableRow
                        key={member.id}
                        className={
                          member.username === user.username &&
                          classes.tablePenalizationFieldCurrent
                        }
                      >
                        <TableCell
                          className={classes.tablePenalizationField}
                          component="th"
                          scope="row"
                        >
                          {member.username === user.username
                            ? "Você"
                            : member.username}
                        </TableCell>
                        <TableCell
                          className={classes.tablePenalizationField}
                          align="center"
                        >
                          {member.field_action}
                        </TableCell>
                        <TableCell
                          className={classes.tablePenalizationField}
                          align="center"
                        >
                          {member.points + " pontos"}
                        </TableCell>
                        <TableCell
                          className={classes.tablePenalizationField}
                          align="center"
                        >
                          <Button
                            className={classes.buttonPenalizate}
                            size="small"
                            variant="contained"
                            onClick={(e) => handleOpenModalPenalties(member)}
                          >
                            Penalizar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="h6" align="center" color="primary">
              Nenhum membro encontrado
            </Typography>
          )}
        </TableContainer>
      </Hidden>
      <div className="history-table-mobile">
        <div className="history-table-mobile-row">
          <p className={classes.tablePenalizationHeadMobile}>Nome</p>
          <p className={classes.tablePenalizationHeadMobile}>Área</p>
          <p className={classes.tablePenalizationHeadMobile}>Pontos</p>
        </div>
        {(filteredMember.length === 0 ? members : filteredMember).map(
          (member) => {
            return (
              <Accordion
                elevation={0}
                className={classes.expandedGeneral}
                key={member.id}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="history-table-mobile-row">
                    <p className={classes.tablePenalizationFieldMobile}>
                      {member.username}
                    </p>
                    <p className={classes.tablePenalizationFieldMobile}>
                      {member.field_action}
                    </p>
                    <p className={classes.tablePenalizationFieldMobile}>
                      {member.points}
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="history-table-mobile-expanded">
                    <div className="history-table-mobile-expanded-btn">
                      <Button
                        className={classes.buttonDetails}
                        size="small"
                        variant="contained"
                      >
                        Perfil
                      </Button>
                      <Button
                        className={classes.buttonPenalizate}
                        size="small"
                        variant="contained"
                        onClick={(e) => handleOpenModalPenalties(member)}
                      >
                        Penalizar
                      </Button>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
      </div>

      <Modal
        aria-labelledby="modal-details-title"
        aria-describedby="modal-details-description"
        className={classes.modal}
        open={openModalPenalties}
        onClose={handleCloseModalPenalties}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPenalties} elevation={1}>
          <div className={classes.modalContainer}>
            <h2
              id="modal-details-title"
              style={{color: "#333"}}
              className={classes.modalTitle}
            >
              Aplicação de Penalidades
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalPenalties}
              />
            </h2>
            <TableContainer style={{marginBottom: 20}}>
              <Table aria-label="table user">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tablePenalizationHead}>
                      Nome
                    </TableCell>
                    <TableCell
                      className={classes.tablePenalizationHead}
                      align="center"
                    >
                      Área
                    </TableCell>
                    <TableCell
                      className={classes.tablePenalizationHead}
                      align="center"
                    >
                      Pontos
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={classes.tableModalFields}
                      component="th"
                      scope="row"
                    >
                      {currentMember.username}
                    </TableCell>
                    <TableCell
                      className={classes.tableModalFields}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {currentMember.field_action}
                    </TableCell>
                    <TableCell
                      className={classes.tableModalFields}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {`${currentMember.points || 0} pontos`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer style={{maxHeight: "30%", overflow: "auto"}}>
              {allPenalties.length ? (
                <Table aria-label="table user data">
                  <TableBody>
                    {allPenalties.map((penaltie) => (
                      <TableRow
                        key={penaltie.id}
                        className={classes.tableModalRow}
                      >
                        <TableCell
                          className={classes.tableModalFields}
                          component="th"
                          scope="row"
                        >
                          <Checkbox
                            checked={checked === penaltie.id}
                            onChange={() => handleChange(penaltie)}
                            className={classes.modalCheckbox}
                            style={{color: "#000"}}
                            inputProps={{
                              "aria-label": `${
                                checked && checked !== penaltie.id
                                  ? `disabled checkbox`
                                  : `uncontrolled-checkbox`
                              }`,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          className={classes.tableModalFields}
                          component="th"
                          scope="row"
                        >
                          {penaltie.title}
                        </TableCell>
                        <TableCell
                          className={classes.tableModalFields}
                          align="center"
                        >
                          {`${penaltie.score} pontos`}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography
                  variant="body1"
                  align="center"
                  color="primary"
                  style={{marginTop: 20}}
                >
                  Nenhuma penalidade cadastrada
                </Typography>
              )}
            </TableContainer>
            <Typography
              variant="body2"
              align="justify"
              style={{padding: "0 15px", marginTop: 20}}
            >
              {currentPenaltie
                ? `O membro ${currentMember.username} será penalizado com ${
                    currentPenaltie.score
                  }, totalizando ${
                    parseInt(currentMember.points) +
                    parseInt(currentPenaltie.score)
                  } pontos de penalidades.`
                : "Selecione a penalidade desejada"}
            </Typography>
            <Grid container justify="center" style={{marginTop: 20}}>
              <Grid
                item
                md={6}
                xs={6}
                style={{display: "flex", justifyContent: "center"}}
              >
                <Button
                  variant="contained"
                  className={classes.buttonSubmitModalGreen}
                  disabled={!currentPenaltie}
                  onClick={handleApplyPenalty}
                >
                  Confirmar
                </Button>
              </Grid>
              <Grid
                item
                md={6}
                xs={6}
                style={{display: "flex", justifyContent: "center"}}
              >
                <Button
                  variant="contained"
                  className={classes.buttonCancelModalRed}
                  onClick={handleCloseModalPenalties}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>

      <Feedback
        open={showFeedback && !feedback}
        close={handleShowFeedback}
        type={"success"}
        message={`${currentMember.username} foi penalizado(a)`}
      >
        <Check style={{marginRight: 10}} />
      </Feedback>

      <Feedback
        open={showFeedback && feedback}
        close={handleShowFeedback}
        type={"error"}
        message={"Erro ao aplicar penalidade"}
      >
        <Close style={{marginRight: 10}} />
      </Feedback>
    </div>
  );
};

export default Solicitations;
