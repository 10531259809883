import React, {useState} from "react";
import {useDispatch} from "react-redux";

//Redux actions

import {loadUserSolicitations} from "@/store/actions/userSolicitations";

//Material Components

import {
  Paper,
  Button,
  FormControl,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Modal,
  Backdrop,
  Fade,
  Typography,
} from "@material-ui/core";

//Material Icons

import {ExpandMore, Close} from "@material-ui/icons";

//Custom components

import FileInput from "../utils/FileInput";
import SuccessImg from "@/assets/success-png.png";
import ErrorImg from "@/assets/error-png.png";
import api from "@/services/api";

const Solicitation = (props) => {
  const dispatch = useDispatch();
  const {classes} = props;

  //Functionality
  const [openModalRefund, setOpenModalRefund] = useState(false); //Controlar modal de reembolso
  const [openModalWithDrawal, setOpenModalWithDrawal] = useState(false); //Controlar modal de afastamento
  const [openModalShutdown, setOpenModalShutdown] = useState(false); //Controlar modal de desligamento
  const [haveSolicitationRefund, setHaveSolicitationRefund] = useState(false); //Controle se há solicitação de Reembolso (usado para mensagem de sucesso ou não)
  const [haveSolicitationWithDrawal, setHaveSolicitationWithDrawal] = useState(
    false
  ); //Controle se há solicitação de Afastamento (usado para mensagem de sucesso ou não)
  const [haveSolicitationShutdown, setHaveSolicitationShutdown] = useState(
    false
  ); //Controle se há solicitação de Desligamento (usado para mensagem de sucesso ou não)
  const [errorFileUpload, setErrorFileUpload] = useState(false);
  const [event_picture, setEvent_picture] = useState("");

  //Methods

  const handleOpenModalRefund = () => {
    setOpenModalRefund(true);
  };

  const handleCloseModalRefund = () => {
    setOpenModalRefund(false);
    setHaveSolicitationRefund(false);
  };

  const handleOpenModalWithDrawal = () => {
    setOpenModalWithDrawal(true);
  };

  const handleCloseModalWithDrawal = () => {
    setOpenModalWithDrawal(false);
    setHaveSolicitationWithDrawal(false);
  };

  const handleOpenModalShutdown = () => {
    setOpenModalShutdown(true);
  };

  const handleCloseModalShutdown = () => {
    setOpenModalShutdown(false);
    setHaveSolicitationShutdown(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentEvent = parseInt(e.target.name);
    const formData = new FormData();

    formData.append("event_picture", event_picture);
    formData.append("description", "Arquivo PDF");
    formData.append("type_id", currentEvent);

    if (event_picture !== "") {
      await api({
        url: "/events",
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          dispatch(loadUserSolicitations());
          switch (currentEvent) {
            case 1:
              setHaveSolicitationShutdown(true);
              setErrorFileUpload(false);
            case 2:
              setHaveSolicitationWithDrawal(true);
              setErrorFileUpload(false);
            case 3:
              setHaveSolicitationRefund(true);
              setErrorFileUpload(false);
          }
          setEvent_picture("");
        })
        .catch((error) => {
          switch (currentEvent) {
            case 1:
              setHaveSolicitationShutdown(true);
              setErrorFileUpload(true);
            case 2:
              setHaveSolicitationWithDrawal(true);
              setErrorFileUpload(true);
            case 3:
              setHaveSolicitationRefund(true);
              setErrorFileUpload(true);
          }
        });
    } else {
      alert("ERRO");
    }
  };

  return (
    <>
      <div className="solicitation-web">
        <Paper className="solicitation-card" elevation={2}>
          <h2 className="solicitation-card-title">REEMBOLSO</h2>
          <Typography
            variant="body2"
            align="left"
            className="solicitation-card-description"
          >
            A solicitação de reembolso pode ocorrer quando, por qualquer razão
            um membro utiliza de suas finanças para adquirir algum produto ou
            serviço para a empresa. Para solicitar o pedido de reembolso, faz-se
            necessário enviar um documento .pdf contendo a explicação detalhada
            do dinheiro investido com cópias de comprovantes e/ou nota fiscal.
          </Typography>
          <div className="solicitation-card-btn">
            <Button
              className={classes.buttonSubmitModal}
              onClick={handleOpenModalRefund}
            >
              SOLICITAR REEMBOLSO
            </Button>
          </div>
        </Paper>

        <Paper className="solicitation-card" elevation={2}>
          <h2 className="solicitation-card-title">AFASTAMENTO</h2>
          <Typography
            variant="body2"
            align="left"
            className="solicitation-card-description"
          >
            O membro pode pedir afastamento mediante as seguintes situações:{" "}
            <br />
            Participação em estágio nacional ou internacional; Motivos de saúde,
            por laudo médico; Demais casos analisados pela Diretoria Executiva.
            Para solicitar o afastamento deve ser enviado à diretoria um arquivo
            .pdf explicando o motivo que o leva a optar pelo afastamento, bem
            como documentos que comprovem a necessidade do mesmo (como laudos
            médicos, estágio, etc).
          </Typography>
          <div className="solicitation-card-btn">
            <Button
              className={classes.buttonSubmitModal}
              onClick={handleOpenModalWithDrawal}
            >
              SOLICITAR AFASTAMENTO
            </Button>
          </div>
        </Paper>
        <Paper className="solicitation-card" elevation={2}>
          <h2 className="solicitation-card-title">DESLIGAMENTO</h2>
          <Typography
            variant="body2"
            align="left"
            className="solicitation-card-description"
          >
            O membro pode pedir desligamento mediante as seguintes situações:
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.
          </Typography>
          <div className="solicitation-card-btn">
            <Button
              className={classes.buttonSubmitModal}
              onClick={handleOpenModalShutdown}
            >
              SOLICITAR DESLIGAMENTO
            </Button>
          </div>
        </Paper>
      </div>
      <div className="solicitation-mobile">
        <div className="solicitation-content">
          <div className="solicitation-table-mobile">
            <ExpansionPanel elevation={0} className={classes.expandedGeneral}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={classes.tablePenalizationFieldMobile}>
                  {"Reembolso"}
                </p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="solicitation-table-mobile-expanded">
                  <p className={classes.tablePenalizationFieldMobile}>
                    A solicitação de reembolso pode ocorrer quando, por qualquer
                    razão um membro utiliza de suas finanças para adquirir algum
                    produto ou serviço para a empresa. <br />
                    <br />
                    Para solicitar o pedido de reembolso, faz-se necessário
                    enviar um documento .pdf contendo a explicação detalhada do
                    dinheiro investido com cópias de comprovantes e/ou nota
                    fiscal.
                  </p>
                  <div className="solicitation-table-mobile-expanded-btn">
                    <Button
                      className={classes.buttonSubmitModal}
                      onClick={handleOpenModalRefund}
                    >
                      SOLICITAR REEMBOLSO
                    </Button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel elevation={0} className={classes.expandedGeneral}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={classes.tablePenalizationFieldMobile}>
                  {"Afastamento"}
                </p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="solicitation-table-mobile-expanded">
                  <p className={classes.tablePenalizationFieldMobile}>
                    O membro pode pedir afastamento mediante as seguintes
                    situações: <br />
                    -Participação em estágio nacional ou internacional; <br />
                    -Motivos de saúde, por laudo médico; <br />
                    -Demais casos analisados pela Diretoria Executiva. <br />
                    Para solicitar o afastamento deve ser enviado à diretoria um
                    arquivo .pdf explicando o motivo que o leva a optar pelo
                    afastamento, bem como documentos que comprovem a necessidade
                    do mesmo (como laudos médicos, estágio, etc).
                  </p>
                  <div className="solicitation-table-mobile-expanded-btn">
                    <Button
                      className={classes.buttonSubmitModal}
                      onClick={handleOpenModalWithDrawal}
                    >
                      SOLICITAR AFASTAMENTO
                    </Button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel elevation={0} className={classes.expandedGeneral}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <p className={classes.tablePenalizationFieldMobile}>
                  {"Desligamento"}
                </p>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className="solicitation-table-mobile-expanded">
                  <p className={classes.tablePenalizationFieldMobile}>
                    O membro pode pedir desligamento mediante as seguintes
                    situações: Lorem Ipsum is simply dummy text of the printing
                    and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged.
                  </p>
                  <div className="solicitation-table-mobile-expanded-btn">
                    <Button
                      className={classes.buttonSubmitModal}
                      onClick={handleOpenModalShutdown}
                    >
                      SOLICITAR DESLIGAMENTO
                    </Button>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="modal-refund-title"
        aria-describedby="modal-refund-description"
        className={classes.modal}
        open={openModalRefund}
        onClose={handleCloseModalRefund}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalRefund} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-refund-title" className={classes.modalTitle}>
              Pedido de reembolso
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalRefund}
              />
            </h2>
            <FormControl name={3} component="form" onSubmit={handleSubmit}>
              <div className={classes.modalContent}>
                {!haveSolicitationRefund ? (
                  <div>
                    <p>
                      Para solicitar o pedido de reembolso, faz-se necessário
                      enviar um documento .pdf contendo a explicação detalhada
                      do dinheiro investido com cópias de comprovantes e/ou nota
                      fiscal.
                    </p>
                    <FileInput
                      buttonProps={{color: "primary"}}
                      multiple={false}
                      id={"file-refund"}
                      onChange={({target}) => setEvent_picture(target.files[0])}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className={classes.modalImageStatus}
                      src={!errorFileUpload ? SuccessImg : ErrorImg}
                      alt="status-image"
                    />
                    <p>
                      {!errorFileUpload
                        ? "Seu pedido foi encaminhado!"
                        : "Erro"}{" "}
                      <br />
                      <br />
                      {!errorFileUpload
                        ? "Aguarde até 72 horas"
                        : "Tente Novamente!"}
                    </p>
                  </div>
                )}
              </div>
              {!haveSolicitationRefund && (
                <Button
                  type="submit"
                  className={classes.buttonSubmitModal}
                  color="secondary"
                >
                  CONFIRMAR
                </Button>
              )}
              <Button
                type="button"
                className={classes.buttonCancelModal}
                onClick={handleCloseModalRefund}
                color="secondary"
              >
                {!haveSolicitationRefund ? "CANCELAR" : "VOLTAR"}
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-withdrawal-title"
        aria-describedby="modal-withdrawal-description"
        className={classes.modal}
        open={openModalWithDrawal}
        onClose={handleCloseModalWithDrawal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalWithDrawal} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-withdrawal-title" className={classes.modalTitle}>
              Pedido de afastamento
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalWithDrawal}
              />
            </h2>
            <FormControl name={2} component="form" onSubmit={handleSubmit}>
              <div className={classes.modalContent}>
                {!haveSolicitationWithDrawal ? (
                  <div>
                    <p>
                      O membro pode pedir afastamento mediante as seguintes
                      situações: <br />
                      -Participação em estágio nacional ou internacional; <br />
                      -Motivos de saúde, por laudo médico;
                      <br />
                      -Demais casos analisados pela Diretoria Executiva.
                    </p>
                    <FileInput
                      buttonProps={{color: "primary"}}
                      multiple={false}
                      id={"file-withdrawal"}
                      onChange={({target}) => setEvent_picture(target.files[0])}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className={classes.modalImageStatus}
                      src={!errorFileUpload ? SuccessImg : ErrorImg}
                      alt="status-image"
                    />
                    <p>
                      {!errorFileUpload
                        ? "Seu pedido foi encaminhado!"
                        : "Erro"}{" "}
                      <br />
                      <br />
                      {!errorFileUpload
                        ? "Aguarde até 72 horas"
                        : "Tente Novamente!"}
                    </p>
                  </div>
                )}
              </div>
              {!haveSolicitationWithDrawal && (
                <Button
                  type="submit"
                  className={classes.buttonSubmitModal}
                  color="secondary"
                >
                  CONFIRMAR
                </Button>
              )}
              <Button
                type="button"
                className={classes.buttonCancelModal}
                onClick={handleCloseModalWithDrawal}
                color="secondary"
              >
                {!haveSolicitationWithDrawal ? "CANCELAR" : "VOLTAR"}
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="modal-shutdown-title"
        aria-describedby="modal-shutdown-description"
        className={classes.modal}
        open={openModalShutdown}
        onClose={handleCloseModalShutdown}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalShutdown} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-shutdown-title" className={classes.modalTitle}>
              Pedido de desligamento
              <Close
                className={classes.modalClose}
                onClick={handleCloseModalShutdown}
              />
            </h2>
            <FormControl name={1} component="form" onSubmit={handleSubmit}>
              <div className={classes.modalContent}>
                {!haveSolicitationShutdown ? (
                  <div>
                    <p>
                      O documento deve seguir o padrão encontrado neste link.
                      https://docs.google.com/document/d/1NW3NuVs
                      _fyjPsl2ZbpPBPcs50wtYILRINTo4hnDDIOo/edit
                    </p>
                    <FileInput
                      buttonProps={{color: "primary"}}
                      multiple={false}
                      id={"file-shutdown"}
                      onChange={({target}) => setEvent_picture(target.files[0])}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      className={classes.modalImageStatus}
                      src={!errorFileUpload ? SuccessImg : ErrorImg}
                      alt="Sucesso-image"
                    />
                    <p>
                      Seu pedido foi encaminhado! <br />
                      <br />
                      Aguarde até 72 horas
                    </p>
                  </div>
                )}
              </div>
              {!haveSolicitationShutdown && (
                <Button
                  type="submit"
                  className={classes.buttonSubmitModal}
                  color="secondary"
                >
                  CONFIRMAR
                </Button>
              )}
              <Button
                type="button"
                className={classes.buttonCancelModal}
                onClick={handleCloseModalShutdown}
                color="secondary"
              >
                {!haveSolicitationShutdown ? "CANCELAR" : "VOLTAR"}
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Solicitation;
