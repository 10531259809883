import {createMuiTheme, makeStyles} from "@material-ui/core";

export const themeMaterial = createMuiTheme({
  palette: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#Fb8c00",
      dark: "#C25E00",
      light: "#FFBD45",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "1.5rem",
        "@media (max-width: 1000px)": {
          fontSize: ".9rem",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.5rem",
        "@media (max-width: 1000px)": {
          fontSize: "1.1rem",
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#5e2075",
        minWidth: "200px",
        "@media (max-width: 1000px)": {
          height: "30px",
        },
      },
      label: {
        fontSize: ".8rem",
        color: "#fff",
      },
    },
  },
});

export const useStyles = makeStyles({
  searchField: {
    width: "60%",
    marginRight: "2%",
    fontSize: "2rem",
    "@media (max-width: 1000px)": {
      width: "80%",
      margin: "30px 0",
    },
  },
  wrapper: {
    "@media (max-width: 1000px)": {
      justifyContent: "center",
    },
  },
  tableMembrosHead: {
    color: "#5e2075",
    fontSize: "1.3vw",
    "@media (max-width: 1000px)": {
      color: "#C25E00",
      fontSize: "16px",
    },
  },
  tableMembrosField: {
    color: "#000000",
  },
  tablePenalizationFieldCurrent: {
    backgroundColor: "#ECECEC",
  },
  tablePenalizationHeadMobile: {
    fontSize: "15px",
    color: "#C25E00",
    fontWeight: 500,
  },
  tablePenalizationFieldMobile: {
    fontSize: "12px",
    color: "#000000",
    fontWeight: 400,
  },
  expandedGeneral: {
    borderBottom: "1px solid #707070",
  },
});
