import React from "react";
import {Grid, Button} from "@material-ui/core";
import {useSpring, animated} from "react-spring";
import {Zoom, Fade} from "react-reveal";
import {Link} from "react-router-dom";

import {Container, Subscribe} from "./styles";

import Header from "../../../components/Header";
import MyBottomNavigation from "../../../components/MyBottomNavigation";
import MountAnimation from "../../../components/MountAnimation";

import gridImg1 from "../../../assets/lp-sobre-grid-img-1.jpg";
import Todos1 from "../../../assets/todos1.jpeg";
import Todos2 from "../../../assets/todos2.jpeg";
import Todos3 from "../../../assets/todos3.png";

import value1Img from "../../../assets/lp-sobre-value-1-img.svg";
import value2Img from "../../../assets/lp-sobre-value-2-img.svg";
import value3Img from "../../../assets/lp-sobre-value-3-img.svg";
import value4Img from "../../../assets/lp-sobre-value-4-img.svg";

import subscribeImg from "../../../assets/lp-sobre-subscribe-img.svg";

const Sobre = () => {
  const physicsConfig = {mass: 5, tension: 200, friction: 40};

  const [propsCard1, setCard1] = useSpring(() => ({
    s: [1],
    config: physicsConfig,
  }));
  const [propsCard2, setCard2] = useSpring(() => ({
    s: [1],
    config: physicsConfig,
  }));
  const [propsCard3, setCard3] = useSpring(() => ({
    s: [1],
    config: physicsConfig,
  }));
  const [propsCard4, setCard4] = useSpring(() => ({
    s: [1],
    config: physicsConfig,
  }));

  return (
    <>
      <Header />
      <Container>
        <MountAnimation title="Sobre nós" backgroundColor="#5e2075" />
        <h1>
          As mesmas estratégias e métodos de empresas tradicionais sob a
          flexibilidade de uma visão jovem e conectada com a atualidade
        </h1>
        <div className="grid-text">
          <h3 className="h3-left">
            Trabalhamos com um time forte, unido e comprometido em entregar o
            melhor em nossos projetos.
          </h3>
          <h3 className="h3-right">
            Utilizamos metodologias ágeis em nossos projetos garantindo assim um
            ambiente de desenvolvimento eficiente.
          </h3>
        </div>
        <div className="grid-imgs-1">
          <div
            className="img-1"
            style={{
              backgroundImage: `url(${gridImg1})`,
              backgroundSize: "cover",
            }}
          />

          <div
            className="img-2"
            style={{
              backgroundImage: `url(${Todos1})`,
              // Imagem temporária, TODO: Alterar quando tiver a imagem certa
              backgroundSize: "cover",
            }}
          />
        </div>
        <div className="grid-imgs-2">
          <div
            className="img-3"
            style={{
              backgroundImage: `url(${Todos2})`,
              // Imagem temporária, TODO: Alterar quando tiver a imagem certa
              backgroundSize: "cover",
            }}
          />
          <div
            className="img-4"
            style={{
              backgroundImage: `url(${Todos3})`,
              // Imagem temporária, TODO: Alterar quando tiver a imagem certa
              backgroundSize: "cover",
            }}
          />
        </div>

        <div className="body">
          <Fade>
            <div className="mission">
              <p id="title">Nossa Missão</p>
              <h2 id="description">
                Transmitir conhecimento, artificial ou não, para simplificar
                processos.
              </h2>
            </div>
          </Fade>

          <div className="values">
            <Fade>
              <p id="title2">Nossos Valores</p>
            </Fade>

            <Grid className="grid-values" container spacing={2} elavation={2}>
              <Grid item xs>
                <animated.div
                  className="value-card"
                  onMouseEnter={() => setCard1({s: [1.03]})}
                  onMouseLeave={() => setCard1({s: [1]})}
                  style={{
                    transform: propsCard1.s.interpolate((s) => `scale(${s})`),
                  }}
                >
                  <img id="value-1-img" src={value1Img} alt="Value-1" />
                  <h3>Proatividade</h3>
                </animated.div>
              </Grid>
              <Grid item xs>
                <animated.div
                  className="value-card"
                  onMouseEnter={() => setCard2({s: [1.03]})}
                  onMouseLeave={() => setCard2({s: [1]})}
                  style={{
                    transform: propsCard2.s.interpolate((s) => `scale(${s})`),
                  }}
                >
                  <img id="value-2-img" src={value2Img} alt="Value-2" />
                  <h3>Respeito</h3>
                </animated.div>
              </Grid>
              <Grid item xs>
                <animated.div
                  className="value-card"
                  onMouseEnter={() => setCard3({s: [1.03]})}
                  onMouseLeave={() => setCard3({s: [1]})}
                  style={{
                    transform: propsCard3.s.interpolate((s) => `scale(${s})`),
                  }}
                >
                  <img id="value-3-img" src={value3Img} alt="Value-3" />
                  <h3>Altruísmo</h3>
                </animated.div>
              </Grid>
              <Grid item xs>
                <animated.div
                  className="value-card"
                  onMouseEnter={() => setCard4({s: [1.03]})}
                  onMouseLeave={() => setCard4({s: [1]})}
                  style={{
                    transform: propsCard4.s.interpolate((s) => `scale(${s})`),
                  }}
                >
                  <img id="value-4-img" src={value4Img} alt="Value-4" />
                  <h3>Comunicação</h3>
                </animated.div>
              </Grid>
            </Grid>
          </div>
          <Subscribe subscribeBg={subscribeImg}>
            <Fade>
              <div className="subscribe-area">
                <h3>
                  Gostou do que fazemos e se identificou com nossos valores?
                  Venha fazer parte do nosso time!
                </h3>
                  <Button
                    color="secondary"
                    className="button"
                    variant="contained"
                    component={Link}
                    to="/contato"
                  >
                    CONTATO
                  </Button>
              </div>
            </Fade>
          </Subscribe>
        </div>
        <MyBottomNavigation pageIndex="sobre" />
      </Container>
    </>
  );
};

export default Sobre;
