import {takeLatest, call, put} from "redux-saga/effects";
import api from "@/services/api";
import {userSolicitationsconstants as cc} from "@/store/types/userSolicitations";

function* loadSolicitations() {
  try {
    const {data} = yield call(api.get, "/events");
    yield put({type: cc.LOAD_USER_SOLICITATIONS_SUCCESS, solicitations: data});
  } catch (e) {
    yield put({type: cc.LOAD_USER_SOLICITATIONS_FAILURE});
  }
}

export default function* watcherSaga() {
  yield takeLatest(cc.LOAD_USER_SOLICITATIONS, loadSolicitations);
}
