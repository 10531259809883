import styled from "styled-components";

export const Container = styled.div`
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5vw;
    background: #f6f5fa;

    .pages {
      display: flex;
      align-items: center;
      a.navlink {
        margin: 0 24px;
        font-size: 2.4rem;
        font-weight: 300;
        color: #16141a;
        position: relative;
        .hover-bar {
          width: 0%;
          height: 2px;
          background: #16141a;

          transition: width 0.5s ease-in-out;
        }

        :hover {
          .hover-bar {
            width: 100%;
          }
        }
      }

      .button-contact {
        background: #f6f5fa;
        color: #16141a;
        font-size: 1.4rem;
        font-weight: 300;
        letter-spacing: 0.02px;
        width: 122px;
        height: 40px;
        box-shadow: 3px 3px 6px #0000001a;
        border: 1px solid #16141a;
        margin-left: 48px;

        span {
          z-index: 1;
        }

        .hover-background {
          position: absolute;
          left: 0;
          height: 100%;
          width: 0%;
          background: #16141a;

          transition: all 0.5s ease-in-out;
        }

        :hover {
          .hover-background {
            width: 100%;
          }

          color: #f6f5fa;
        }
      }
    }

    .active {
      border-bottom: 2px solid #16141a;
      .hover-bar {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .toolbar {
      .pages {
        a.navlink {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .toolbar {
      .logo {
        img {
          width: 140px;
        }
      }
      .pages {
        .button-contact {
          width: 90px;
          height: 30px;
        }
      }
    }
  }
`;
