import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  background: url('${(props) => props.background}') no-repeat ;
  background-size: cover;
  display: flex;
  justify-content: flex-end;

  .container-register {
    min-height: 100vh;
    width: 40%;
    background: ${({ theme }) => theme.color.white.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0px 0px 10px;

    .progress-bar{
      width: 400px;
      height: 8px;
      border-radius: 4px;
      background: ${({ theme }) => theme.color.gray.light};
      margin-bottom: 3%;
      .bar {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: ${({ theme }) => theme.color.primary.light};
      }
    }

    img {
      margin-bottom: 40px;
    }

    p {
      text-align: center;
      margin-bottom: 24px;
      font-size: 24px;
      color: ${({ theme }) => theme.color.gray.main};

      strong {
        text-align: center;
        color: ${({ theme }) => theme.color.primary.main};
        font-size: 24px;
      }
    }


    button {
      width: 280px;
      height: 56px;
      background: #FB8C00;
      box-shadow: 3px 3px 6px #0000003D;
      font-size: 14px;
      color: #FFFFFF;
      margin-top: 8px;
      margin-bottom: 8px; 
    }

  }

  @media screen and (max-width: 1000px){
    .container-register {
      width: 100%;
      border-radius: 0;
    }
  }


`;
