import React, { useState, useEffect } from "react";
import { Fade } from "react-reveal";
import {
  FormControl,
  TextField,
  Button,
  LinearProgress,
} from "@material-ui/core";

import Header from "../../../components/Header";
import MyBottomNavigation from "../../../components/MyBottomNavigation";

import api from "../../../services/api";

import { Container } from "./styles";

import facebookIcon from "../../../assets/facebook-icon.svg";
import instagramIcon from "../../../assets/instagran-icon.svg";
import WhatsAppIcon from "../../../assets/whatsapp-icon.svg";

const Contato = () => {
  const [owner, setOwner] = useState("");
  const [email, setEmail] = useState("");
  const [content, setContent] = useState("");

  const [loadingSubmit, setLoadingSubmit] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoadingSubmit(true);

    await api
      .post("/contact/sendEmail", {
        owner,
        email,
        content,
      })
      .then((response) => {
        console.log(response.status);
        alert(response.data);
        setOwner("");
        setEmail("");
        setContent("");
      })
      .catch((error) => {
        console.log(error);
        alert("O email não foi enviado, tente novamente mais tarde.");
      });

    setLoadingSubmit(false);
  };

  return (
    <>
      <Header />
      <Container>
        <div className="contact-container">
          <h1>Fale com a gente!</h1>
          <p>
            Nos conte sua ideia, tire suas dúvidas e vamos construir juntos uma
            solução.
          </p>
          <Fade>
            <FormControl component="form" onSubmit={handleSubmit}>
              <TextField
                disabled={loadingSubmit}
                label="Nome"
                className="input"
                variant="outlined"
                required
                onChange={({ target }) => setOwner(target.value)}
                value={owner}
                inputProps={{
                  textTransform: "capitalize",
                }}
              />
              <TextField
                disabled={loadingSubmit}
                type="email"
                className="input"
                label="Email"
                variant="outlined"
                required
                onChange={({ target }) => setEmail(target.value)}
                value={email}
              />
              <TextField
                disabled={loadingSubmit}
                label="Mensagem"
                className="input-msg"
                multiline
                variant="outlined"
                rows={5}
                required
                onChange={({ target }) => setContent(target.value)}
                value={content}
              />
              <Button
                color="secondary"
                className="button-submit"
                type="submit"
                variant="contained"
                disabled={loadingSubmit}
              >
                ENVIAR
              </Button>
              {loadingSubmit && <LinearProgress id="submit-progress" />}
            </FormControl>
            <div className="social">
              <a
                href="https://www.facebook.com/emakersjr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="button-icon"
                  src={facebookIcon}
                  alt="Facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/emakersjr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="button-icon"
                  src={instagramIcon}
                  alt="Instagram"
                />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5535997319785&text=Ol%C3%A1%20Emakers%20Jr!"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="button-icon"
                  src={WhatsAppIcon}
                  alt="WhatsApp"
                />
              </a>
            </div>
          </Fade>
          <p id="telephone">+55 (35) 9 9731-9785</p>
        </div>

        <div className="map">
          <iframe
            id="iframe-map"
            title="map"
            src="https://maps.google.com/maps?width=100%&height=561&hl=pt&q=DCC%20-%20Av.%20Central%20UFLA%20-%20Centro%2C%20Lavras%20-%20MG%2C%2037200-000+(Emakers%20Jr.)&ie=UTF8&t=&z=17&iwloc=A&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          >
            <a href="https://www.mapsdirections.info/pt/mapa-circulo-raio/">
              Medir Círculo no Mapa Google
            </a>
          </iframe>
          <br />
          <p>DCC - Av. Central UFLA - Aquenta Sol, Lavras - MG, 37200-000</p>
        </div>
        <MyBottomNavigation pageIndex="none" />
      </Container>
    </>
  );
};

export default Contato;
