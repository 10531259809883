import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .logo {
      display: none;
    }

    .toolbar-menu {
      display: none;
    }

    .toolbar-avatar-container {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({theme}) => theme.color.primary.main};
      overflow: hidden;
      border-radius: 100%;

      .toolbar-avatar {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }

  .dashboard-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: 64px;
  }

  .dashboard-container-items {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 100px;

    .nav-bar-web {
      box-shadow: 0px 3px 3px #0000004d;
    }
  }

  @media screen and (max-width: 1000px) {
    .toolbar {
      justify-content: space-between;

      .logo {
        display: block;
      }

      .toolbar-menu {
        display: block;
      }

      .toolbar-avatar-container {
        display: none;
        .toolbar-avatar {
        }
      }
    }

    .dashboard-content {
      flex-direction: column;
      margin-top: 55px;
    }

    .dashboard-container-items {
      margin-left: 0;
      .nav-bar-web {
        display: none;
      }
    }
  }
`;
