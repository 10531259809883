import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Paper } from "@material-ui/core";

import { Container } from "./styles";
import sucesso from "../../../../../assets/sucesso.svg";
import logo from "../../../../../assets/loginLogo.svg";
import background from "../../../../../assets/backgroundInterno.png";

const Sucesso = () => {
  let history = useHistory();

  const handleFinalize = () => {
    history.push("/membros");
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <img src={sucesso} alt="SUCESSO" />
        <p>
          Seu cadastro foi finalizado com <strong>Sucesso!</strong>
        </p>
        <Button
          variant="contained"
          style={{ background: "#FB8C00" }}
          onClick={handleFinalize}
        >
          FINALIZAR
        </Button>
      </Paper>
    </Container>
  );
};

export default Sucesso;
