import { createMuiTheme, makeStyles } from "@material-ui/core";

export const themeMaterial = createMuiTheme({
  palette: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      dark: "#C25E00",
      light: "#FFBD45",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiSvgIcon: {
      root: {
        fontSize: "20px",
      },
    },
    MuiOutlinedInput: {
      inputMultiline: {
        paddingTop: "5px",
        lineHeight: "normal",
      },
      input: {
        fontSize: "1rem",
      },
    },
    MuiTextField: {
      root: {
        color: "#fff",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1rem",
        "@media (max-width: 1000px)": {
          color: "#fff",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        background: "#f6f5fa",
        padding: "0 5px 5px 0",
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "11px",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1.2rem",
        "@media (max-width: 1000px)": {
          color: "#fff",
          borderBottom: "1px #fff solid",
        },
      },
    },
    MuiButton: {
      contained: {
        "&$disabled": {
          backgroundColor: "rgba(169, 169, 169, 0.51)",
        },
      },
    },
  },
});

export const useStyles = makeStyles({
  buttonBack: {
    width: "280px",
    height: "56px",
    backgroundColor: "#FFBD45",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FFBD45",
    },
  },
});

export const themeStyled = {
  color: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
    },
    secondary: {
      main: "#Fb8c00",
      dark: "#C25E00",
      light: "#FFBD45",
    },
    black: {
      main: "#16141a",
    },
    white: {
      main: "#f6f5fa",
      dark: "#e3e1ed",
    },
    gray: {
      main: "#676586",
      light: "#acaac2",
    },
  },
};
