import {searchConstants as cc} from "@/store/types/searchMembers";

const INITIAL_STATE = {
  members: [],
  loading: false,
  error: false,
};

export default function searchMembers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case cc.LOAD_ALL_MEMBERS:
      return {...state, loading: true, error: false};
    case cc.LOAD_ALL_MEMBERS_SUCCESS:
      return {...state, loading: false, members: action.members};
    case cc.LOAD_ALL_MEMBERS_FAILURE:
      return {...state, loading: false, error: true};
    default:
      return state;
  }
}
