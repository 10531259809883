import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  right: 0;
  background: ${({ backgroundColor }) => backgroundColor};
  z-index: 2000;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: lighter;
  color: #f6f5fa;

  animation: animation-out 1.2s ease-in-out forwards;

  @keyframes animation-out {
    0% {
      width: 100%;
      font-size: 48px;
    }
    70% {
      width: 100%;
      font-size: 56px;
    }
    100% {
      width: 0;
      font-size: 0;
    }
  }
`;
