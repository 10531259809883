import axios from "axios";
import {getToken} from "./auth";

const api = axios.create({
  //baseURL: "http://127.0.0.1:2912" //Dev
  baseURL: "https://api.emakersjr.com.br" //Deploy-Docker
});

api.interceptors.request.use(async (config) => {
  const token = await getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default api;
