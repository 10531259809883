import {createStore, applyMiddleware} from "redux";
import reduxLogger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import sagas from "./sagas";

const sagaMiddlewares = createSagaMiddleware();
const middlewares = [sagaMiddlewares];

if (process.env.NODE_ENV === "development") {
  middlewares.push(reduxLogger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddlewares.run(sagas);

export default store;
