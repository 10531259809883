import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ArrowBackRounded } from "@material-ui/icons";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  Select,
  InputLabel
} from "@material-ui/core";

import { Container } from "./styles";
import logo from "../../../../../assets/loginLogo.svg";
import background from "../../../../../assets/backgroundInterno.png";

import { allowAccessRegister } from "../../../../../store/actions/register.js";

const areas = ["Frontend", "Backend", "Embarcados", "Gestão"];
const curses = [
  "Engenharia de Controle e Automação",
  "Ciência da Computação",
  "Sistemas de Informação"
];

const RotaInfos = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [telephone, setTelephone] = useState("");
  const [curse, setCurse] = useState("");
  const [period, setPeriod] = useState("");
  const [area, setArea] = useState("");

  const [cpfCounter, setCpfCounter] = useState(0);
  const [periodCounter, setPeriodCounter] = useState(0);
  const [rgCounter, setRgCounter] = useState(0);

  const handleSubmit = () => {
    allowAccessRegister(dispatch);
    history.push("/membros/cadastro/3");
  };

  const handleCancel = () => {
    history.push("/membros/");
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Container background={background}>
      <Paper className="container-register">
        <img src={logo} alt="Emakers Jr." />
        <div className="progress-bar">
          <div className="bar" />
        </div>
        <div className="back-container">
          <div className="back" onClick={handleBack}>
            <ArrowBackRounded style={{ color: "#676586" }} />
            <p>Voltar</p>
          </div>
        </div>
        <FormControl component="form" onSubmit={handleSubmit}>
          <TextField
            label="Nome Completo"
            className="input"
            variant="outlined"
            required
            onChange={({ target }) => setName(target.value)}
          />

          <div className="inputs-cpf-rg">
            <div className="input-cpf">
              <TextField
                label="CPF"
                variant="outlined"
                required
                inputProps={{ maxLength: 11, minLength: 11 }}
                error={(cpfCounter === 11) | (cpfCounter === 0) ? false : true}
                onChange={({ target }) => {
                  setCpf(target.value);
                  setCpfCounter(target.value.length);
                }}
              />
              <p id="counter">{cpfCounter}/11</p>
            </div>
            <div className="input-rg">
              <TextField
                label="RG"
                variant="outlined"
                required
                inputProps={{ maxLength: 10, minLength: 10 }}
                error={(rgCounter === 10) | (rgCounter === 0) ? false : true}
                onChange={({ target }) => {
                  setRg(target.value);
                  setRgCounter(target.value.length);
                }}
              />
              <p id="counter">{rgCounter}/10</p>
            </div>
          </div>

          <TextField
            label="Telefone"
            className="input"
            variant="outlined"
            required
            onChange={({ target }) => setTelephone(target.value)}
          />

          <div className="inputs-curso-periodo">
            <FormControl
              className="input-curse"
              variant="filled"
              required
              onChange={({ target }) => setCurse(target.value)}
            >
              <InputLabel>Curso</InputLabel>
              <Select native>
                <option />
                {curses.map(curse => (
                  <option>{curse}</option>
                ))}
              </Select>
            </FormControl>

            <div className="input-period">
              <TextField
                label="Periodo"
                variant="outlined"
                required
                inputProps={{ maxLength: 2, minLength: 2 }}
                error={
                  (periodCounter === 2) | (periodCounter === 0) ? false : true
                }
                onChange={({ target }) => {
                  setPeriod(target.value);
                  setPeriodCounter(target.value.length);
                }}
              />
              <p id="counter">{periodCounter}/2</p>
            </div>
          </div>

          <FormControl
            className="input-area"
            variant="filled"
            required
            onChange={({ target }) => setArea(target.value)}
          >
            <InputLabel>Área</InputLabel>
            <Select native>
              <option />
              {areas.map(area => (
                <option>{area}</option>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            style={{ background: "#FB8C00" }}
          >
            PRÓXIMO
          </Button>
          <Button
            variant="contained"
            style={{ background: "#FFBD45" }}
            onClick={handleCancel}
          >
            CANCELAR
          </Button>
        </FormControl>
      </Paper>
    </Container>
  );
};

export default RotaInfos;
