import {takeLatest, call, put} from "redux-saga/effects";
import api from "@/services/api";
import {searchConstants as cc} from "@/store/types/searchMembers";

function* loadAllMembers() {
  try {
    const {data} = yield call(api.get, "/profiles");
    yield put({type: cc.LOAD_ALL_MEMBERS_SUCCESS, members: data.data});
  } catch (e) {
    yield put({type: cc.LOAD_ALL_MEMBERS_FAILURE});
  }
}

function* loadListOfMembers() {
  try {
    const {data} = yield call(api.get, "/members-list-download");
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "relacao_de_membros.pdf");
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    yield put({type: cc.LOAD_DOCUMENT_RELATIONSHIP_FAILURE});
  }
}

export default function* watcherSaga() {
  yield takeLatest(cc.LOAD_ALL_MEMBERS, loadAllMembers);
  yield takeLatest(cc.LOAD_DOCUMENT_RELATIONSHIP, loadListOfMembers);
}
