import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#f6f5fa",
    position: "fixed",
    minHeight: "56px",
    minWidth: "100%",
    bottom: 0,
    zIndex: 1000,
    boxShadow: "0px -3px 6px #0000004D",
    "@media screen and (min-width: 769px)": {
      display: "none",
    },
  },
});

export default useStyles;
