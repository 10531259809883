import React, { useMemo } from "react";
import isArray from "lodash/fp/isArray";
import isString from "lodash/fp/isString";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { AttachFile } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
// import FormHelperText from '@material-ui/core/FormHelperText';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  label: {
    whiteSpace: "nowrap",
    marginRight: "10px",
    display: "flex",
  },
  button: {
    backgroundColor: "#FFBD45",
    border: "none",
    color: "#000000",
    display: "flex",
    "&:hover": {
      backgroundColor: "#FFBD45",
      color: "#000000",
    },
    "@media (max-width: 1000px)": {
      color: "#000000",
    },
  },
}));

const acceptVariants = {
  word:
    ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  images: "image/*",
  excel:
    ".xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const makeAcceptString = (accept) => {
  if (!accept) {
    return "";
  }

  if (isString(accept)) {
    return acceptVariants[accept] ? acceptVariants[accept] : accept;
  }

  if (isArray(accept)) {
    const len = accept.length;
    return accept.reduce((acc, curr, currIndex) => {
      if (acceptVariants[curr]) {
        acc += acceptVariants[curr];
      } else if (curr && curr.length && curr.trim().length) {
        acc += curr.trim();
      }

      if (len > 1 && currIndex < len - 1) {
        acc += ",";
      }

      return acc;
    }, "");
  }

  return "";
};

const FileInput = ({
  accept,
  multiple,
  id,
  inputProps,
  labelProps,
  buttonProps,
  onChange,
  label = "Escolher arquivo...",
}) => {
  const classes = useStyles();

  const acceptString = useMemo(() => makeAcceptString(accept), [accept]);

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <input
          id={id}
          accept={acceptString}
          className={classes.input}
          multiple={multiple}
          type="file"
          onChange={onChange}
          {...inputProps}
        />
        <div className={classes.container}>
          <label className={classes.label} htmlFor={id} {...labelProps}>
            <AttachFile /> Anexar arquivo
          </label>
          <label className={classes.label} htmlFor={id} {...labelProps}>
            <Button
              variant="contained"
              component="span"
              className={classes.button}
              {...buttonProps}
            >
              {label}
            </Button>
          </label>
        </div>
      </FormControl>
    </div>
  );
};

FileInput.propTypes = {
  multiple: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default FileInput;
