const INITIAL_STATE = {
  allowAccessRegister: false
};

export default function register(state = INITIAL_STATE, action) {
  if (action.type === "ALLOW_ACCESS_REGISTER") {
    return { ...state, allowAccessRegister: true };
  }

  return state;
}
