import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: Roboto;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background: #f6f5fa;
}
button,
a {
  cursor: pointer;
}
a {
  text-decoration: none;
}

@media screen and (max-width: 1650px) {
  html {
    font-size: 58%;
  }
}

@media screen and (max-width: 1366px) {
  html {
    font-size: 49%;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 40%;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 36%;
  }
}


`;
