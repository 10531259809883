import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .toolbar-title {
      font-size: 1.5rem;
      font-weight: 500;
      flex: 1;
      text-align: center;
      margin-left: 4%;
    }

    .toolbar-menu {
      display: none;
    }

    .toolbar-avatar {
      width: 30px;
      height: 30px;
    }
  }

  .solicitation-content {
    flex: 1;
    display: flex;
    flex-direction: row;

    .side-bar {
      background-color: ${({theme}) => theme.color.primary.main};
      width: 64px;
    }

    .nav-bar-mobile {
      display: none;
    }
  }

  .solicitation-container-items {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    .nav-bar-web {
      box-shadow: 0px 3px 3px #0000004d;
    }

    .solicitation-container {
      flex: 1;
      display: flex;
      justify-content: center;

      .solicitation-web {
        flex: 1;
        max-width: 1500px;
        display: flex;
        padding: 0 2%;
        flex-wrap: wrap;
        flex-direction: row;

        .solicitation-card {
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 30%;
          max-width: 537px;
          margin: 3% auto;
          padding: 10px 20px 15px 20px;

          .solicitation-card-title {
            font-size: 1.3rem;
            font-weight: 600;
            color: ${({theme}) => theme.color.primary.dark};
            margin: 2% 0 8% 0;
          }

          .solicitation-card-description {
            flex: 1;
            background-color: #f1f1f1;
            margin: 0 20px;
            font-size: 1rem;
            text-align: left;
          }

          .solicitation-card-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px 0;
          }
        }
      }

      .solicitation-mobile {
        display: none;
      }
    }

    .history-content {
      flex: 1;
      padding: 50px 100px;

      .history-table-mobile {
        display: none;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .toolbar {
      justify-content: space-between;

      .toolbar-title {
        display: block;
        margin: 0;
        font-weight: bold;
        font-size: 1.1rem;
        text-align: start;
      }

      .toolbar-menu {
        display: block;
      }

      .toolbar-avatar {
        display: none;
      }
    }

    .solicitation-content {
      flex-direction: column;
      .side-bar {
        display: none;
      }

      .nav-bar-mobile {
        display: block;
        background-color: #fff;
        margin-bottom: 7px;
        box-shadow: 0 1px 3px #0000004d;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      .solicitation-avatar-mobile {
        width: 100%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: ${({theme}) => theme.color.primary.main};

        .solicitation-avatar-mobile-img {
          width: 100px;
          height: 100px;
        }
      }

      .solicitation-avatar-mobile h2 {
        margin: 15px 0;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .solicitation-container-items {
      .nav-bar-web {
        display: none;
      }

      .solicitation-container {
        .solicitation-web {
          display: none;
          padding: 0;
          width: 100%;
          align-items: center;

          .solicitation-card {
            flex: 1;
            width: 100%;
            margin: 0;
            padding: 0;
            box-shadow: none;
            border-radius: none;
          }
        }

        .solicitation-mobile {
          display: block;

          .solicitation-content {
            padding: 0;
            width: 100%;
            padding: 0 15px;
            background-color: #fff;

            .solicitation-table-web {
              display: none;
            }

            .solicitation-table-mobile {
              display: block;
              margin-top: 30px;

              :nth-child(1) {
                border-top: 1px solid #707070;
              }

              .solicitation-table-mobile-expanded {
                min-height: 70px;
                display: flex;
                flex-direction: column;

                .solicitation-table-mobile-expanded-btn {
                  margin-top: 10px;
                  display: flex;
                  justify-content: flex-end;
                }
              }
            }
          }
        }

        .history-content {
          padding: 0;
          width: 100%;
          padding: 0 15px;

          .history-table-mobile {
            display: block;
          }

          .history-table-mobile {
            display: block;

            .history-table-mobile-row-nopadding {
              width: 100%;
              height: 48px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .history-table-mobile-row {
              width: 100%;
              padding: 0 5%;
              height: 48px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .history-table-mobile-expanded {
              min-height: 60px;
              flex: 1;
              display: flex;
              justify-content: center;
              flex-direction: column;

              .history-table-mobile-expanded-btn {
                flex: 1;
                margin-top: 10px;
                align-items: center;
                display: flex;
                justify-content: space-evenly;
                background-color: #e3e1ed;
              }
            }
          }
        }
      }
    }
  }
`;
