import React, {useEffect, useState} from "react";
import Plx from "react-plx";
import {Link} from "react-router-dom";
import {Fade} from "react-reveal";
import {Paper, Button, Typography} from "@material-ui/core";
import PublicIcon from "@material-ui/icons/Public";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import {Container, Apresentation} from "./styles";

import Header from "../../../components/Header";
import MyBottomNavigation from "../../../components/MyBottomNavigation";
import MouseAnimationContainer from "../../../components/MouseAnimationContainer";

import astronaut from "../../../assets/lp-home-apresentation-astronaut.png";
import backAstronaut from "../../../assets/lp-home-apresentation-backAstronaut.jpg";

import waveImg from "../../../assets/lp-home-body-wave-img.svg";
import boatImg from "../../../assets/lp-home-body-boat-img.svg";
import cloudImg from "../../../assets/lp-home-body-cloud-img.svg";

import teamBg from "../../../assets/lp-home-body-team-bg.jpg";

import infos1Icon from "../../../assets/lp-home-infos-1-icon.svg";
import infos1Img from "../../../assets/lp-home-infos-1-img.svg";
import infos2Icon from "../../../assets/lp-home-infos-2-icon.svg";
import infos2Img from "../../../assets/lp-home-infos-2-img.svg";
import infos3Icon from "../../../assets/lp-home-infos-3-icon.svg";
import infos3Img from "../../../assets/lp-home-infos-3-img.svg";

import Isabele from "../../../assets/Isabele.jpg";
import William from "../../../assets/William.jpeg";

import bottomBg from "../../../assets/lp-home-bottom-bg.svg";

import {toRightOnly, toLeftOnly} from "../../../utils/parallaxDatas";

import api from "../../../services/api";

const Home = () => {
  const [testimonial1, setTestimonial1] = useState({});
  const [testimonial2, setTestimonial2] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    const getTestimonials = async () => {
      await api
        .get("/testimonials/2")
        .then((response) => {
          console.log(response.data);
          const testimonials = response.data;
          setTestimonial1(testimonials[0]);
          setTestimonial2(testimonials[0]); // TODO: Alterar para 'setTestimonial2(testimonials[1]);' quando tiverem 2 depoimentos na API
        })
        .catch((e) => {
          //TODO: Alterar o segundo depoimento quando tiver
          const testimonials = [
            {
              id: 6,
              picture_url: William,
              // Imagem temporária, TODO: Alterar quando tiver a imagem certa
              instagram_url: "https://www.instagram.com/williamabreu96",
              contact_url: "http://williamabreu.net/",
              linkedin_url: "https://linkedin.com/in/williamabreu96",
              description:
                "A experiência como co-fundador da Emakers Jr. e como diretor de projetos no início da EJ foi muito gratificante e me rendeu uma experiência empreendedora que jamais conseguiria em nenhuma iniciação científica nem em estágio, além de ter aberto minha visão quanto à gestão de processos e de equipes. Atuar na empresa desde sua gênese até o fechamento do primeiro projeto entregue por ela envolveu muito estresse e empenho, mas que foram determinantes para formação do profissional que sou hoje. Por isso afirmo: valeu a pena!",
              created_at: "2020-05-20T20:52:50.000Z",
              updated_at: "2020-05-20T20:52:50.000Z",
            },
            {
              id: 6,
              picture_url: Isabele,
              // Imagem temporária, TODO: Alterar quando tiver a imagem certa
              instagram_url: "https://www.instagram.com/isabellerc_25",
              contact_url: "https://www.facebook.com/isabelle.rc.79",
              linkedin_url:
                "https://www.linkedin.com/in/isabelle-rodrigues-coelho-09615a192",
              description:
                "Durante todo o tempo que permaneci na Emakers Jr., além do aprendizado nos mais diversos âmbitos, fiz diversas amizades que pretendo levar para a vida! Também devo dizer que é uma honra ser a primeira mulher a participar da história dessa EJ como membro efetivo e, depois, como Diretora de Processos Internos. Trabalhar na Emakers foi uma experiência incrível que recomendo a todos que desejam crescer profissional e pessoalmente. Agradeço a todos que tornaram essa jornada possível e desejo cada vez mais sucesso para a empresa. #go Emakers!",
              created_at: "2020-05-20T20:52:50.000Z",
              updated_at: "2020-05-20T20:52:50.000Z",
            },
          ];
          setTestimonial1(testimonials[0]);
          setTestimonial2(testimonials[1]);
        });
    };

    getTestimonials();
  }, []);

  return (
    <>
      <Header />

      <Container bottomBg={bottomBg} teamBg={teamBg}>
        <Apresentation background={backAstronaut} astronaut={astronaut}>
          <div className="filter">
            <Fade bottom>
              <p id="text-title">Soluções inteligentes e inovadoras</p>
              <h6 id="text-description">
                Compromisso em simplificar processos e desenvolver soluções
                inovadoras para o mercado.
              </h6>
            </Fade>
            <MouseAnimationContainer color="#f6f5fa" />
          </div>
        </Apresentation>
        <div className="body">
          <div className="transition-texts">
            <Fade>
              <p id="text-1">
                "Ter minha plataforma produzida pelo Emakers foi um sonho
                realizado."<p> - Cássia, Redação Inteligente.</p>
              </p>
            </Fade>
          </div>
          <Fade left>
            <Paper className="container-infos info-1" elevation={1}>
              <div className="content">
                <img id="infos-icons" src={infos1Icon} alt="Info1" />
                <p id="text-title">Projetos de alta qualidade</p>
                <p id="text-description">
                  Temos o compromisso de desenvolver produtos seguindo as mais
                  recentes tendência do mercado, garantindo qualidade no que
                  desenvolvemos.
                </p>
              </div>
              <div className="img">
                <img src={infos1Img} alt="" />
              </div>
            </Paper>
          </Fade>

          <Fade right>
            <Paper className="container-infos info-2" elevation={1}>
              <div className="img">
                <img src={infos2Img} alt="" />
              </div>
              <div className="content">
                <img id="infos-icons" src={infos2Icon} alt="Info1" />
                <p id="text-title">Preço abaixo do mercado</p>
                <p id="text-description">
                  Oferecemos preços mais atraentes e condições especiais de
                  pagamentos.
                </p>
              </div>
            </Paper>
          </Fade>

          <Fade left>
            <Paper className="container-infos info-3" elevation={1}>
              <div className="content">
                <img id="infos-icons" src={infos3Icon} alt="Info1" />
                <p id="text-title">Time qualificado</p>
                <p id="text-description">
                  Membros que estão sempre em busca de superar desafios,
                  desenvolver ideias e entregar resultados
                </p>
              </div>
              <div className="img">
                <img src={infos3Img} alt="" />
              </div>
            </Paper>
          </Fade>

          <div
            className="info-boat"
            style={{
              background: `url(${waveImg})`,
              backgroundSize: "100% auto",
              backgroundRepeat: "no-repeat",
              backgroundPositionY: "101%",
            }}
          >
            <Plx
              tagName="img"
              src={boatImg}
              alt="boat"
              className="boat"
              parallaxData={toRightOnly}
            />
            <Plx
              src={cloudImg}
              alt="cloud"
              className="cloud"
              parallaxData={toLeftOnly}
            >
              <h2>Impulsionando Carreiras!</h2>
              <p>
                A Emakers Jr. proporciona experiências para que seus membros se
                tornem grandes profissionais.
              </p>
            </Plx>
          </div>

          <div className="peoples">
            <Fade bottom>
              <div className="cards card-1">
                <div
                  className="card-img"
                  style={{
                    backgroundImage: `url(${testimonial1.picture_url})`,
                  }}
                >
                  <div className="social">
                    <a href={testimonial1.contact_url}>
                      <PublicIcon style={{color: "white", fontSize: "3rem"}} />
                    </a>
                    <a href={testimonial1.instagram_url}>
                      <InstagramIcon
                        style={{color: "white", fontSize: "3rem"}}
                      />
                    </a>
                    <a href={testimonial1.linkedin_url}>
                      <LinkedInIcon
                        style={{color: "white", fontSize: "3rem"}}
                      />
                    </a>
                  </div>
                </div>
                <div className="card-content">
                  <p className="comment">{testimonial1.description}</p>
                  <Typography
                    style={{
                      marginTop: 30,
                      color: "#676586",
                      marginRight: 40,
                      width: "100%",
                    }}
                    variant="h5"
                    align="right"
                  >
                    William Abreu
                  </Typography>
                </div>
              </div>
            </Fade>

            <div className="img-team-divider"></div>

            <Fade bottom>
              <div className="cards card-2">
                <div className="card-content">
                  <p className="comment">{testimonial2.description}</p>
                  <Typography
                    style={{
                      marginTop: 30,
                      color: "#676586",
                      marginRight: 40,
                      width: "100%",
                    }}
                    variant="h5"
                    align="right"
                  >
                    Isabelle Coelho
                  </Typography>
                </div>
                <div
                  className="card-img"
                  style={{
                    backgroundImage: `url(${testimonial2.picture_url})`,
                  }}
                >
                  <div className="social">
                    <a href={testimonial2.contact_url}>
                      <PublicIcon style={{color: "white", fontSize: "3rem"}} />
                    </a>
                    <a href={testimonial2.instagram_url}>
                      <InstagramIcon
                        style={{color: "white", fontSize: "3rem"}}
                      />
                    </a>
                    <a href={testimonial2.linkedin_url}>
                      <LinkedInIcon
                        style={{color: "white", fontSize: "3rem"}}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Fade>
            <div className="bottom">
              <Fade right>
                <div className="content">
                  <p id="text-title">Sempre prontos para te ouvir.</p>
                  <h6 id="text-description">
                    Possui uma ideia inovadora, revolucionária ou deseja mais
                    informações sobre nós e como trabalhamos, entre em contato.
                  </h6>
                  <Button
                    color="secondary"
                    className="button"
                    variant="contained"
                    component={Link}
                    to="/contato"
                  >
                    CONTATO
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <MyBottomNavigation pageIndex="none" />
      </Container>
    </>
  );
};

export default Home;
