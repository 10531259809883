import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "@/services/formatDate";

//Custom Icons
import Lapis from "@/assets/mode-edit.svg";

//Redux actions
import {
  loadUserProfile,
  updateUserProfile,
  updateUserPassword,
} from "@/store/actions/userProfile";

//Material Components
import {
  Avatar,
  Paper,
  Button,
  FormControl,
  TextField,
  Badge,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";

//Material icons
import {Create, Check, Close} from "@material-ui/icons";

//Custom Components
import Feedback from "@/components/Widgets/Feedbacks";

const Profile = (props) => {
  const {
    classes,
    chooseProfilePicture,
    btnUpdateFields,
    handleShowBtnUpdateFields,
    profilePicture,
    setProfilePicture,
  } = props;
  const dispatch = useDispatch();
  const {user, error, feedback} = useSelector(({userProfile}) => userProfile);

  //Functionality
  const [openModal, setOpenModal] = useState(false); //Controlar modal de alteração de Senha
  const [showModalBtn, setShowModalBtn] = useState(true); //Botão confirmar do modal de Senha
  const [statusFromChangePass, setStatusFromChangePass] = useState(""); //Menssagem de status do modal de senha
  const [showFeedback, setShowFeedback] = useState(false);

  //Fields
  const refInputProfile = useRef(null);
  const [currentPassword, setCurrentPassword] = useState("**********");
  const [phone_number, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [address_number, setAddressNumber] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [district, setDistrict] = useState("");
  const [address_complements, setAddressComplement] = useState("");
  const [field_action, setFieldAction] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPass, setConfigNewPass] = useState("");

  useEffect(() => {
    if (!user.id) {
      dispatch(loadUserProfile());
    }
  }, []);

  useEffect(() => {
    setProfilePicture(user.profile_picture);
    setPhoneNumber(user.phone_number);
    setStreet(user.street);
    setPostalCode(user.postal_code);
    setDistrict(user.district);
    setFieldAction(user.field_action);
    setAddressNumber(user.address_number);
    setAddressComplement(user.address_complements);
  }, [user]);

  const handleOpenPassModal = () => {
    setOpenModal(!openModal);
  };

  const handleClosePassModal = () => {
    setOpenModal(false);
    setStatusFromChangePass("");
  };

  const handleShowModalBtn = () => {
    setShowModalBtn(!showModalBtn);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    setStatusFromChangePass("");
    handleShowModalBtn();

    if (oldPassword == "" || newPassword == "" || confirmNewPass == "") {
      setStatusFromChangePass("Preencha todos os campos!");
      return;
    }

    if (newPassword !== confirmNewPass) {
      setStatusFromChangePass("As senhas não coincidem!");
      return;
    }

    const pass = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    dispatch(updateUserPassword(pass));
    handleClosePassModal();
    handleShowFeedback();
  };

  const handleChangePerfil = async (event) => {
    event.preventDefault();

    setAddressNumber(address_number.toString());

    const formData = new FormData();
    formData.append("profile_picture", profilePicture);
    formData.append("current_period", user.current_period);
    formData.append("field_action", field_action);
    formData.append("phone_number", phone_number);
    formData.append("street", street);
    formData.append("address_number", address_number);
    formData.append("district", district);
    formData.append("postal_code", postal_code);
    formData.append("address_complements", address_complements);
    formData.append("points", user.points);

    dispatch(updateUserProfile(formData));
    handleShowBtnUpdateFields();
  };

  const handleShowFeedback = () => setShowFeedback(!showFeedback);

  return (
    <>
      <FormControl
        component="form"
        className="perfil-form"
        onSubmit={handleChangePerfil}
      >
        <Paper className="perfil-card perfil-card-mobile" elevation={2}>
          <div className="perfil-avatar">
            <Badge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              className="perfil-avatar-img"
              badgeContent={
                btnUpdateFields && (
                  <Avatar
                    alt="Avatar"
                    src={Lapis}
                    className="perfil-avatar-img-small"
                    onClick={chooseProfilePicture}
                  />
                )
              }
            >
              <Avatar
                alt="Avatar"
                src={user?.profile_picture_url}
                className="perfil-avatar-img"
              />
              <input
                onChange={({target}) => setProfilePicture(target.files[0])}
                style={{display: "none"}}
                type="file"
                ref={refInputProfile}
              />
            </Badge>
          </div>
          <p className="perfil-card-title">Informações públicas</p>
          <TextField
            label="Nome"
            className="perfil-input"
            type="text"
            size="small"
            value={user?.username}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
          <TextField
            label="Email"
            className="perfil-input"
            type="email"
            size="small"
            value={user?.email}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
          <TextField
            label="Curso"
            className="perfil-input"
            type="text"
            size="small"
            value={user?.course}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
          <div className="perfil-card-row">
            <TextField
              label="Telefone"
              className="perfil-input input-telephone"
              type="text"
              size="small"
              value={user?.phone_number}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              InputLabelProps={{shrink: true}}
            />
            <TextField
              label="Período"
              className="perfil-input"
              type="text"
              size="small"
              value={user?.current_period}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              InputLabelProps={{shrink: true}}
            />
          </div>
          <TextField
            label="Data de nascimento"
            className="perfil-input"
            type="text"
            size="small"
            value={user && formatDate(user.birth)}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
          <TextField
            label="Data de admissão"
            className="perfil-input"
            type="text"
            size="small"
            value={user && formatDate(user.admission_date)}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
          <TextField
            label="Área de interesse"
            className="perfil-input perfil-input-mobile-end"
            type="text"
            size="small"
            value={user?.field_action}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            InputLabelProps={{shrink: true}}
          />
        </Paper>

        <Paper className="perfil-card" elevation={2}>
          <div className="perfil-card-btn-update">
            {!btnUpdateFields ? (
              <Create
                className={classes.buttonUpdateField}
                onClick={handleShowBtnUpdateFields}
              />
            ) : null}
            {btnUpdateFields ? (
              <>
                <Check
                  className={classes.buttonUpdateField}
                  onClick={handleChangePerfil}
                  type="submit"
                />
                <Close
                  className={classes.buttonUpdateField}
                  onClick={handleShowBtnUpdateFields}
                />
              </>
            ) : null}
          </div>
          <h2 className="perfil-title">Alteração dos dados pessoais</h2>
          <p className="perfil-card-title">Informações privadas</p>
          <TextField
            label="Senha"
            className="perfil-input"
            type="password"
            size="small"
            value={user?.currentPassword || "**********"}
            onChange={({target}) => setCurrentPassword(target.value)}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{shrink: true}}
            required
            onClick={btnUpdateFields ? handleOpenPassModal : undefined}
          />
          <TextField
            label="Telefone"
            className="perfil-input"
            type="text"
            size="small"
            value={phone_number}
            onChange={({target}) => setPhoneNumber(target.value)}
            InputProps={{
              readOnly: !btnUpdateFields,
            }}
            InputLabelProps={{shrink: true}}
            required
          />
          <TextField
            label="Endereço"
            className="perfil-input"
            type="text"
            size="small"
            value={street}
            onChange={({target}) => setStreet(target.value)}
            InputProps={{
              readOnly: !btnUpdateFields,
            }}
            InputLabelProps={{shrink: true}}
            required
          />
          <div className="perfil-card-row">
            <TextField
              label="Número"
              className="perfil-input input-numero"
              type="text"
              size="small"
              value={address_number}
              onChange={({target}) => setAddressNumber(target.value)}
              InputProps={{
                readOnly: !btnUpdateFields,
              }}
              InputLabelProps={{shrink: true}}
              required
            />
            <TextField
              label="CEP"
              className="perfil-input"
              type="text"
              size="small"
              value={postal_code}
              onChange={({target}) => setPostalCode(target.value)}
              required
              InputLabelProps={{shrink: true}}
              InputProps={{
                maxLength: 8,
                minLength: 8,
                readOnly: !btnUpdateFields,
              }}
            />
          </div>
          <div className="perfil-card-row">
            <TextField
              label="Bairro"
              className="perfil-input input-bairro"
              type="text"
              size="small"
              value={district}
              onChange={({target}) => setDistrict(target.value)}
              InputProps={{
                readOnly: !btnUpdateFields,
              }}
              InputLabelProps={{shrink: true}}
              required
            />
            <TextField
              label="Complemento"
              className="perfil-input"
              type="text"
              size="small"
              value={address_complements}
              onChange={({target}) => setAddressComplement(target.value)}
              InputProps={{
                readOnly: !btnUpdateFields,
              }}
              InputLabelProps={{shrink: true}}
            />
          </div>
          <TextField
            label="Área de interesse"
            className="perfil-input perfil-input-mobile-end"
            type="text"
            size="small"
            value={field_action}
            onChange={({target}) => setFieldAction(target.value)}
            InputProps={{
              readOnly: !btnUpdateFields,
            }}
            InputLabelProps={{shrink: true}}
          />
        </Paper>
        <Modal
          aria-labelledby="password-modal-title"
          aria-describedby="password-modal-description"
          className={classes.modalPassword}
          open={openModal}
          onClose={handleClosePassModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal} elevation={1}>
            <div className={classes.modalPasswordContainer}>
              <h2
                id="password-modal-title"
                className={classes.modalPasswordTitle}
              >
                Alteração de senha
                <Close onClick={handleClosePassModal} />
              </h2>
              <FormControl component="form" onSubmit={handleChangePassword}>
                <TextField
                  label="Senha atual"
                  className={classes.modalPasswordInput}
                  value={oldPassword}
                  onChange={({target}) => setOldPassword(target.value)}
                  type="password"
                  size="small"
                />
                <TextField
                  label="Nova senha"
                  className={classes.modalPasswordInput}
                  value={newPassword}
                  onChange={({target}) => setNewPassword(target.value)}
                  type="password"
                  size="small"
                />
                <TextField
                  label="Confirmar senha"
                  className={classes.modalPasswordInput}
                  value={confirmNewPass}
                  onChange={({target}) => setConfigNewPass(target.value)}
                  type="password"
                  size="small"
                />
                {statusFromChangePass && (
                  <p className={classes.modalPasswordStatus}>
                    {statusFromChangePass}
                  </p>
                )}
                <Button
                  type="submit"
                  className={classes.buttonSubmitModal}
                  color="secondary"
                >
                  CONFIRMAR
                </Button>

                <Button
                  type="button"
                  className={classes.buttonCancelModal}
                  onClick={handleClosePassModal}
                  color="secondary"
                >
                  {showModalBtn ? "CANCELAR" : "VOLTAR"}
                </Button>
              </FormControl>
            </div>
          </Fade>
        </Modal>
      </FormControl>

      <Feedback
        open={showFeedback && !error}
        close={handleShowFeedback}
        type={"success"}
        message={"Senha alterada com sucesso !"}
      >
        <Check style={{marginRight: 10}} />
      </Feedback>

      <Feedback
        open={showFeedback && error}
        close={handleShowFeedback}
        type={"error"}
        message={"Erro ao alterar a senha !"}
      >
        <Close style={{marginRight: 10}} />
      </Feedback>
    </>
  );
};

export default Profile;
