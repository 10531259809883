import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

//Redux actions

import {loadPeriod, updatePeriod} from "@/store/actions/admin";

//Material Components

import {
  Paper,
  Button,
  FormControl,
  Modal,
  Backdrop,
  Fade,
  Grid,
  Typography,
  Hidden,
} from "@material-ui/core";

//Material Icons

import {Close, ArrowBack} from "@material-ui/icons";

const Period = (props) => {
  const dispatch = useDispatch();
  const {classes, setValue, admOptionSelected, setAdmOptionSelected} = props;

  const {currentPeriod} = useSelector(({admin}) => admin);
  const [openModalChangePeriod, setOpenModalChangePeriod] = useState(false);

  //Methods

  useEffect(() => {
    if (!currentPeriod) {
      dispatch(loadPeriod());
    }
  }, []);

  const handleOpenModalChangePeriod = () => {
    setOpenModalChangePeriod(!openModalChangePeriod);
  };

  const handleClickRefAdmSolicitations = (index) => {
    setAdmOptionSelected(!admOptionSelected);
    setValue(index);
  };

  const getNextPeriod = () => {
    if (currentPeriod) {
      const currentPeriodSplit = currentPeriod.split("/");
      const currentYear = parseInt(currentPeriodSplit[0]);
      const currentMouth = parseInt(currentPeriodSplit[1]);

      if (currentMouth === 1) return `${currentYear}/${currentMouth + 1}`;
      return `${currentYear + 1}/${currentMouth - 1}`;
    }
  };

  const handleChangePeriod = () => {
    const nextPeriod = getNextPeriod();
    if (nextPeriod) {
      const newPeriod = {
        period: nextPeriod,
        active: true,
      };

      dispatch(updatePeriod(newPeriod));
      handleOpenModalChangePeriod();
    }
  };

  return (
    <>
      <div className={classes.periodPaper}>
        <Paper elevation={!admOptionSelected && 3} className={classes.paper}>
          <Hidden mdUp>
            <Typography
              align="left"
              variant="body1"
              style={{
                marginLeft: "-20px",
                margin: "20px 0",
                width: "100%",
              }}
            >
              <ArrowBack
                style={{fontSize: 30}}
                onClick={() => handleClickRefAdmSolicitations(0)}
              />
            </Typography>
          </Hidden>
          <Typography
            variant="h5"
            color="primary"
            align="center"
            style={{fontWeight: 700, marginBottom: 80}}
          >
            Alteração do período corrente
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{fontWeight: 400, marginBottom: 30}}
          >
            Período atual: {currentPeriod || "Período atual indefinido"}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{fontWeight: 400, marginBottom: 50}}
          >
            Próximo período: {getNextPeriod() || "Período atual indefinido"}
          </Typography>
          <Typography
            variant="caption"
            align="justify"
            style={{
              fontWeight: 400,
            }}
          >
            Obs.: A alteração do período é irreversível. Esta opção irá
            incrementar o período do <br /> sistema e de todos os membros
            registrados
          </Typography>
          <Grid container justify="center" style={{marginTop: 80}}>
            <Grid
              item
              md={12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                className={classes.buttonSubmitModal}
                onClick={handleOpenModalChangePeriod}
              >
                Alterar Período
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
      <Modal
        aria-labelledby="modal-changePeriod-title"
        aria-describedby="modal-changePeriod-description"
        className={classes.modal}
        open={openModalChangePeriod}
        onClose={handleOpenModalChangePeriod}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalChangePeriod} elevation={1}>
          <div className={classes.modalContainer}>
            <h2 id="modal-changePeriod-title" className={classes.modalTitle}>
              Alteração de Período
              <Close
                className={classes.modalClose}
                onClick={handleOpenModalChangePeriod}
              />
            </h2>
            <FormControl name={3} component="form">
              <div className={classes.modalContent}>
                <p>
                  Ao clicar em 'CONFIRMAR' você estará alterando o período
                  letivo de todo o sistema. <br />
                  <br />
                  Sua ação não poderá ser desfeita.
                </p>
              </div>
              <Button
                type="button"
                className={classes.buttonSubmitModalGreen}
                color="secondary"
                onClick={handleChangePeriod}
              >
                CONFIRMAR
              </Button>
              <Button
                type="button"
                className={classes.buttonCancelModalRed}
                onClick={handleOpenModalChangePeriod}
                color="secondary"
              >
                VOLTAR
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Period;
