import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Visibility, VisibilityOff, Error} from "@material-ui/icons";
import {
  Paper,
  FormControl,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Snackbar,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import {Container} from "./styles";
import logo from "@/assets/loginLogo.svg";
import background from "@/assets/backgroundInterno.png";
import {login} from "@/services/auth";
import api from "@/services/api";

const Login = () => {
  let history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeFeedBack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowFeedback(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await api
      .post("/sessions", {email, password})
      .then((res) => {
        login(res.data.token);
        history.push("/dashboard/perfil");
      })
      .catch((err) => {
        setShowFeedback(true);
      });
    setLoading(false);
  };

  return (
    <Container background={background}>
      <Paper className="container-login">
        <img src={logo} alt="Emakers Jr." />
        <FormControl component="form" onSubmit={handleSubmit}>
          <TextField
            type="email"
            required
            className="input"
            variant="outlined"
            label="Email"
            onChange={({target}) => setEmail(target.value)}
          />
          <TextField
            className="input"
            type={showPassword ? "text" : "password"}
            label="Senha"
            variant="outlined"
            required
            onChange={({target}) => setPassword(target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button className="button-main" variant="contained" type="submit">
            {!loading ? "LOGAR" : <CircularProgress style={{color: "#fff"}} />}
          </Button>
          <p>
            Esqueceu sua senha?{" "}
            <a href="/membros/recuperar-senha">Clique aqui</a>
          </p>
        </FormControl>
      </Paper>

      <Snackbar
        open={showFeedback}
        autoHideDuration={6000}
        onClose={closeFeedBack}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body2" align="center" className="login-feedback">
          <Error style={{marginRight: 10}} />
          Erro ao tentar fazer Login, verifique suas credenciais
        </Typography>
      </Snackbar>
    </Container>
  );
};

export default Login;
