import {createMuiTheme, makeStyles} from "@material-ui/core";

export const themeMaterial = createMuiTheme({
  palette: {
    primary: {
      main: "#5e2075",
      dark: "#300049",
      light: "#8d4eA4",
      contrastText: "#fff",
    },
    secondary: {
      main: "#Fb8c00",
      dark: "#C25E00",
      light: "#FFBD45",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTab: {
      labelIcon: {
        fontSize: "25px",
        minHeight: "56px",
        textAlign: "center",
        textTransform: "capitalize",
      },
      wrapper: {
        flexDirection: "row",
        "& svg, .material-icons": {
          fontSize: 40,
          marginRight: 5,
        },
      },
      textColorPrimary: {
        color: "#5e2075",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#5e2075",
        border: "1px solid #5e2075",
        width: "140px",
        height: "30px",
        "@media (max-width: 1000px)": {
          width: "70px",
          height: "20px",
        },
      },
      label: {
        fontSize: ".7rem",
        "&:hover": {
          color: "#fff",
        },
        "@media (max-width: 1000px)": {
          fontSize: ".6rem",
          textTransform: "capitalize",
        },
      },
      colorInherit: {
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#5e2075",
        },
        "@media (max-width: 1000px)": {
          backgroundColor: "#5e2075",
          border: "none",
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0,
        height: "48px",
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        backgroundColor: "#E3E1ED",
      },
    },
    MuiAvatar: {
      img: {
        width: "80%",
        height: "80%",
      },
    },
  },
});

export const useStyles = makeStyles({
  tabGeneral: {
    minHeight: "45px",
    fontSize: "15px",
    textAlign: "center",
    textTransform: "capitalize",
    "& svg, .material-icons": {
      fontSize: 20,
    },
  },
  tabCard: {
    width: "100px",
    minHeight: "100px",
    borderRadius: "10px",
    fontSize: "15px",
    textAlign: "center",
    textTransform: "capitalize",
    "& svg, .material-icons": {
      fontSize: 20,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    width: "45%",
    maxWidth: "600px",
    padding: "1.5%",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    "@media (max-width: 1000px)": {
      width: "90%",
    },
  },
  modalClose: {
    cursor: "pointer",
  },
  modalTitle: {
    color: "#676586",
    fontSize: "1.3rem",
    fontWeight: 500,
    marginBottom: "2%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1000px)": {
      fontSize: "1rem",
    },
  },
  modalContent: {
    padding: "20px",
    fontSize: "15px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  modalContentSubtitle: {
    width: "100%",
    textAlign: "start",
    fontSize: "1.2rem",
    marginBottom: "15px",
  },
  modalContentSubtitleMin: {
    fontSize: "1.2rem",
    color: "#757575",
    display: "flex",
    justifyContent: "center",
    marginBottom: "15px",
    textAlign: "justify",
    cursor: "pointer",
  },
  modalStatus: {
    margin: "20px 0",
    fontSize: "1rem",
    textAlign: "center",
  },
  modalImageStatus: {
    width: "100px",
    height: "100px",
    marginBottom: "10px",
  },
  modalCheckbox: {
    padding: 0,
    color: "#000",
  },
  tableModal: {
    fontWeight: 600,
    fontSize: "1rem",
    color: themeMaterial.palette.secondary.dark,
  },
  tableModalRow: {
    backgroundColor: "#ECECEC",
  },
  tableModalFields: {
    fontWeight: 500,
    fontSize: ".9rem",
    color: "#000000",
    maxHeight: "52px",
  },
  searchField: {
    width: "60%",
    marginRight: "2%",
    fontSize: "2rem",
    "@media (max-width: 1000px)": {
      width: "80%",
      margin: "30px 0",
    },
  },
  wrapper: {
    justifyContent: "center",
    "@media (max-width: 1000px)": {
      justifyContent: "center",
    },
  },
  tablePenalizationHead: {
    color: "#5e2075",
    fontSize: "1.3vw",
    "@media (max-width: 1000px)": {
      color: "#C25E00",
      fontSize: "16px",
    },
  },
  tablePenalizationField: {
    color: "#000000",
  },
  tablePenalizationFieldCurrent: {
    backgroundColor: "#ECECEC",
  },
  tablePenalizationHeadMobile: {
    fontSize: "15px",
    color: "#C25E00",
    fontWeight: 500,
  },
  tablePenalizationFieldMobile: {
    fontSize: "12px",
    color: "#000000",
    fontWeight: 400,
  },
  tablePenalizationCheckText: {
    fontSize: "11px",
    color: "#000000",
    fontWeight: 300,
  },
  expandedGeneral: {
    borderBottom: "1px solid #707070",
  },
  buttonDefault: {
    backgroundColor: "#5e2075",
    color: "#fff",
    border: "none",
  },
  buttonPending: {
    backgroundColor: "#F3E02E",
    color: "#040404",
    border: "none",
    "&:hover": {
      backgroundColor: "#F3E02E",
    },
  },
  buttonDenied: {
    backgroundColor: "#EF2525AD",
    color: "#fff",
    border: "none",
    "&:hover": {
      backgroundColor: "#EF2525AD",
      color: "#fff",
    },
  },
  buttonCancel: {
    backgroundColor: "transparent",
    color: "#EE0E0E",
    border: "1px solid #EE0E0E",
    "&:disabled": {
      border: "1px solid #848484",
      color: "#848484",
      border: "none",
    },
    "&:hover": {
      backgroundColor: "#EE0E0E",
      color: "#fff",
    },
    "@media (max-width: 1000px)": {
      backgroundColor: "#EE0E0E",
      color: "#fff",
    },
  },
  buttonAccept: {
    backgroundColor: "#1D8000D9",
    color: "#fff",
    border: "none",
    "&:hover": {
      backgroundColor: "#1D8000D9",
      color: "#fff",
    },
  },
  buttonDetails: {
    backgroundColor: "#FB8C00",
    color: "#fff",
    border: "none",
    "&:hover": {
      backgroundColor: "#FB8C00",
      color: "#fff",
    },
  },
  buttonSubmitModal: {
    backgroundColor: "#FB8C00",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#FB8C00",
    },
  },
  buttonSubmitModalGreen: {
    backgroundColor: "#1D8000",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#1D8000",
    },
  },
  buttonCancelModal: {
    backgroundColor: "#FFBD45",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#FFBD45",
    },
  },
  buttonCancelModalRed: {
    backgroundColor: "#EF0000B3",
    color: "#fff",
    boxShadow: "3px 3px 6px #0000003D",
    width: "60%",
    height: "40px",
    border: "none",
    alignSelf: "center",
    margin: "10px 0",
    "&:hover": {
      backgroundColor: "#EF0000B3",
      color: "#fff",
    },
  },
  buttonPenalizate: {
    backgroundColor: "#EE0E0E",
    color: "#fff",
    border: "1px solid #EE0E0E",
    "&:disabled": {
      border: "1px solid #848484",
      color: "#848484",
      border: "none",
    },
    "&:hover": {
      backgroundColor: "#EE0E0E",
      color: "#fff",
    },
    "@media (max-width: 1000px)": {
      backgroundColor: "#EE0E0E",
      color: "#fff",
    },
  },
  cardAdmMobile: {
    width: "150px",
    height: "150px",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    alignItems: "center",
    flexDirection: "column",
  },
  periodPaper: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width: 1000px)": {
      justifyContent: "flex-start",
    },
  },
  paper: {
    padding: "1% 10%",
  },
});
