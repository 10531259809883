import {takeLatest, call, put} from "redux-saga/effects";
import api from "@/services/api";
import {userProfileConstants as cc} from "@/store/types/userProfile";

function* loadProfile() {
  try {
    const {data} = yield call(api.get, "/my-profile");
    yield put({type: cc.LOAD_USER_PROFILE_SUCCESS, user: data});
  } catch (e) {
    yield put({type: cc.LOAD_USER_PROFILE_FAILURE});
  }
}

function* loadFullProfile() {
  try {
    const {data} = yield call(api.get, "/users");
    yield put({type: cc.LOAD_FULL_USER_PROFILE_SUCCESS, fullUser: data});
  } catch (e) {
    yield put({type: cc.LOAD_FULL_USER_PROFILE_FAILURE});
  }
}

function* updateProfile({user}) {
  try {
    const {data} = yield call(api.put, "/profiles", user);
    yield put({type: cc.UPDATE_USER_PROFILE_SUCCESS, user: data});
  } catch (e) {
    yield put({type: cc.UPDATE_USER_PROFILE_FAILURE});
  }
}

function* updatePassword({passwords}) {
  try {
    yield call(api.put, "/update-password", passwords);
    yield put({type: cc.UPDATE_USER_PASSWORD_SUCCESS});
  } catch (e) {
    yield put({type: cc.UPDATE_USER_PASSWORD_FAILURE});
  }
}

function* loadPenalizations() {
  try {
    const {data} = yield call(api.get, "/my-penalties");
    yield put({type: cc.LOAD_USER_PENALIZATIONS_SUCCESS, penalizations: data});
  } catch (e) {
    yield put({type: cc.LOAD_USER_PENALIZATIONS_FAILURE});
  }
}

export default function* watcherSaga() {
  yield takeLatest(cc.LOAD_USER_PROFILE, loadProfile);
  yield takeLatest(cc.LOAD_FULL_USER_PROFILE, loadFullProfile);
  yield takeLatest(cc.UPDATE_USER_PROFILE, updateProfile);
  yield takeLatest(cc.UPDATE_USER_PASSWORD, updatePassword);
  yield takeLatest(cc.LOAD_USER_PENALIZATIONS, loadPenalizations);
}
