import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadAllPenalizations} from "@/store/actions/userPenalizations";
import {loadAllPenalties} from "@/store/actions/admin";
import {formatDate} from "@/services/formatDate";

import {
  ThemeProvider,
  Box,
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputAdornment,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";

import {Search, Close} from "@material-ui/icons";

import {Container} from "./styles";
import {themeMaterial, useStyles} from "./customMUI";

const ListPenalization = (props) => {
  //initial methods
  const dispatch = useDispatch();
  const classes = useStyles(); //Classes Personalizadas MUI (Arquivo customMUI)

  //Reducers
  const {user} = useSelector(({userProfile}) => userProfile);
  const {allPenalizations} = useSelector(
    ({userPenalizations}) => userPenalizations
  );
  const {allPenalties} = useSelector(({admin}) => admin);

  const [filteredPenalizations, setFilteredPenalizations] = useState([]);
  const [openModalPenalties, setOpenModalPenalties] = useState(false);

  useEffect(() => {
    dispatch(loadAllPenalizations());
    dispatch(loadAllPenalties());
    props.setTitle("PENALIZAÇÕES");
  }, []);

  const handleControlModalPenalties = () =>
    setOpenModalPenalties(!openModalPenalties);

  const handleHasAdmin = () => {
    const role = user.roles;
    if (role && role.length) {
      return true;
    }
    return false;
  };

  const filterPenalizations = (key) => {
    if (key === "") {
      return setFilteredPenalizations(allPenalizations);
    }

    if (!isNaN(key)) {
      setFilteredPenalizations(
        allPenalizations.filter(
          (penalization) =>
            parseInt(penalization["penalty"].score) === parseInt(key)
        )
      );
    } else {
      setFilteredPenalizations(
        allPenalizations.filter(
          (penalization) =>
            penalization["member"].username
              .toLowerCase()
              .indexOf(key.toLowerCase()) !== -1
        )
      );
    }
  };

  return (
    <Container>
      <ThemeProvider theme={themeMaterial}>
        <Box className="membros-container-items">
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{marginBottom: "4%"}}
          >
            <Grid item md={8} xs={12}>
              <Grid
                container
                className={classes.wrapper}
                justify="flex-end"
                alignItems="center"
              >
                {allPenalizations.length ? (
                  <TextField
                    className={classes.searchField}
                    id="searchField"
                    size="medium"
                    label="Buscar por nome ou pontuação..."
                    onChange={({target}) => filterPenalizations(target.value)}
                    color="primary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Box style={{marginTop: 30}}></Box>
                )}
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              {handleHasAdmin() && (
                <Grid
                  container
                  className={classes.wrapper}
                  justify="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleControlModalPenalties}
                  >
                    Penalidades Cadastradas
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <TableContainer>
            {allPenalizations.length ? (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableMembrosHead}>
                      Nome
                    </TableCell>
                    <TableCell
                      className={classes.tableMembrosHead}
                      align="center"
                    >
                      Data
                    </TableCell>
                    <TableCell
                      className={classes.tableMembrosHead}
                      align="center"
                    >
                      Pontos
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(filteredPenalizations.length
                    ? filteredPenalizations
                    : allPenalizations
                  ).map((penalization) => {
                    return (
                      <TableRow
                        key={penalization.id}
                        className={
                          penalization["member"].username === user.username &&
                          classes.tablePenalizationFieldCurrent
                        }
                      >
                        <TableCell
                          className={classes.tableMembrosField}
                          component="th"
                          scope="row"
                        >
                          {penalization["member"].username === user.username
                            ? "Você"
                            : penalization["member"].username}
                        </TableCell>
                        <TableCell
                          className={classes.tableMembrosField}
                          align="center"
                        >
                          {formatDate(penalization.created_at)}
                        </TableCell>
                        <TableCell
                          className={classes.tableMembrosField}
                          align="center"
                        >
                          {penalization["penalty"].score + " pontos"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="h6" align="center" color="primary">
                Nenhuma penalização foi aplicada
              </Typography>
            )}
          </TableContainer>
          <Modal
            aria-labelledby="modal-details-title"
            aria-describedby="modal-details-description"
            className={classes.modal}
            open={openModalPenalties}
            onClose={handleControlModalPenalties}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModalPenalties} elevation={1}>
              <div className={classes.modalContainer}>
                <h2 id="modal-details-title" className={classes.modalTitle}>
                  Penalidades Cadastradas
                  <Close
                    className={classes.modalClose}
                    onClick={handleControlModalPenalties}
                  />
                </h2>
                <TableContainer>
                  {allPenalties.length ? (
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableModal}>
                            Nome
                          </TableCell>
                          <TableCell
                            className={classes.tableModal}
                            align="center"
                          >
                            Pontuação
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allPenalties.map((penaltie) => (
                          <TableRow
                            key={penaltie.id}
                            className={classes.tableModalRow}
                          >
                            <TableCell
                              className={classes.tableModalFields}
                              component="th"
                              scope="row"
                            >
                              {penaltie.title}
                            </TableCell>
                            <TableCell
                              className={classes.tableModalFields}
                              align="center"
                            >
                              {`${penaltie.score} pontos`}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <Typography
                      variant="body1"
                      align="center"
                      color="primary"
                      style={{marginTop: 20}}
                    >
                      Nenhuma penalidade cadastrada
                    </Typography>
                  )}
                </TableContainer>
              </div>
            </Fade>
          </Modal>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default ListPenalization;
